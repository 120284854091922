import React, { createContext, useMemo, ReactNode, FC, useState, useCallback, useEffect } from 'react';
import useWeSockets from 'hooks/useWeSockets';
import { TSubscribe } from 'hooks/useWeSockets/types';
import { TNoArgsFuncVoid } from 'types/other';

interface IContextData {
    wsMessagesCount: number | null;
    openHelpSidebar: boolean;
}

interface IContextActions {
    subscribe: TSubscribe;
    unSubscribe: TSubscribe;
    resetWSMessagesCount: TNoArgsFuncVoid;
    toggleHelpSidebar: TNoArgsFuncVoid;
    setOpenHelpSidebar: (val: boolean) => void;
}

interface IContextProviderProps {
    children: ReactNode;
}

const initData: IContextData = {
    wsMessagesCount: null,
    openHelpSidebar: false,
};

const initActions: IContextActions = {
    subscribe: () => null,
    unSubscribe: () => null,
    resetWSMessagesCount: () => null,
    toggleHelpSidebar: () => null,
    setOpenHelpSidebar: () => null,
};

export const AppDataContext = createContext(initData);
export const AppActionsContext = createContext(initActions);

const AppContextProvider: FC<IContextProviderProps> = ({ children }: IContextProviderProps) => {
    const [wsMessagesCount, setWSMessagesCount] = useState<number | null>(null);
    const [openHelpSidebar, setOpenHelpSidebar] = useState<boolean>(false);

    const { subscribe, unSubscribe } = useWeSockets();

    const onWSMessage = useCallback(() => {
        setWSMessagesCount(val => (val ? val + 1 : 1));
    }, []);

    useEffect(() => {
        subscribe(onWSMessage);
        return () => {
            unSubscribe(onWSMessage);
        };
    }, [onWSMessage, subscribe, unSubscribe]);

    const resetWSMessagesCount = useCallback(() => {
        setWSMessagesCount(null);
    }, []);

    const toggleHelpSidebar = useCallback(() => {
        setOpenHelpSidebar(val => !val);
    }, []);

    return (
        <AppDataContext.Provider value={useMemo(() => ({ wsMessagesCount, openHelpSidebar }), [openHelpSidebar, wsMessagesCount])}>
            <AppActionsContext.Provider
                value={useMemo(
                    () => ({ subscribe, unSubscribe, resetWSMessagesCount, toggleHelpSidebar, setOpenHelpSidebar }),
                    [subscribe, unSubscribe, resetWSMessagesCount, toggleHelpSidebar, setOpenHelpSidebar],
                )}
            >
                {children}
            </AppActionsContext.Provider>
        </AppDataContext.Provider>
    );
};

export default AppContextProvider;
