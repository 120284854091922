import React, { FC } from 'react';

const UsersIcon: FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M11.3337 14V12.6667C11.3337 11.9594 11.0527 11.2811 10.5526 10.781C10.0525 10.281 9.37424 10 8.66699 10H3.33366C2.62641 10 1.94814 10.281 1.44804 10.781C0.947944 11.2811 0.666992 11.9594 0.666992 12.6667V14'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M5.99967 7.33333C7.47243 7.33333 8.66634 6.13943 8.66634 4.66667C8.66634 3.19391 7.47243 2 5.99967 2C4.52692 2 3.33301 3.19391 3.33301 4.66667C3.33301 6.13943 4.52692 7.33333 5.99967 7.33333Z'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M15.333 13.9998V12.6664C15.3326 12.0756 15.1359 11.5016 14.7739 11.0346C14.4119 10.5677 13.9051 10.2341 13.333 10.0864'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M10.667 2.08643C11.2406 2.23329 11.749 2.56689 12.1121 3.03463C12.4752 3.50237 12.6722 4.07765 12.6722 4.66976C12.6722 5.26187 12.4752 5.83715 12.1121 6.30489C11.749 6.77262 11.2406 7.10623 10.667 7.25309'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default UsersIcon;
