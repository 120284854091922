import routes from 'config/routes';
import { routeId } from 'config/general';
import { HelpData } from './types';
import { links } from './config';

export const getHelpData = (path: string): HelpData | null => {
    // console.log('path', path);

    if (path.includes('/event-feed') || path === '/' || path === '') {
        return links.activityFeed;
    }
    if (path.includes('/users/profile')) {
        return links.usersProfile;
    }
    if (path.includes('/sites') && path.includes('/access-point/')) {
        return links.accessPoint;
    }
    if (path.includes('/sites')) {
        return links.sitesAccessPoints;
    }
    if (path.includes('/user-groups')) {
        return links.accessGroups;
    }
    if (path.includes('/intercom-settings/front-desk')) {
        return { ...links.intercomDirectory, faq: links.intercomDirectory.faq };
    }
    if (path.includes('/intercom-settings/qr')) {
        return links.qrIntercom;
    }
    if (path.includes('/intercom-settings')) {
        return links.intercomDirectory;
    }
    if (path.includes('/visitor-pins')) {
        return links.visitorPins;
    }
    if (path.includes(routes.schedules.root)) {
        return links.schedules;
    }

    if (path.includes(routes.integrations.pms.realPage.root)) {
        return links.integrationsRealPage;
    }

    if (path.includes(routes.integrations.pms.yardi.root)) {
        return links.integrationsYardi;
    }

    if (path.includes(routes.integrations.pms.entrata.root)) {
        return links.integrationsEntrate;
    }

    if (path.includes(routes.integrations.accessControlSystems.brivo.root)) {
        return links.integrationsBrivo;
    }

    if (path.includes(routes.integrations.locks.august.root)) {
        return links.integrationsYaleAugust;
    }

    if (path.includes(routes.integrations.eagleEye.root.replace(routeId, ''))) {
        return links.integrationsEagleEye;
    }

    // integrations root is present at many places, so it should be the last one
    if (path.includes(routes.integrations.root)) {
        return {
            ...links.integrations,
            faq: [
                ...(links.integrations.faq || []),
                ...(links.integrationsRealPage.faq || []),
                ...(links.integrationsYardi.faq || []),
                ...(links.integrationsEntrate.faq || []),
                ...(links.integrationsBrivo.faq || []),
                ...(links.integrationsYaleAugust.faq || []),
                ...(links.integrationsEagleEye.faq || []),
            ],
        };
    }

    if (path.includes(routes.settings.root) && !path.includes(routes.commandCenter.root)) {
        return links.settings;
    }

    if (path.includes(routes.hardware.root) && !path.includes(routes.commandCenter.root)) {
        return links.hardware;
    }

    // /users is present at many places, so it should be the last one
    if (path.includes('users') && !path.includes(routes.commandCenter.root)) {
        return links.users;
    }
    return null;
};
