import React, { useCallback, useMemo, useState } from 'react';
import Container from 'components/common/Container';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { billingStateQueryKey, invoicesContactInfoQueryKey } from 'pages/Billing/config';
import { requestActivateAccount, requestInvoicesContactInfo, requestTrialAccess } from 'pages/Billing/services';
import { useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';
import { Alert, Form, Modal } from 'antd';
import Button from 'components/antd/Button';
import { textsCommon } from 'texts/index';
import { PaymentStatus } from 'pages/Billing/types';
import TextArea from 'antd/es/input/TextArea';
import { requiredRule } from 'config/form';
import ButtonsRow from 'components/common/button/ButtonsRow';
import { Link, useLocation } from 'react-router-dom';
import routes from 'config/routes';
import cn from 'classnames';
import Time from 'components/common/Time';
import { IAuthenticationState } from 'store/authentication/types';
import { Roles } from 'config/general';
import { AccountStatus } from 'pages/CommandCenter/Accounts/types';
import styles from './styles.module.scss';

const BillingBanner = () => {
    const { id_str: companyId } = useSelector(({ workspace }: ICustomMap) => workspace);
    const { company_status, payment_status, is_trial_active, trial_end_time } = useSelector(({ billing }: ICustomMap) => billing);
    const { user } = useSelector(({ authentication }: ICustomMap): IAuthenticationState => authentication);
    const isAdmin = useMemo(() => user?.role === Roles.admin, [user]);
    const [openReqAccessModal, setOpenReqAccessModal] = useState<boolean>(false);
    const [openActivateModal, setActivateModal] = useState<boolean>(false);

    const location = useLocation();
    const queryClient = useQueryClient();

    // const { data } = useQuery({
    //     queryKey: [billingStateQueryKey, { company_id: companyId }],
    //     queryFn: requestBillingState,
    //     staleTime: 0,
    // });

    const { data: contactInfo } = useQuery({
        queryKey: [invoicesContactInfoQueryKey, { company_id: companyId }],
        queryFn: requestInvoicesContactInfo,
        enabled: !!companyId && isAdmin,
        staleTime: 0,
    });

    const { mutate: activateAccount, isLoading: isActivating } = useMutation(requestActivateAccount, {
        onSuccess: () => {
            queryClient.invalidateQueries(billingStateQueryKey);
            setActivateModal(false);
        },
    });

    const { mutate: getTrialAccess, isLoading: isGettingAccess } = useMutation(requestTrialAccess, {
        onSuccess: () => {
            queryClient.invalidateQueries(billingStateQueryKey);
            setOpenReqAccessModal(false);
        },
    });

    const isActivityFeed = location.pathname.includes(routes.activityFeed.root) || location.pathname === routes.home;

    // const { company_status, payment_status, is_trial_active, trial_end_time } = data?.respData || {};

    const handleActivateAccount = useCallback(() => {
        activateAccount();
    }, [activateAccount]);

    const toggleReqAccessModal = useCallback(() => {
        setOpenReqAccessModal(prev => !prev);
    }, []);

    const toggleActivateModal = useCallback(() => {
        setActivateModal(prev => !prev);
    }, []);

    const handleRequestAccess = useCallback(
        ({ purpose }: ICustomMap) => {
            getTrialAccess({ purpose });
        },
        [getTrialAccess],
    );

    // const contactEmails = contactInfo?.respData?.emails || [];
    const isContactIntegrator = contactInfo?.respData?.is_integrator || false;

    const message = useMemo(() => {
        if (company_status === AccountStatus.new && payment_status !== PaymentStatus.suspended)
            return textsCommon.billing.newNotSuspendedMsg;
        if (company_status === AccountStatus.new && payment_status === PaymentStatus.suspended) return textsCommon.billing.newSuspendedMsg;
        if (company_status === AccountStatus.active && payment_status === PaymentStatus.suspended)
            return textsCommon.billing.activeSuspendedMsg;
        if (company_status === AccountStatus.active && payment_status === PaymentStatus.overDue && !isContactIntegrator)
            return (
                <>
                    {/* {isContactIntegrator && contactEmails.length > 0 && ( */}
                    {/*    <> */}
                    {/*        There are invoices due for this account. Please reach out to&nbsp; */}
                    {/*        {contactEmails.map((email, index) => ( */}
                    {/*            <a href={`mailto:${email}`} key={index}> */}
                    {/*                {email} */}
                    {/*            </a> */}
                    {/*        ))} */}
                    {/*        &nbsp;to get the invoices paid. */}
                    {/*    </> */}
                    {/* )} */}
                    {/* {isContactIntegrator && contactEmails.length === 0 && ( */}
                    {/*    <>There are invoices due for this account. Please reach out to your security provider to get the invoices paid.</> */}
                    {/* )} */}
                    {/* {!isContactIntegrator && ( */}
                    {/* <> */}
                    There are invoices due for this account.&nbsp;
                    <Link to={routes.billing.root}>Please review invoices</Link>
                    &nbsp;and pay.
                    {/* </> */}
                    {/* )} */}
                </>
            );
        return null;
    }, [company_status, isContactIntegrator, payment_status]);

    const showRequestAccessBtn =
        company_status === AccountStatus.new || (company_status === AccountStatus.active && payment_status === PaymentStatus.suspended);

    const showActivateBtn = company_status === AccountStatus.new;
    const disableActivateBtn = payment_status === PaymentStatus.suspended;

    if (!message || !contactInfo) return null;

    return (
        <Container fullWidth>
            <Alert
                className={cn(styles.alert, { [styles.pushRight]: isActivityFeed })}
                message={
                    <>
                        <div className={styles.message}>
                            <div className={styles.buttons}>
                                {showActivateBtn && (
                                    <Button onClick={toggleActivateModal} type='primary' ghost size='small' disabled={disableActivateBtn}>
                                        {textsCommon.activate}
                                    </Button>
                                )}
                                {showRequestAccessBtn && (
                                    <Button type='primary' ghost size='small' disabled={isActivating} onClick={toggleReqAccessModal}>
                                        {textsCommon.billing.requestAccessMsg}
                                    </Button>
                                )}
                            </div>
                            {message}
                        </div>
                        {is_trial_active && (
                            <div>
                                <strong>
                                    {textsCommon.billing.accessEndAt}: <Time date={trial_end_time} full />
                                </strong>
                            </div>
                        )}
                    </>
                }
                type='error'
            />
            <Modal
                open={openReqAccessModal}
                title={textsCommon.billing.requestAccessMsg}
                destroyOnClose
                footer={null}
                onCancel={isGettingAccess ? undefined : toggleReqAccessModal}
            >
                <p>{textsCommon.billing.requestAccessPurpose}</p>
                <Form layout='vertical' onFinish={handleRequestAccess}>
                    <Form.Item name='purpose' rules={[requiredRule]}>
                        <TextArea rows={3} />
                    </Form.Item>
                    <ButtonsRow>
                        <Button type='primary' ghost onClick={toggleReqAccessModal} disabled={isGettingAccess}>
                            {textsCommon.buttons.cancel}
                        </Button>
                        <Form.Item>
                            <Button type='primary' htmlType='submit' loading={isGettingAccess}>
                                {textsCommon.buttons.submit}
                            </Button>
                        </Form.Item>
                    </ButtonsRow>
                </Form>
            </Modal>
            <Modal
                className={styles.activateModal}
                open={openActivateModal}
                destroyOnClose
                footer={null}
                title={textsCommon.billing.activateAccount}
                onCancel={isActivating ? undefined : toggleActivateModal}
            >
                <p>
                    <strong>{textsCommon.billing.activateAccountConfirmation}</strong>
                </p>
                <ButtonsRow>
                    <Button type='primary' ghost onClick={toggleActivateModal} disabled={isActivating}>
                        {textsCommon.buttons.cancel}
                    </Button>
                    <Button type='primary' loading={isActivating} onClick={handleActivateAccount}>
                        {textsCommon.buttons.activate}
                    </Button>
                </ButtonsRow>
            </Modal>
        </Container>
    );
};

export default BillingBanner;
