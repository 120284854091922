import { useEffect, useState } from 'react';
import { LG_WIDTH } from 'config/general';

type Res = {
    width: number;
    height: number;
    isMobile: boolean;
};

const useWindowSize = (): Res => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/

    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(window?.innerWidth);
    const [height, setHeight] = useState<number>(window?.innerHeight);

    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            setWidth(window?.innerWidth);
            setHeight(window?.innerHeight);
            if (window.innerWidth < LG_WIDTH) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }

        // Add event listener
        window?.addEventListener('resize', handleResize);

        // Call handler right away so state gets updated with initial window size
        handleResize();

        // Remove event listener on cleanup
        return () => window?.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return {
        width,
        height,
        isMobile,
    };
};

export default useWindowSize;
