export default {
    title: 'ACU Hub',
    devices: 'Devices',
    deviceId: 'Device ID',
    ACUDetails: 'ACU Details',
    privateIP: 'Private IP',
    assignAccountConfirm: 'Are you sure you want to change/assign account?',
    clearAccountConfirm: 'Are you sure you want to clear account?',
    lastDataSync: 'Last Data Sync',
    ACUs: 'ACUs',
    firmware: 'Firmware',
    hardware: 'Hardware',
    addFirmware: 'Add Firmware',
    addHardware: 'Add Hardware',
    createFirmware: 'Create Firmware',
    uploadFirmware: 'Upload Firmware',
    version: 'Version',
    inProgress: 'In Progress',
    firmwareVers: 'Firmware Version',
    cloudUrl: 'Cloud URL',
    view: 'View',
    hardwareType: 'Hardware Type',
    hardwareVers: 'Hardware Version',
    createHardware: 'Create Hardware',
    defaultFirmware: 'Default Firmware',
    fwUpdateSuccess: 'Firmware updated successfully',
    fwUploadError: 'Firmware file upload failed',
    fwUpdateError: 'Firmware update failed',
    uploadProgress: 'Upload Progress',
    pinFirmware: 'Pin Firmware',
    unPinFirmware: 'Unpin Firmware',
    syncNow: 'Force Data Sync',
    selectFirmware: 'Select Firmware',
    pinConfirm: 'Are you sure you want to pin firmware?',
    unPinConfirm: 'Are you sure you want to unpin firmware?',
    reboot: 'Reboot',
    assignAccount: 'Assign Account',
    live: 'Live',
    pinned: 'Pinned',
    liveFwVers: 'Live Firmware Version',
    pinFwVers: 'Pinned Firmware Version',
    rebootConfirm: 'Are you sure you want to reboot?',
    telemetry: 'Telemetry',
    event: 'Event',
    logs: 'Logs',
    acuDevices: 'ACU Devices',
    macAddress: 'Mac Address',
};
