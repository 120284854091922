export default {
    selectCardReaders: 'Select Card Readers',
    intercomSettings: 'Intercom Label Settings',
    visitorBtnLabel: 'Visitor button label',
    visitorFieldHint: 'Would change the visitor button label that is displayed on the swift reader screen',
    tenantBtnLabel: 'Tenant intercom label',
    tenantFieldHint:
        'Would change the `call tenant` button label , which is displayed on swift reader when you click on visitor  option when tenant intercom is enabled.',
    frontDeskBtnLabel: 'Front desk button label',
    frontDeskFieldHint:
        'Would change the front desk button label , which is displayed on swift reader when you click on visitor  option when front desk intercom is enabled.',
    deviceVolume: 'Device volume',
    volumeHelpTxt: 'Adjust the volume of swift-reader',
    apiCallsLimits: 'Swift Reader Daily Action Limits',
    dailyLimit: 'Daily Limit',
    callsMade: 'Calls Made',
    callsLeft: 'Calls Left',
    sitesAndAccessPoints: 'Sites & Access Points',
    addNewSite: 'Add a new Site',
    siteName: 'Site Name',
    address: 'Address',
    typeSearchPlaces: 'Type and search for places',
    timezone: 'Timezone',
    selectTimezone: 'Select Timezone',
    country: 'Country',
    selectCountry: 'Select Country',
    phoneNumber: 'Phone Number',
    pleaseCheckPhoneNumber: 'Please enter a valid 10 digit phone number',
    addSite: 'Add Site',
    editSite: 'Edit Site',
    selectSite: 'Select Site',
    pleaseSelectSite: 'Please Select Site',
    unlock: 'Unlock',
    selectSection: 'Select Section',
    accessPointDetails: 'Access Point Details',
};
