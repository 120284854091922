import React, { CSSProperties, FC } from 'react';

interface IProps {
    className?: string;
    styles?: CSSProperties;
}

const ExternalLinkIcon: FC<IProps> = ({ className, styles }: IProps) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        fill='none'
        stroke='currentColor'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
        className={className}
        style={styles}
    >
        <path d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6' />
        <polyline points='15 3 21 3 21 9' />
        <line x1={10} y1={14} x2={21} y2={3} />
    </svg>
);

export default ExternalLinkIcon;
