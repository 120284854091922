import { APIMethods, IRequestApiConfig } from 'hooks/useRequestApi/types';

export const getWebSocketsTokenConfig: IRequestApiConfig = {
    uri: '/web-socket/auth-token',
    respKey: 'data',
    method: APIMethods.POST,
};

export interface IGetWebSocketsToken {
    body: {
        device_id: string;
        subscription_type: string;
    };
}
