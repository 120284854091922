import { useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ICustomMap } from 'types/ICustomMap';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import useRequestApi from 'hooks/useRequestApi/useRequestApi';
import { IOptions } from 'hooks/useRequestApi/types';
import { isProd } from 'utils/isProd';
import { getWebSocketsTokenConfig, IGetWebSocketsToken } from './servicesConfig';
import { IWebSocketAuthData, TSubscribe, TSubscribeCallback } from './types';

const defaultOptions: IOptions = { popupErrMsg: false, popupSuccessMsg: false };

const wsUrl = isProd() ? 'wss://ws.swiftlane.com' : 'wss://ws.swiftpass.io';

interface IRes {
    subscribe: TSubscribe;
    unSubscribe: TSubscribe;
}

const useWeSockets = (): IRes => {
    const { user } = useSelector(({ authentication }: ICustomMap) => authentication);
    const { id_str: companyId } = useSelector(({ workspace }: ICustomMap) => workspace);
    const [requestToken] = useRequestApi<IGetWebSocketsToken, IWebSocketAuthData>(getWebSocketsTokenConfig, defaultOptions);
    const { enableWebSockets } = useFlags();

    const webSocket = useRef<WebSocket>();
    const subscribers = useRef<TSubscribeCallback[]>([]);
    const reConnectCount = useRef<number>(0);

    const userId = useMemo(() => user?.id_str, [user]);

    useEffect(() => {
        let reconnectDelay = 3000;
        const onConnectionOpen = () => {
            reConnectCount.current = 0;
            reconnectDelay = 3000;
            window.console.log('webSocket connection established');
        };
        const onError = (error: Event) => {
            window.console.error(error);
        };

        const onMessage = (message: MessageEvent) => {
            const msg = JSON.parse(message.data);
            subscribers.current?.forEach(func => func(msg));
        };
        const onWSClose = () => {
            window.console.log('websockets re-connect');
            reConnectCount.current += 1;
            if (reConnectCount.current > 3) {
                reconnectDelay = 60 * 1000;
            }
            // re connect
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            setTimeout(connectWS, reconnectDelay);
        };

        async function connectWS() {
            if (userId && companyId && enableWebSockets) {
                if (webSocket.current) {
                    webSocket.current.removeEventListener('open', onConnectionOpen);
                    webSocket.current.removeEventListener('message', onMessage);
                    webSocket.current.removeEventListener('error', onError);
                    webSocket.current.removeEventListener('close', onWSClose);
                    webSocket.current?.close(1000);
                    webSocket.current = undefined;
                }

                try {
                    const { data: authData } = await requestToken({
                        body: {
                            subscription_type: 'WEB_DASH',
                            device_id: userId,
                        },
                    });
                    webSocket.current = new WebSocket(
                        `${wsUrl}?device_id=${authData.device_id}&subscription_type=WEB_DASH&company_id=${companyId}&auth_token=${authData.auth_token}`,
                    );
                } catch {
                    // try to reconnect
                    onWSClose();
                }

                webSocket.current?.addEventListener('open', onConnectionOpen);
                webSocket.current?.addEventListener('message', onMessage);
                webSocket.current?.addEventListener('error', onError);
                webSocket.current?.addEventListener('close', onWSClose);
            }
        }
        connectWS();

        return () => {
            webSocket.current?.removeEventListener('open', onConnectionOpen);
            webSocket.current?.removeEventListener('message', onMessage);
            webSocket.current?.removeEventListener('error', onError);
            webSocket.current?.removeEventListener('close', onWSClose);
        };
    }, [companyId, enableWebSockets, requestToken, userId]);

    const subscribe: TSubscribe = useCallback(callback => {
        subscribers.current.push(callback);
    }, []);

    const unSubscribe: TSubscribe = useCallback(callback => {
        subscribers.current = subscribers.current?.filter(func => func !== callback);
    }, []);

    useEffect(() => {
        return () => {
            webSocket.current?.close(1000);
        };
    }, []);

    return {
        subscribe,
        unSubscribe,
    };
};

export default useWeSockets;
