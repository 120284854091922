import React, { FC, ReactNode } from 'react';
import { Button as ButtonAnt } from 'antd';
import { ButtonProps } from 'antd/es/button';
import cn from 'classnames';
import styles from './styles.module.scss';

interface IButtonProps extends ButtonProps {
    children?: ReactNode | JSX.Element | string | number;
    green?: boolean;
    gray?: boolean;
}

const Button: FC<IButtonProps> = ({ children, green, className, type, ghost, gray, ...props }: IButtonProps) => (
    <ButtonAnt
        {...props}
        type={type}
        ghost={ghost}
        className={cn(styles.btn, {
            [className || '']: className,
            [styles.green]: green,
            [styles.gray]: gray,
            [styles.ghost]: ghost,
        })}
    >
        {children}
    </ButtonAnt>
);

export default Button;
