import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import featureFlagsList from 'config/featureFlagsList';
import { useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';
import { IAuthenticationState } from 'store/authentication/types';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { EIconColorType, TSideNavMenu } from 'types/TSideNavMenu';
import cn from 'classnames';
import { TNoArgsFuncVoid } from 'types/other';
import routes from 'config/routes';
import { Roles } from 'config/general';
import { adminItems, sideNavMenuOpenKeysKey, userDashboardItems } from 'components/NavSideBar/config';
import Item from './Item';
import styles from './styles.module.scss';
import ActivityFeedItem from './ExtendedItems/ActivityFeed';
import SelectBoxItem from './SelectBoxItem';

type MenuItem = Required<MenuProps>['items'][number];

const keys = localStorage.getItem(sideNavMenuOpenKeysKey);
const savedOpenKeys: string[] = (keys ? JSON.parse(keys) : ['0']) as string[] | [];
// savedOpenKeys = [...savedOpenKeys.filter(el => el !== '0'), '0']; // keep 0 key always open

interface IProps {
    onItemPress: TNoArgsFuncVoid;
}

const MainMenu: FC<IProps> = ({ onItemPress }: IProps) => {
    const { user } = useSelector(({ authentication }: ICustomMap): IAuthenticationState => authentication);
    const { cap_settings } = useSelector((state: ICustomMap) => state.workspace);
    const { isAccountRestricted } = useSelector(({ billing }: ICustomMap) => billing);
    const [openKeys, setOpenKeys] = useState<string[] | undefined>(savedOpenKeys);

    // console.log(openKeys)

    const flags = useFlags();

    const allowCommandCenter =
        flags[featureFlagsList.enableIosDevices] ||
        flags[featureFlagsList.enableWorkspaces] ||
        flags[featureFlagsList.enableDcuDevices] ||
        flags[featureFlagsList.enableTailgateDetection] ||
        flags[featureFlagsList.enableReferralsOffers];

    const menuItemList = useMemo(() => {
        if (user) {
            return user?.role === Roles.admin ? adminItems : userDashboardItems;
        }
        return [];
    }, [user]);

    const renderItem = useCallback(
        (menuItem: TSideNavMenu, key: number | string): MenuItem | null => {
            // hide menu item if it is not enabled
            if (menuItem.launchDarklyFlag && !flags[menuItem.launchDarklyFlag]) return null;
            // if (user?.role !== menuItem.accessRole) return null;
            if (menuItem.isCommandCenter && !allowCommandCenter) return null;

            // when account is restricted we show invoices page so need to show also patent menu item and hide others
            const parentRestricted = menuItem?.items?.every(el => !el.isBilling) && isAccountRestricted;

            if (parentRestricted) {
                return null;
            }

            // TODO = find better solution
            // hide user.subscriptions if it is not enabled
            if (!user?.end_user_billing && menuItem.route === routes.user.subscriptions) return null;

            // the following flag controls hiding of items disabled in workspace.cap_settings object
            // we use it to show/hide health check option for now
            if (menuItem.workspaceSettingFlag && !cap_settings?.[menuItem.workspaceSettingFlag]) return null;

            const subMenu = menuItem.items
                ? menuItem.items.map((el, idx) => {
                      // hide menu item if account is restricted and it is not billing
                      if (isAccountRestricted && !el.isBilling) {
                          return null;
                      }
                      return renderItem(el, `group-${key}-${idx}`);
                  })
                : null;

            let ItemComponent = menuItem.route === routes.activityFeed.root ? ActivityFeedItem : Item;
            ItemComponent = menuItem.selectBoxType ? SelectBoxItem : ItemComponent;

            const onPress = () => {
                if (!menuItem.selectBoxType && !menuItem.items) {
                    onItemPress();
                }
                return null;
            };

            return {
                key,
                icon: menuItem.icon ? (
                    <span className={cn('sideNavIcon', { sideNavIconFill: menuItem.iconColorType === EIconColorType.fill })}>
                        <menuItem.icon />
                    </span>
                ) : undefined,
                label: <ItemComponent data={menuItem} />,
                // className: cn(styles.item, {
                //     [styles.subNav]: menuItem.items,
                // }),
                children: subMenu,
                popupClassName: styles.popup,
                onClick: onPress,
                onTitleClick: ({ key: navKey }: ICustomMap) => {
                    if (subMenu) {
                        setOpenKeys(prev => {
                            const hasKey = prev?.includes(navKey);
                            const newKeys = hasKey ? prev?.filter(el => el !== navKey) : [...(prev || []), navKey];
                            localStorage.setItem(sideNavMenuOpenKeysKey, JSON.stringify(newKeys));
                            return newKeys;
                        });
                    }
                },
            } as MenuItem;
        },
        [allowCommandCenter, cap_settings, flags, onItemPress, isAccountRestricted, user],
    );

    const menuItems = menuItemList?.map((menuItem, key) => renderItem(menuItem, key));
    return <Menu className={styles.menu} theme='dark' mode='inline' items={menuItems} openKeys={openKeys} />;
};

export default memo(MainMenu);
