export default {
    profile: 'Profile',
    changePassword: 'Change Password',
    customizeAccessExperience: 'Customize your access experience',
    profilePhoto: 'Profile Photo',
    uploadPhoto: 'Upload Photo',
    oldPass: 'Current Password',
    newPass: 'New Password',
    changePass: 'Change Password',
};
