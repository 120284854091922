import { combineReducers } from 'redux';

// import { companyInfo } from 'legacy/reducers/users.reducer';
// import { alert } from 'legacy/reducers/alert.reducer';
// import { sites } from 'legacy/reducers/site.reducer';
// import { visitorQuestions } from 'legacy/reducers/visitor.reducer';
// import { workspaces } from 'legacy/reducers/events.reducer';
// import { accessPoints, getAccessPoints } from 'legacy/reducers/accessPoints.reducer';
// import ajaxStatus from 'legacy/reducers/ajaxStatusReducer';

import workspaceReducer from './workSpace/reducer';
import authenticationReducer from './authentication/reducer';
import fcmReducer from './fcm/reducer';
import rentGptReducer from './rentGpt/reducer';
import billingReducer from './billing/reducer';

const rootReducer = combineReducers({
    authentication: authenticationReducer,
    // sites,
    // alert,
    // ajaxStatus,
    // accessPoints,
    // getAccessPoints,
    // workspaces,
    // companyInfo,
    // visitorQuestions,
    firebase: fcmReducer,

    workspace: workspaceReducer,
    rentGpt: rentGptReducer,
    billing: billingReducer,
});

export default rootReducer;
