import React, { FC } from 'react';

const HeartIcon: FC = () => (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M13.8931 2.07357C13.5526 1.73291 13.1483 1.46267 12.7033 1.2783C12.2584 1.09392 11.7814 0.999023 11.2998 0.999023C10.8181 0.999023 10.3412 1.09392 9.89618 1.2783C9.45121 1.46267 9.04693 1.73291 8.70642 2.07357L7.99975 2.78024L7.29309 2.07357C6.60529 1.38578 5.67244 0.99938 4.69975 0.99938C3.72707 0.99938 2.79422 1.38578 2.10642 2.07357C1.41863 2.76137 1.03223 3.69422 1.03223 4.66691C1.03223 5.6396 1.41863 6.57245 2.10642 7.26024L2.81309 7.96691L7.99975 13.1536L13.1864 7.96691L13.8931 7.26024C14.2338 6.91974 14.504 6.51545 14.6884 6.07048C14.8727 5.6255 14.9676 5.14857 14.9676 4.66691C14.9676 4.18525 14.8727 3.70831 14.6884 3.26334C14.504 2.81836 14.2338 2.41408 13.8931 2.07357V2.07357Z'
            strokeWidth='1.33333'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default HeartIcon;
