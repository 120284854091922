import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { requestProperties } from 'pages/RentGpt/services';
import { Select } from 'antd';

import { rentGptPropertiesQueryKey, RentGptPropertyLocalStorageKey } from 'pages/RentGpt/config';
import { useDispatch, useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';
import { selectOptionFilter } from 'utils/filters';
import { setRGPropertiesAction, setRGPropertyAction } from 'store/rentGpt/actions';
import styles from './styles.module.scss';

const RentGptSelect: FC = () => {
    const { id_str: accountId } = useSelector(({ workspace }: ICustomMap) => workspace);
    const selectedBotId = useSelector(({ rentGpt }: ICustomMap) => rentGpt?.property?.botID);

    const dispatch = useDispatch();

    const { data, isLoading } = useQuery({
        queryKey: [rentGptPropertiesQueryKey, { company_id: accountId }],
        queryFn: requestProperties,
        enabled: !!accountId,
        onSuccess: ({ respData }) => {
            dispatch(setRGPropertiesAction(respData?.properties || null));
            if (respData?.properties?.length && !selectedBotId) {
                dispatch(setRGPropertyAction(respData.properties[0] || null));
            }
        },
    });

    const list = data?.respData?.properties || [];

    const handleSelectBot = (botId: string) => {
        const property = list.find(({ botID }) => botID === botId);
        dispatch(setRGPropertyAction(property || null));
        localStorage.setItem(RentGptPropertyLocalStorageKey, JSON.stringify(property));
    };

    return (
        <Select
            value={selectedBotId}
            className={styles.selectBox}
            loading={isLoading}
            onSelect={handleSelectBot}
            showSearch
            filterOption={selectOptionFilter}
        >
            {list.map(({ name, botID }) => (
                <Select.Option key={botID} value={botID}>
                    {name}
                </Select.Option>
            ))}
        </Select>
    );
};

export default RentGptSelect;
