import React, { FC } from 'react';

import { history } from 'legacy/helpers';
import PushNotifications from 'components/PushNotifications';
import { Link, Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import 'legacy/assets/styles/global.scss';

import { Layout, ConfigProvider, Spin } from 'antd';

import NavSideBar from 'components/NavSideBar';
import cn from 'classnames';

import useApp from 'components/App/useApp';

import EmptyHeader from 'components/common/EmptyHeader';
import AppContextProvider from 'context/App';
import HelpSidebar from 'components/common/Help/HelpSidebar';
import BillingBanner from 'components/BillingBanner';
import useWindowSize from 'hooks/useWindowSize';
import { textsCommon } from 'texts/index';
import { accountingEmail, theme } from 'config/general';
import routes from 'config/routes';
import Button from 'components/antd/Button';
import Intercom from 'components/Intercom';
import Routes from '../../Routes';

import styles from './styles.module.scss';

const { Content } = Layout;

const App: FC = () => {
    const { isLoggedIn, isAccountRestricted, isNotRestrictedPage, isLoading, isAdmin } = useApp();
    const { isMobile } = useWindowSize();

    return (
        <div className={cn({ [styles.rootRestricted]: isAccountRestricted, [styles.loading]: isLoading })}>
            {isLoading && (
                <div className={styles.loader}>
                    <Spin size='large' />
                </div>
            )}
            {!isLoggedIn && <EmptyHeader />}
            <Layout className={cn(styles.layout, { [styles.noSider]: !isLoggedIn })} hasSider={isLoggedIn && !isMobile}>
                {isLoggedIn && <NavSideBar />}
                <Content className={cn({ [styles.restricted]: isAccountRestricted && !isNotRestrictedPage })}>
                    {!isAccountRestricted && isAdmin && <BillingBanner />}
                    {isLoggedIn && <PushNotifications />}
                    <main className={styles.main}>
                        <Routes />
                    </main>
                    {isAccountRestricted && !isNotRestrictedPage && (
                        <div className={styles.restrictedModal}>
                            <div>
                                <h1>{textsCommon.billing.accountIsRestricted}</h1>
                                <p>
                                    <strong>{textsCommon.billing.restrictedMsg}</strong>
                                </p>
                                <p>
                                    Contact <a href={`mailto:${accountingEmail}`}>{accountingEmail}</a> for assistance.
                                </p>
                                <Link to={routes.billing.root}>
                                    <Button type='primary'>View and Pay Invoices</Button>
                                </Link>
                            </div>
                        </div>
                    )}
                </Content>
                <HelpSidebar />
            </Layout>
            <ToastContainer position='top-center' hideProgressBar />
            {isLoggedIn && <Intercom />}
        </div>
    );
};

const WrappedApp = () => {
    return (
        <AppContextProvider>
            <ConfigProvider theme={theme}>
                <Router history={history}>
                    <App />
                </Router>
            </ConfigProvider>
        </AppContextProvider>
    );
};

export default WrappedApp;
