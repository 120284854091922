import React, { FC } from 'react';

interface IFilledUserProps {
    className?: string;
}

const FilledUser: FC<IFilledUserProps> = ({ className }: IFilledUserProps) => (
    <svg className={className} width='80' height='80' viewBox='0 0 80 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g opacity='0.5'>
            <path
                d='M40 0C28.3689 0 18.9062 9.46266 18.9062 21.0938C18.9062 32.7248 28.3689 42.1875 40 42.1875C51.6311 42.1875 61.0938 32.7248 61.0938 21.0938C61.0938 9.46266 51.6311 0 40 0Z'
                fill='#D1D1E0'
            />
            <path
                d='M66.2447 55.968C60.4697 50.1042 52.8139 46.875 44.6875 46.875H35.3125C27.1863 46.875 19.5303 50.1042 13.7553 55.968C8.00859 61.803 4.84375 69.5052 4.84375 77.6562C4.84375 78.9506 5.89312 80 7.1875 80H72.8125C74.1069 80 75.1562 78.9506 75.1562 77.6562C75.1562 69.5052 71.9914 61.803 66.2447 55.968Z'
                fill='#D1D1E0'
            />
        </g>
    </svg>
);

export default FilledUser;
