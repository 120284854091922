export default {
    privacySecurity: 'Privacy & Security',
    unsavedChanges: 'You have unsaved changes',
    configurationIsUpdated: 'Configuration is updated',
    api: 'API',
    generateToken: 'Generate Token',
    deactivateConfirm: 'Are you sure you want to deactivate this token?',
    copyToken: 'Copy Token',
    copyTokenSuccess: 'Token copied to clipboard',
    apiTokens: 'API Tokens',
    tokenSuffix: 'Token Suffix',

    userAdminLoginSettings: {
        title: 'User/Admin Login Settings',

        allowUsersText: 'Allow users to sign in with email verification code on mobile',
        allowUsersTooltip:
            'This is enabled by default. Your users will be able to log in to Swiftlane mobile app with one time verification code that is sent to their email. If you want to use Single Sign-On, you can install one of the SSO integrations and even disable this setting afterwards if needed.',

        allowAdminsText: 'Allow admins to sign in to admin dashboard with their username/password credentials',
        allowAdminsTooltip:
            'This is enabled by default. Admins will be able to log in to Swiftlane admin dashboard with their username/password credentials. If you want to use Single Sign-On, you can install one of the SSO integrations and disable this setting afterwards.',

        enableAzureText: 'Enable Azure SSO login (applicable to both admin dashboard and mobile app)',
        enableAzureTooltip:
            'This is disabled by default. If enabled, admins will be able to sign in to admin dashboard using Azure Single Sign-On and users will be able to sign in to mobile app using Azure SSO as well. Set up Azure SSO integration under Integrations for this setting to work properly.',

        enableOktaText: 'Enable Okta SSO login (applicable to both admin dashboard and mobile app)',
        enableOktaTooltip:
            'This is disabled by default. If enabled, admins will be able to sign in to admin dashboard using Okta Single Sign-On and users will be able to sign in to mobile app using Okta SSO as well. Set up Okta SSO integration under Integrations for this setting to work properly.',

        enableGSuiteText: 'Enable GSuite SSO login (applicable to both admin dashboard and mobile app)',
        enableGSuiteTooltip:
            'This is disabled by default. If enabled, admins will be able to sign in to admin dashboard using GSuite Single Sign-On and users will be able to sign in to mobile app using GSuite SSO as well. Set up GSuite SSO integration under Integrations for this setting to work properly.',
        allowSignInWithPhone: 'Allow users to sign in with their Mobile number and Verification Code',
        allowSignInWithPhoneTooltip:
            'Your users will be able to log in to Swiftlane mobile app with one time verification code that is sent to their mobile number registered with Swiftlane profile. If you want to use Single Sign-On, you can install one of the SSO integrations and even disable this setting afterwards if needed.',
    },

    pinSettings: {
        title: 'PIN Settings',

        userPinRotationInterval: 'User PIN rotation interval (days)',
        userPinRotationIntervalTooltip: 'We would request the user to change their PIN after these many days.',

        generatePinsAutomatically: 'Generate PINs automatically for all users',
        generatePinsAutomaticallyTooltip:
            'Recommended. Allow users to get their PINs in the app, without needing to request one from the admin.',
        allowCustomPinsForUsers: 'Allow Custom Pins for Users',
        allowCustomPinsForUsersHelpTxt:
            "If you enable this, residents can create their own pins. This is potential security risk as residents would be able to guess other resident's or visitor's pins. If they use other's pins, the system would show incorrect person name accessing the building",
        minPinLen: 'Min Pin Length',
        maxPinLen: 'Max Pin Length',
        maxPinDuration: 'Max Duration for Visitor Pins (in days)',
        maxPinDurationHelpTxt: "Applicable only for 'User' role. Admins can create visitor pins for any duration.",
    },

    doorAlertSettings: {
        title: 'Door Alert Settings',

        doorHeldOpen: 'Door held open duration threshold (in sec)',
        doorHeldOpenTooltip: 'Default duration that triggers an alarm if a door has been held open for.',
    },
};
