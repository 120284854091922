import React, { FC, useState, useCallback, useMemo } from 'react';
import { CopyOutlined, GiftOutlined, MailOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Modal, Typography, Spin } from 'antd';
import Button from 'components/antd/Button';
import { textsReferrals } from 'texts/index';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { sbEmail } from 'config/general';
import ExternalLinkIcon from 'components/common/Icons/ExternalLinkIcon';
import FacebookIcon from 'components/common/Icons/Facebook';
import LinkedInIcon from 'components/common/Icons/LinkedIn';
import TwitterIcon from 'components/common/Icons/Twitter';
import styles from './styles.module.scss';
import { referralCodesQueryKey, requestGetReferralCodes } from './services';

const { Text } = Typography;

function isWebView() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /wv|webview/.test(userAgent);
}

interface IProps {
    iconOnly?: boolean;
    type: 'primary' | 'link';
    buttonSize?: 'large' | 'small';
    ghost?: boolean;
    buttonText?: string;
}

const Referral: FC<IProps> = ({ iconOnly, type, buttonText, buttonSize, ghost }: IProps) => {
    const [visible, setVisible] = useState<boolean>(false);

    const { data: referral, isLoading } = useQuery({
        queryKey: referralCodesQueryKey,
        queryFn: () => requestGetReferralCodes(),
    });

    const referralData = referral?.respData;

    const toggleModal = useCallback(() => {
        setVisible(prev => !prev);
    }, []);

    const handleCopyUrl = useCallback(() => {
        if (!referralData?.url) return;
        navigator?.clipboard?.writeText(referralData.url);
        toast.success(textsReferrals.copyToClipboardSuccess);
    }, [referralData]);

    const inMobWebView = isWebView();

    const shareOnFacebook = useCallback(() => {
        const facebookWindow = window.open(
            `https://www.facebook.com/sharer/sharer.php?u=${referralData?.url}`,
            'facebookPopup',
            `height=450, width=550, top=${window.innerHeight / 2 - 225}, left=${
                window.innerWidth / 2 - 275
            }, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`,
        );
        if (facebookWindow?.focus) {
            facebookWindow.focus();
        }
    }, [referralData]);

    const shareOnLinkedIn = useCallback(() => {
        const linkedInWindow = window.open(
            `https://www.linkedin.com/sharing/share-offsite/?url=${referralData?.url}&title=Swiftlane Introduction`,
            'linkedInPopup',
            `height=450, width=550, top=${window.innerHeight / 2 - 225}, left=${
                window.innerWidth / 2 - 275
            }, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`,
        );
        if (linkedInWindow?.focus) {
            linkedInWindow.focus();
        }
    }, [referralData]);

    const shareOnTwitter = useCallback(() => {
        const twitterWindow = window.open(
            `https://twitter.com/intent/tweet?url=${referralData?.url}&text=Swiftlane Introduction`,
            'twitterPopup',
            `height=450, width=550, top=${window.innerHeight / 2 - 225}, left=${
                window.innerWidth / 2 - 275
            }, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`,
        );
        if (twitterWindow?.focus) {
            twitterWindow.focus();
        }
    }, [referralData]);

    const openShareModal = useCallback(() => {
        if (navigator.share) {
            navigator.share({
                title: 'Swiftlane Introduction',
                url: referralData?.url,
            });
        }
    }, [referralData]);

    const canOpenShareModal = useMemo(() => {
        return !!navigator?.share && !!referralData?.url;
    }, [referralData]);

    if (!referralData) return null;

    return (
        <>
            <Button type={type} onClick={toggleModal} size={buttonSize || 'middle'} ghost={ghost}>
                {iconOnly && <GiftOutlined />}
                {buttonText && !iconOnly && buttonText}
                {!iconOnly && !buttonText && referralData?.short_desc}
            </Button>

            <Modal centered open={visible} onCancel={toggleModal} footer={null} title={referralData?.short_desc} destroyOnClose>
                <Text className={styles.subtitle}>{referralData?.long_desc}</Text>
                <div className={styles.linkContainer}>
                    <Spin spinning={isLoading} wrapperClassName={styles.spinContainer}>
                        <div className={styles.linkBox}>
                            <Text>{referralData?.url}</Text>
                        </div>
                    </Spin>
                    <Button className={styles.iconBtn} type='link' onClick={handleCopyUrl}>
                        <CopyOutlined className={styles.copyIcon} />
                    </Button>
                    {!inMobWebView && (
                        <a href={referralData.url} target='_blank' rel='noreferrer'>
                            <Button className={styles.iconBtn} type='link'>
                                <ExternalLinkIcon className={styles.externalIcon} />
                            </Button>
                        </a>
                    )}
                </div>
                <div className={styles.buttons}>
                    <a href={`mailto:${sbEmail}?subject=Swiftlane Introduction`} rel='noreferrer'>
                        <Button size='large' type='primary' icon={<MailOutlined />}>
                            {textsReferrals.composeEmail}
                        </Button>
                    </a>
                    {!canOpenShareModal && (
                        <>
                            <span>Share On</span>
                            <button className={styles.socialBtn} type='button' onClick={shareOnFacebook}>
                                <FacebookIcon />
                            </button>
                            <button className={styles.socialBtn} type='button' onClick={shareOnLinkedIn}>
                                <LinkedInIcon />
                            </button>
                            <button className={styles.socialBtn} type='button' onClick={shareOnTwitter}>
                                <TwitterIcon />
                            </button>
                        </>
                    )}
                    {canOpenShareModal && (
                        <Button size='large' type='primary' ghost icon={<ShareAltOutlined />} onClick={openShareModal}>
                            Share
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default Referral;
