import { APIMethods, apiProxy, TResponse, Message } from 'utils/api/apiRequestProxy';
import { analyticsIntercomEventTypes, dashboardAnalytics, dashboardAnalyticsEvents } from 'hooks/useAnalytics/config';
import { getFcmToken } from '../firebase';

export type TReqRegisterDevicePushMessaging = {
    token: string;
    device_type: string;
    app_type: string;
    common_user_id?: string;
    is_chime_supported?: boolean;
};

export async function requestRegisterDevicePushMessaging(payload: TReqRegisterDevicePushMessaging): Promise<TResponse<Message>> {
    return apiProxy<Message>({
        url: '/register/user_device/',
        method: APIMethods.POST,
        data: payload,
        showToastErrMsg: false,
        showToastSuccessMsg: false,
    });
}

const getSWRegistration = async () => {
    // Check if the browser supports service workers
    if (!('serviceWorker' in navigator)) {
        const msg = 'Service workers are not supported in this browser.';
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.pushMessagesNotSupported,
            message: msg,
        });
        throw new Error(msg);
    }

    // Check the current notification permission status
    const { permission } = Notification;

    if (permission === 'granted') {
        // Permission is already granted
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.pushMessagesPermissionsAlreadyGranted,
        });
        return navigator.serviceWorker.getRegistration();
    }
    if (permission === 'default') {
        // Ask the user for notification permission
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.pushMessagesPermissionsRequested,
        });
        const newPermission = await Notification.requestPermission();
        if (newPermission === 'granted') {
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.pushMessagesPermissionsGranted,
            });
            return navigator.serviceWorker.getRegistration();
        }
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.pushMessagesPermissionsDeniedByUser,
        });
        throw new Error('Notification permission was denied by the user.');
    } else {
        // Permission was denied previously
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.pushMessagesPermissionsDenied,
        });
        throw new Error('Notification permission was previously denied.');
    }
};

export const requestGetFcmToken = async () => {
    // register push notification and get token, we do it here as we need register when user re-login as we unregister on log-out
    let token = null;

    const registration = await getSWRegistration();

    if (registration) {
        token = await getFcmToken(registration);
    }
    return token;
};
