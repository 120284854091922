import React, { FC, memo, ReactNode } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';

interface IRes {
    className?: string;
    children: ReactNode;
    extraButtons?: ReactNode;
}

const ButtonsRow: FC<IRes> = ({ children, className, extraButtons }: IRes) => {
    return (
        <div className={cn(styles.btnsRow, { [className || '']: className })}>
            <div className={styles.main}>{children}</div>
            {extraButtons ? (
                <div className={styles.extra}>
                    <span className={styles.separator} />
                    <div>{extraButtons}</div>
                </div>
            ) : null}
        </div>
    );
};

export default memo(ButtonsRow);
