export const billingStateQueryKey = 'billingState';
export const accountInvoicesQueryKey = 'accountInvoices';
export const invoicesContactInfoQueryKey = 'invoicesContactInfo';
export const accountInvoiceTemplatesQueryKey = 'accountInvoiceTemplates';
export const qbCustomersQueryKey = 'qbCustomers';
export const qbRecurringInvoiceTemplatesQueryKey = 'qbRecurringInvoiceTemplates';
export const qbTemplateInfoQueryKey = 'qbTemplateInfo';

export const invoicePropKeys = {
    invoiceType: 'invoice_type',
    status: 'status',
    link: 'qb_invoice_link',
    dueDate: 'due_date',
    amount: 'amount',
    invoicePurpose: 'invoice_purpose',
    endDate: 'end_date',
    amountPerInvoice: 'amount_per_invoice',
    billingFrequency: 'billing_frequency',
    gracePeriod: 'grace_period',
    blockShipment: 'block_shipment',
    suspendOnNonPayment: 'suspend_on_non_payment',
    signedTermDuration: 'signed_term_duration',
    initialActivationDate: 'initial_activation_date',
    suspendNonPayment: 'suspend_on_non_payment',
    sendReminders: 'send_reminder',
    reminderStartDate: 'reminder_start_date',
    qbTemplateId: 'qb_template_id',
    dueOnActivation: 'due_on_activation',
    notificationLog: 'notification_log',
    qbInvoiceJson: 'qb_invoice_json',
    accountId: 'account_id',
    blockedShipment: 'blocked_shipment',
    invoiceStatus: 'invoice_status',
    accountName: 'account_name',
};
