export default {
    copyToClipboardSuccess: 'Referral link copied to clipboard',
    referralAndOffers: 'Referrals and Offers',
    addNewOffer: 'Add New Offer',
    offersList: 'Offers List',
    offerId: 'Offer ID',
    offerType: 'Offer Type',
    status: 'Status',
    shortDesc: 'Short Description',
    longDesc: 'Long Description',
    lastUpdatedAt: 'Last Updated At',
    active: 'Active',
    inactive: 'Inactive',
    default: 'Default',
    partner: 'Partner',
    referrerName: 'Referrer Name',
    referralCode: 'Referral Code',
    referralCodeStatus: 'Referral Code Status',
    referralGeneratedAt: 'Referral Code Generated at',
    referralsList: 'Referrals List',
    offers: 'Offers',
    codes: 'Codes',
    referrerEmail: 'Referrer Email',
    referralCodesList: 'Referral Codes List',
    addOffer: 'Add Offer',
    offerInformation: 'Offer Information',
    max25characters: 'Max 25 characters',
    invalidShortDesc: 'Short Description cannot exceed 25 characters',
    editOffer: 'Edit Offer',
    lastUpdatedBy: 'Last Updated By',
    createdAt: 'Created At',
    createdBy: 'Created By',
    addNewCode: 'Add New Code',
    addCode: 'Add Code',
    referralCodeInformation: 'Referral Code Information',
    sendAutoEmail: 'Send auto-email',
    code: 'Code',
    generatedAt: 'Generated at',
    referralCodes: 'Referral Codes',
    composeEmail: 'Email',
    emailCopiedToClipboard: 'Email is copied to clipboard',
};
