import routes from 'config/routes';

const noMenuBarRoutes = [
    routes.auth.visitorSettings,
    routes.healthCheck.test,
    routes.auth.enterWorkspace,
    routes.auth.forgotPassword,
    routes.auth.joinWorkspace,
    routes.auth.confirmEmail,
    routes.auth.login,
    routes.auth.emailVerification,
    routes.intercomSettings.access,
];
export const hideMenuBar = (pathname: string): boolean => {
    return noMenuBarRoutes.includes(pathname);
};
