import { Property } from 'pages/RentGpt/types';
import { rentGptActionTypes } from './actionTypes';

export const setRGPropertyAction = (payload: Property | null) => ({
    type: rentGptActionTypes.SET_RG_PROPERTY,
    payload,
});

export const setRGPropertiesAction = (payload: Property[] | null) => ({
    type: rentGptActionTypes.SET_RG_PROPERTIES,
    payload,
});
