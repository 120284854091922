export default {
    users: 'Users',
    addUser: 'Add User',
    userInfo: 'User Info',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    sendInvite: 'Send Invite',
    role: 'Role',
    addAnotherUser: 'Add another User',
    enterValidEmail: 'Please enter valid email: test@exapmle.com',
    isAdded: 'is added',
    csv: 'csv',
    listOfUsers: 'List of Users',
    sendInviteToAll: 'Send Invite To All Users',
    sendInviteToSelected: 'Send Invite To Selected Users',
    addAllToGroups: 'Add All Users To Groups',
    addSelectedToGroups: 'Add Selected Users To Groups',
    inviteEmailSent: 'Invite Email Sent',
    assignGroups: 'Assign Groups',
    usersAddedSuccessfully: 'Users Added Successfully',
    source: 'Source',
    activateSelected: 'Activate Selected',
    deactivateSelected: 'Deactivate Selected',
    okta: 'Okta',
    gSuite: 'GSuite',
    azure: 'Azure',
    realPage: 'Real Page',
    entrata: 'Entrata',
    manual: 'Manual',
    brivo: 'Brivo',
    addUserToGroup: 'Add User To Group',
    stidVirtualCard: 'Mobile Keycard',
    assignVirtualCard: 'Assign Mobile Keycard',
    removeVCard: 'Remove  Virtual Card',
    accessGroups: 'Access Groups',
    yardi: 'Yardi',
    userWillGetAccessTo: 'The user will get access to',
    activationDate: 'Activation Date',
    deactivationDate: 'Deactivation Date',
    addDateTime: 'Add date/time',
    createTenantEntry: 'Create Intercom Directory Entry for User',
    assignKeyCard: 'Assign Key Card',
    keyCard: 'Key Card',
    tenantDirectoryDisplayName: 'Intercom Directory Display Name',
    allUsers: 'All Users',
    moveIns: 'Move-ins',
    moveOuts: 'Move-outs',
    activationStartDate: 'Activation Start Date',
    activationEndDate: 'Activation End Date',
    deactivationStartDate: 'Deactivation Start Date',
    deactivationEndDate: 'Deactivation End Date',
    startDate: 'Start Date',
    endDate: 'End Date',
    csvUpload: 'CSV Upload',
    downloadCsvTemplate: 'download CSV template here',
    uploadCsv: 'Upload CSV',
    reviewErrors: 'Review Errors',
    verifyImportedData: 'Verify Imported Data',
    importFileNotValid: "Imported File isn't Valid",
    importedFileNotValid:
        "Imported CSV File isn't Valid, please check if headers is correct, no empty rows and each data row has all fields is set. Please check the template for correct format.",
    newGroups: 'New Groups',
    completeUpload: 'Complete Upload',
    successList: 'Success List',
    failedList: 'Failed List',
    moveInHelpText: 'List of users with activation date in future',
    moveOutHelpText1: 'List of active users with deactivation date in future',
    moveOutHelpText2: 'If Admin adds future deactivation date for a user on user’s profile, then the user will be listed in move-out tab.',
    moveOutHelpText3: 'The user will still remain active till his deactivation date.',
    userDetails: 'User Details',
    accessInfoHelpText: 'Set the required access for user. User will get access on his activation date',
    unitsHelpText: 'User will get access to the smart locks assigned to selected units.',
    intercomEntryHelpText:
        'An entry will be created in Intercom directory for this user, which can be used by visitors to make a call to this user.',
    activationHelpText:
        'If activation date is provided, invite will be sent on activation date. If activation date is not provided, admin can choose if he wants to send invite now (auto, by selecting the checkbox) OR later (manually, using Send Invite button on User’s profile)',
    accessInfo: 'Access Info',
    intercomDirectory: 'Intercom Directory',
    sendInviteToUser: 'Send Email Invite to User',
    upcomingMoveIns: 'Upcoming Move-ins',
    upcomingMoveOuts: 'Upcoming Move-outs',
    activationIn: 'Activation in',
    deactivationIn: 'Deactivation in',
    day: 'day',
    days: 'days',
    groupsHelpText: 'To gain access through a door, the user must belong to the correct access group.',
    addDeactivationDate: 'Add Deactivation Date',
    selectDeactivationDate: 'Select Deactivation Date',
    clearDeactivationDate: 'Clear Deactivation Date',
    mobKeyCardNum: 'Mobile Keycard number',
    removeSelected: 'Delete Selected',
    saveErrorMsg: 'There are errors, please check entered data',
    assignGroupsToSelected: 'Assign Groups to Selected',
    emailDuplicated: 'Email is duplicated',
    phoneDuplicated: 'Phone is duplicated',
    roteIntercomCalls: 'Route Intercom Calls',
    newSites: 'New Sites',
    emailPhoneRequired: 'Please enter either Email or Phone number',
    createTenant: 'Create Intercom Directory Entry',
    phoneIntercomErr: "The field is mandatory as you've opted to route intercom calls to regular phone",
    sitesNotExist: {
        p1: "Following Sites doesn't exist",
        p2: 'You can create',
        p3: 'Sites here',
    },
    groupsNotExist: {
        p1: "Following Groups doesn't exist",
        p2: 'You can create',
        p3: 'Groups here',
    },
    allRecords: 'All Records',
    errRecords: 'Records with Errors',
    selectYourRingTone: 'Select your ringtone',
    assignSiteToSelected: 'Assign Sites to Selected',
    changeIntercomDirectory: 'Update Intercom Directory Entry for selected rows',
    tenantEntryAdded: 'Intercom Entry',
    mobileKeyCards: 'Mobile Keycards',
    mobileKeyCardsDesc:
        "Mobile Keycards provide more ways to unlock the door with user's mobile phone using Bluetooth. This works on the supported card readers shown in image.",
    assignMobileReader: 'Assign Mobile Keycard (Bluetooth Mobile Reader Access)',
    assignMobileCard: 'Assign Mobile Keycard',
    doorAccess: 'Door Access',
    noAccessGroupWarn:
        'Hey, no user groups have been assigned. The User(s) won’t have access to any door. Are you sure? Assign access group now.',
    bulkAccessGroupsWarning:
        'One or more users do not have any Access Groups assigned. They won’t have access to any door. This is not mandatory to create a new user but highly recommended. Please consider assigning proper access groups.',
    changeRouteIntercomCalls: 'Update Route Intercom Calls for selected rows',
    createTenantEntries: 'Create Intercom Directory Entries',
    createTenantEntriesForUsers: 'Create Tenant Entries for Selected Users',
    exportSelectedUsers: 'Export Selected Users to CSV',
    exportToCsv: 'Export to CSV',
    mapping: 'Mapping',
    review: 'Review',
    custom: 'Custom',
    notes: 'Notes',
    mapFirstName: 'Please map First Name to proceed',
    mapLastName: 'Please map Last Name to proceed',
    tenantDisplayNameToLong: 'The display name can be max 32 characters. Please update or it’ll be trimmed to first 32 characters',
    assignMobCreds: 'Assign Mobile Credentials',
    faceScanned: 'Face Scanned?',
    appfolio: 'Appfolio',
    mds: 'MDS',
};
