// Code is base on jwt-decode npm package
// added changes related to our project needs as we have long numbers in project and we need to keep them as strings
// Added
// res = res.replace(/(?<=:)([^,"{}\s][^,{}\s]*)(?=[,}])/g, '"$1"'); make sure all values are wrapped in double quotes, as we have long numbers in project
// Modified output = str.replace(/-/g, '+').replace(/_/g, '/'); to output = str.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
// return parsed jwt token

import atobFunc from './atob';

function b64DecodeUnicode(str) {
    return decodeURIComponent(
        atobFunc(str).replace(/(.)/g, (m, p) => {
            let code = p.charCodeAt(0).toString(16).toUpperCase();
            if (code.length < 2) {
                code = `0${code}`;
            }
            return `%${code}`;
        }),
    );
}

export default str => {
    let output;
    output = str.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
        case 0:
            break;
        case 2:
            output += '==';
            break;
        case 3:
            output += '=';
            break;
        default:
            throw new Error('base64 string is not of the correct length');
    }

    try {
        let res = b64DecodeUnicode(output);
        res = res.replace(/(:\s*)([^,"{}\s][^,{}\s]*)(?=[,}])/g, '$1"$2"'); // make sure all values are wrapped in double quotes, as we have long numbers in project
        return JSON.parse(res);
    } catch (err) {
        return atob(output);
    }
};
