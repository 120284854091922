export const featureFlagsList = {
    enableAuditLogs: 'enableAuditLogs',
    enableAuditLogsDescFilter: 'enableAuditLogsDescFilter',
    enableTenantCsvUpload: 'enableTenantCsvUpload',
    doorUnlockSchedule: 'doorUnlockSchedule',
    pmsIntegration: 'pmsIntegration',
    milestoneIntegration: 'milestoneIntegration',
    vmsIntegration: 'vmsIntegration',
    eagleEyeIntegration: 'eagleEyeIntegration',
    enableIosDevices: 'enableIosDevices',
    enableWorkspaces: 'enableWorkspaces',
    enableDcuDevices: 'enableDcuDevices',
    enableTailgateDetection: 'enableTailgateDetection',
    enableReferralsOffers: 'allowedUsersForReferralSystemAuth',
    enableReferEarnButton: 'referAndEarnButton',
    enableLiveView: 'liveView',
    enableSiteUnits: 'siteUnits',
    enableElevators: 'enableElevators',
    enableStid: 'enableStid',
    keycards: 'keycards',
    enableCardReaders: 'cardReaders',
    deviceVolume: 'deviceVolume',
    enableDoorUnlockTone: 'enableDoorUnlockTone',
    cardReaders: 'cardReaders',
    securitySettingsGSuite: 'securitySettingsGSuite',
    enablePinPrivacySettings: 'enablePinPrivacySettings',
    enableDoorAlertPrivacySettings: 'enableDoorAlertPrivacySettings',
    enableYardiIntegration: 'yardiIntegration',
    enableOktaIntegration: 'oktaIntegrations',
    securitySettings: 'securitySettings',
    enablePassSettings: 'enablePassSettings',
    enableUserActivationDeactivation: 'enableUserActivationDeactivation',
    enableCustomPin: 'enableCustomPin',
    enableIosProvisioning: 'enableIosProvisioning',
    enableRexMapping: 'enableRexMapping',
    reports: 'reports',
    enableSwiftHealth: 'enableSwiftHealth',
    enableAuthByPhone: 'enableAuthByPhone',
    enableRentGpt: 'enableRentgpt',
    enableApiSettings: 'enableApiSettings',
    enableHelpSupport: 'enableHelpSupport',
    enableBilling: 'enableBilling',
    enableBillingInvoiceTemplates: 'enableBillingInvoiceTemplates',
    enableFeedLogs: 'enableFeedLogs',
    referralDismissDays: 'referralDissmissDays',
    createReport: 'createReport',
    enablePackageScan: 'enablePackageScan',
    enableThirdPartyWiegand: 'enableThirdPartyWiegand',
};

export default featureFlagsList;
