import React, { FC } from 'react';
import { MenuSelectBoxType, TSideNavMenu } from 'types/TSideNavMenu';
import RentGptSelect from './RentGptSelect';

type Props = {
    data: TSideNavMenu;
};
const SelectBoxItem: FC<Props> = ({ data }) => {
    if (data.selectBoxType === MenuSelectBoxType.renGpt) return <RentGptSelect />;
    return null;
};

export default SelectBoxItem;
