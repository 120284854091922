import { AnyAction } from 'redux';
import { getCachedRecentAuths, getCachedUserTokens } from 'utils/auth';
import jwtDecode from 'jwt-decode';
import { ICustomMap } from 'types/ICustomMap';
import { IProfileUser } from 'types/IUser';
import { IAuthenticationState } from './types';
import { authActions } from './actionTypes';

const userTokens = getCachedUserTokens();
const recentAuths = getCachedRecentAuths();

const initialState: IAuthenticationState = {
    isLoggedIn: !!userTokens,
    userTokens,
    recentAuths,
    errorMsg: null,
    user: {} as IProfileUser,
    isSuperAdmin: false,
};

function reducer(state = initialState, action: AnyAction): IAuthenticationState {
    const { type, payload } = action;
    switch (type) {
        case authActions.SET_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: payload,
            };
        case authActions.SET_USER_TOKENS:
            return {
                ...state,
                userTokens: payload,
            };
        case authActions.SET_RECENT_AUTHS:
            return {
                ...state,
                recentAuths: payload,
            };
        case authActions.SET_AUTH_ERROR:
            return {
                ...state,
                errorMsg: payload,
            };
        case authActions.LOG_OUT:
            return {
                ...state,
                isLoggedIn: false,
                userTokens: null,
                isSuperAdmin: false,
            };
        case authActions.SET_AUTH_USER: {
            const accessToken = getCachedUserTokens();
            const tokenData: ICustomMap | null = accessToken && jwtDecode(accessToken.access_token);
            return {
                ...state,
                user: payload,
                isSuperAdmin: !!tokenData && tokenData.is_super_admin,
            };
        }
        default:
            return state;
    }
}

export default reducer;
