import { IRecentAuth } from 'types/auth';

export const sortRecentAuth = (arr: IRecentAuth[]): IRecentAuth[] => {
    const sortedArr = arr.sort((a, b) => b.shortName.localeCompare(a.shortName));
    // @ts-ignore
    return sortedArr.sort((a, b) => {
        if (a?.timestamp === undefined && b?.timestamp === undefined) {
            // If both items have no timestamp, maintain their original order
            return 0;
        }
        if (a?.timestamp === undefined) {
            // Place items without timestamp after items with a timestamp
            return 1;
        }
        if (b?.timestamp === undefined) {
            // Place items without timestamp after items with a timestamp
            return -1;
        }
        // Sort based on the timestamp (newest first)
        return b.timestamp - a.timestamp;
    });
};
