export default {
    billingAccounts: 'Billing Accounts',
    details: 'Details',
    billingDetails: 'Billing Details',
    invoices: 'Invoices',
    accountStatus: 'Account Status',
    paymentStatus: 'Payment Status',
    shippingStatus: 'Shipping Status',
    invoiceType: 'Invoice Type',
    status: 'Status',
    linkToPay: 'Link to Pay',
    viewInvoice: 'View Invoice',
    dueDate: 'Due Date',
    amount: 'Amount',
    addOneTimeInvoice: 'Add New One Time Invoice',
    editOneTimeInvoice: 'Edit One Time Invoice',
    invoicePurpose: 'Invoice Purpose',
    qbInvoiceDocNum: 'QB Invoice Doc Number',
    qbInvoiceLink: 'QB Invoice Link',
    gracePeriod: 'Grace Period',
    suspendOnNonPayment: 'Suspend if unpaid?',
    suspendOnNonPaymentDesc: 'The account will be suspended if this invoice is not paid till Due Date+Grace Period',
    blockShipment: 'Block Shipment Until Paid?',
    blockShipmentDesc: "Hardware won't be shipped till this invoice is paid.",
    blockShipmentShort: 'Block Shipment',
    hardware: 'Hardware',
    installation: 'Installation',
    prePayment: 'Pre-Payment',
    shipment: 'Shipment',
    taxes: 'Taxes',
    software: 'Software',
    other: 'Other',
    purpose: 'Purpose',
    addRecurringInvoiceRules: 'Add Recurring Invoice Rules',
    editRecurringInvoiceRules: 'Edit Recurring Invoice Rules',
    allowCustomerActivate: 'Allow Customer to Activate?',
    signedTermDuration: 'Signed Term Duration',
    billingFrequency: 'Billing Frequency',
    amountPerInvoice: 'Amount Per Invoice',
    initialActivationDate: 'Initial Activation Date',
    endDate: 'End Date',
    invoiceTemplates: 'Invoice Templates',
    contactInfo: 'Contact Info',
    isIntegrator: 'Is Integrator?',
    contactNumber: 'Contact Number',
    emails: 'Emails',
    overdueDelinquent: 'Overdue Delinquent',
    overdueSuspended: 'Overdue Suspended',
    unpaidCurrent: 'Unpaid Current',
    paused: 'Paused',
    active: 'Active',
    contactName: 'Contact Name',
    createInvoiceTxt: "If Quickbook Link and ID are not provided, payment reminders won't be sent to customers.",
    createTemplateHelpTxt:
        'To set up recurring payments, such as subscriptions, create a recurring transaction template in Quickbook and map the template ID with Swiftlane account. The invoices generated against the recurring template will be automatically synced and shown in invoices table.',
    isIntegratorHelpTxt:
        "If this flag is turned ON, only the email IDs added in Contact Info page will get payment reminders. You can turn on 'CC Admins' if you want to trigger payment reminders to the admins as well",
    isIntegratorHelpTxt2:
        "If this flag is turned OFF, account admins will always get payment reminders unless the 'CC Admins' flag is turned OFF manually",
    inUsd: 'in USD',
    inMonths: 'in Months',
    inDays: 'in Days',
    selectStatus: 'Select Status',
    contactAccountTxt: "Please contact {{email}} in case you don't find link to pay the invoice.",
    mapInvoiceTemplate: 'Map Invoice Template',
    qbRecurringTemplateId: 'Quickbook Recurring Template ID',
    getTemplate: 'Get Template',
    swiftlaneAccountId: 'Swiftlane Account ID',
    qbRecTempId: 'Quickbook Recurring Template ID',
    qbTempName: 'Quickbook Template Name',
    scheduleInfo: 'Schedule Info',
    mapTemplate: 'Map Template',
    startDate: 'Start Date',
    intervalType: 'Interval Type',
    numInterval: 'Number of Interval',
    dayOfMonth: 'Day of the Month',
    dayOfWeek: 'Day of the Week',
    weekOfMonth: 'Week of the Month',
    monthOfYear: 'Month of the Year',
    nextDate: 'Next Date',
    prevDate: 'Previous Date',
    suspendIfUnpaid: 'Suspend if Unpaid?',
    sendReminders: 'Send Reminders?',
    syncInvoices: 'Sync Invoices',
    balance: 'Balance',
    reminderStartDate: 'Reminder Start Date',
    qbInvoiceId: 'QB Invoice ID',
    balanceHelp: 'Total amount of unpaid invoices for the customer',
    accountStatusHelp: {
        active: 'Account is active, the billing cycle for subscription is started. All accesses will work unless payment status is Suspended.',
        new: 'Account is newly created. No accesses will work till the account is Active.',
        deactivated: 'Account is no more in use.',
    },
    paymentStatusHelp: {
        paid: 'All invoices are paid.',
        unpaidCurrent: 'There are invoices due for payment.',
        suspended: 'Invoices overdue. Account is Suspended. No access will work.',
    },
    shippingStatusHelp: {
        readyToShip: 'Ok to ship the Hardware.',
        shippingBlocked: 'Do not ship any hardware, if requested on Order',
    },
    sfLinkHelp: 'Salesforce Opportunity page URL',
    projectTrackerLinkHelp: 'Notion URL for the Account from Project Tracker',
    initialActivationDateHelp:
        'This will be set as mentioned in recurring rule on Quickbook but will be auto-updated when the account gets Activated. The billing cycle starts on this date. The date is editable, if needed to be updated manually',
    startDateHelp: 'Date when billing subscription starts',
    intervalTypeHelp: 'Billing Duration',
    dayOfMonthHelp: 'Date of the month when bill should be generated.',
    nextDateHelp: 'Date on which next bill will be generated against this recurring template.',
    numIntervalHelp:
        'Frequency of bill generation. For ex. if Interval type is Monthly and Number of Intervals is 3, the bill will be generated once each 3 months which covers bill for last 3 months.',
    monthOfYearHelp:
        'When Interval Type is set to annual, this fields will decide which month of the year we generate the bill for that year.',
    prevDateHelp: 'Date on which last bill was generated against this recurring template.',
    suspendIfUnpaidHelp:
        'If any of the invoice generated against this template is not paid till Due Date + Grace period, we suspend the account for all accesses or we put on hold all HW shipments and installations, etc.',
    sendRemindersHelp: 'When ON, we send email reminders at regular intervals to customer regarding due invoices.',
    dueOnActivation: 'Mark Due on Activation?',
    isSent: 'Is Sent?',
    sentAt: 'Sent At',
    reminderNumSent: 'Reminder {num} Sent',
    suspensionAlertSent: 'Suspension Alert Sent',
    otherReminders: 'Other Reminders',
    ccAdmins: 'CC Admins',
    viewLineItems: 'Line Items',
    lineData: 'Line Items',
    viewReminderLogs: 'Logs',
    isCCAdminsHelpTxt:
        'If this flag is ON, all admins for this account will get payment reminders. Please turn Off the flag if you want only Channel Partners to receive payment reminders',
    contactEmailLabel: 'Enter one or more Email IDs to send Payment Reminders and Notifications',
    new: 'New',
    deactivated: 'Deactivated',
    createTemplate: 'Create Template',
    selectInterval: 'Select Interval',
    detailType: 'Detail Type',
    qty: 'Quantity',
    unitPrice: 'Unit Price',
    fullDetails: 'Full Details',
    link: 'Link',
    selectLineItems: 'Please Select Line Items',
    lastTemplateUpdate: 'Last Template Updated',
    createTemplates: 'Create Templates',
    prevAccount: 'Prev Account',
    nextAccount: 'Next Account',
    prevInvoice: 'Prev Invoice',
    nextInvoice: 'Next Invoice',
    accWithNoTemp: 'Accounts with no Template',
    searchAccount: 'Search Account',
    invoiceDate: 'Invoice Date',
};
