export default {
    tenants: 'Tenants',
    addTenant: 'Add Tenant',
    routeIntercomCalls: 'Route intercom calls to this users',
    selectRecipients: 'Select Recipients',
    tenantName: 'Tenant Name',
    editTenant: 'Edit Tenant',
    nameIsRequired: 'Name is mandatory (max of 32 characters)!',
    nameDisplayed: 'Name (displayed on admin dashboard)',
    sameAsName: 'Same as Name',
    displayNameRequired: 'Display Name is mandatory (max of 32 characters)!',
    displayNameDisplayed: 'Display Name (displayed on intercom)',
    siteRequired: 'Site is mandatory!',
    intercomRecipients: 'Intercom Recipients',
    pleaseSelectRecipients: 'Please select intercom recipients',
    addRecipient: 'Add recipient',
    source: 'Source',
};
