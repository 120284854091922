import { billingActionTypes } from 'store/billing/actionTypes';
import { AnyAction } from 'redux';
import { BillingState, PaymentStatus } from 'pages/Billing/types';
import { ICustomMap } from 'types/ICustomMap';
import { AccountStatus } from 'pages/CommandCenter/Accounts/types';

const initialState = {
    isAccountRestricted: false,
};

function reducer(state = initialState, action: AnyAction): BillingState | ICustomMap {
    const { type, payload } = action;
    switch (type) {
        case billingActionTypes.SET_BILLING_ACCOUNT_INFO:
            return {
                ...state,
                ...payload,
                isAccountRestricted: payload.company_status === AccountStatus.active && payload.payment_status === PaymentStatus.suspended,
            };
        default:
            return state;
    }
}

export default reducer;
