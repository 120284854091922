export default {
    yardi: 'Yardi',
    cardDesc: 'Smarter Property Management',
    cardSubDesc: 'Import resident data from Yardi. You will need property list code or property ID to enroll',
    propertyId: 'Property ID',
    properties: 'Properties',
    selectProperty: 'Select Property',
    commonWsdlLink: 'Common WSDL Link',
    username: 'Username',
    password: 'Password',
    serverName: 'Server Name',
    databaseName: 'Database Name',
    platform: 'Platform',
    enterYardiCredentials: 'Enter Yardi Credentials to fetch the list of available properties',
    testConnection: 'Test Connection',
    connectionSuccessful: 'Connection Successful. Please select the properties to connect to Swiftlane.',
    reauthoriseYardiConnection: 'Reauthorise Yardi Connection',
};
