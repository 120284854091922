import { textsCommon, textsUsers } from 'texts/index';
import { IOption } from 'types/IOption';
import routes from 'config/routes';

export const usersKeys = {
    firstName: 'first_name',
    lastName: 'last_name',
    email: 'email',
    role: 'role',
    status: 'status',
    createdAt: 'created_at',
    source: 'source',
    id: 'id_str',
    activationDate: 'activation_date',
    deactivationDate: 'deactivation_date',
    message: 'message',
    tenantEntryAdded: 'is_intercom_directory_created',
    mobCreds: 'is_mobile_credentials_assigned',
    groups: 'access_groups',
    notes: 'notes',
};

export const sortBy: IOption[] = [
    {
        label: textsCommon.firstName,
        value: usersKeys.firstName,
    },
    {
        label: textsCommon.lastName,
        value: usersKeys.lastName,
    },
    {
        label: textsCommon.email,
        value: usersKeys.email,
    },
    {
        label: textsCommon.role,
        value: usersKeys.role,
    },
    {
        label: textsCommon.status,
        value: usersKeys.status,
    },
    {
        label: textsCommon.createdAt,
        value: usersKeys.createdAt,
    },
    {
        label: textsCommon.source,
        value: usersKeys.source,
    },
];

export const sortByShort: IOption[] = [
    {
        label: textsCommon.firstName,
        value: usersKeys.firstName,
    },
    {
        label: textsCommon.lastName,
        value: usersKeys.lastName,
    },
    {
        label: textsCommon.email,
        value: usersKeys.email,
    },
    {
        label: textsCommon.createdAt,
        value: usersKeys.createdAt,
    },
];

export const listHeaderTabs = [
    {
        link: routes.users.root,
        label: textsUsers.allUsers,
    },
    {
        link: routes.users.moveIns,
        label: textsUsers.upcomingMoveIns,
    },
    {
        link: routes.users.moveOuts,
        label: textsUsers.upcomingMoveOuts,
    },
];

export const firstFieldsSetId = 0;

export enum ESteps {
    upload = 'upload',
    mapping = 'mapping',
    mappingKeyCards = 'mappingKeyCards',
    mappingSettings = 'mappingSettings',
    errorsReview = 'errors',
    review = 'review',
    verify = 'verify',
}

export enum EActions {
    sendInviteToAll = '0',
    sendInviteToSelected = '1',
    addAllToGroups = '2',
    addSelectedToGroups = '3',
    activateSelected = '4',
    deactivateSelected = '5',
    removeSelected = '6',
    addDeactivationDate = '7',
    clearDeactivationDate = '8',
    assignMobileCard = '9',
    createTenantEntries = '10',
}

export const userQueryKey = 'user';
export const usersQueryKey = 'users';
export const userProfileQueryKey = 'user-profile';
