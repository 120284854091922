export default {
    schedules: 'Schedules',
    errorFromToTime: 'From time can not be later or equal than To time',
    provideFromToTime: 'Please Provide From and To time',
    addSchedule: 'Add Schedule',
    scheduleName: 'Schedule Name',
    scheduleDescription: 'Schedule Description',
    accessType: 'Access Type',
    pickTime: 'Pick time',
    daysOfWeek: 'Days of Week',
    timeZone: 'Time Zone',
    timeBlocks: 'Time Blocks',
    timeBlocksDesc: "These time windows are checked against the local timezone for access points it's assigned to",
    addSTimeSlot: 'Add Time Slot',
    time: 'Time',
    editSchedule: 'Edit Schedule',
    assignAccessPointGroup: 'assign Access Points and Groups',
    assignAccessPointToThisSchedule: 'Assign Access Point to this Schedule',
    keepDoorUnlocked: 'Keep door unlocked during time intervals defined in schedules',
    setUpFirstPersonInRule: 'Set up first person in Rule',
    pickAccessGroups: 'Pick Access Groups, that can trigger this schedule to start after their first access everyday.',
    accessSchedules: 'Access Schedules',
    doorUnlockSchedules: 'Door Unlock Schedules',
    addAccessSchedule: 'Add Access Schedule',
    addDoorUnlockSchedule: 'Add Door Unlock Schedule',
    scheduledAccess: 'Scheduled Access',
    accessSchedulesInfo:
        'Access Schedule allows you to restrict access to doors during certain times for certain access groups. After creating the access schedule, assign it to an Access group on access point page.',
    doorUnlockInfo:
        'Door Unlock Schedule allows you to automatically unlock doors for specific time intervals, e.g. unlock a kitchen door at 9 AM and lock it at 6 PM. Everyone would be able to freely access it during specified time interval without need to present access credentials.',
    accessInterval: 'Access Interval',
    copyToSelected: 'Copy to Selected Days',
};
