import React, { FC } from 'react';

const WorkspaceIcon: FC = () => (
    <svg enableBackground='new 0 0 512 512' height='16' viewBox='0 0 512 512' width='16' xmlns='http://www.w3.org/2000/svg'>
        <g>
            <path d='m482.284 482h-39.573v-467c0-8.284-6.716-15-15-15h-343.423c-8.284 0-15 6.716-15 15v467h-39.572c-8.284 0-15 6.716-15 15s6.716 15 15 15h452.569c8.284 0 15-6.716 15-15s-6.716-15-15.001-15zm-277.415 0v-102.261h102.261v102.261zm132.261 0v-117.261c0-8.284-6.716-15-15-15h-132.261c-8.284 0-15 6.716-15 15v117.261h-75.581v-452h313.423v452z' />
            <path d='m223.748 66.309h-73.508c-8.284 0-15 6.716-15 15v73.508c0 8.284 6.716 15 15 15h73.508c8.284 0 15-6.716 15-15v-73.508c0-8.284-6.716-15-15-15zm-15 73.508h-43.508v-43.508h43.508z' />
            <path d='m361.76 66.309h-73.508c-8.284 0-15 6.716-15 15v73.508c0 8.284 6.716 15 15 15h73.508c8.284 0 15-6.716 15-15v-73.508c0-8.284-6.716-15-15-15zm-15 73.508h-43.508v-43.508h43.508z' />
            <path d='m223.748 205.954h-73.508c-8.284 0-15 6.716-15 15v73.508c0 8.284 6.716 15 15 15h73.508c8.284 0 15-6.716 15-15v-73.508c0-8.284-6.716-15-15-15zm-15 73.508h-43.508v-43.508h43.508z' />
            <path d='m361.76 205.954h-73.508c-8.284 0-15 6.716-15 15v73.508c0 8.284 6.716 15 15 15h73.508c8.284 0 15-6.716 15-15v-73.508c0-8.284-6.716-15-15-15zm-15 73.508h-43.508v-43.508h43.508z' />
        </g>
    </svg>
);

export default WorkspaceIcon;
