export default {
    rentGpt: 'RentGPT',
    chatHistory: 'Chat History',
    contentModeration: 'Content Moderation',
    chatID: 'Chat ID',
    addDocument: 'Add Document',
    editDocument: 'Edit Document',
    propertyInfo: 'For ex. Property information, Rent inoformation',
    leadActions: 'Leads',
    leadDetails: 'Lead Details',
    propertiesManagement: 'Properties Management',
    leadName: 'Lead Name',
    leadEmail: 'Lead Email',
    leadPhone: 'Lead Phone',
    assignAccount: 'Assign Account',
    unAssignAccount: 'Unassign Account',
    unAssignAccountConfirm: 'Are you sure you want to un-assign account from selected properties?',
    selectAccountToMap: 'Select account to map',
    type: 'Type',
    formSubmissions: 'Form Submissions',
    viewChats: 'View Chats',
    formDetails: 'Form Details',
    scheduleDate: 'Schedule Date',
    scheduleTime: 'Schedule Time',
    lead: 'Lead',
    filterBy: 'Filter By',
    startsWith: 'Starts With',
    formType: 'Form Type',
    propertyName: 'Property Name',
    propertyAddress: 'Property Address',
    enableRentGpt: 'Enable RentGPT',
    property: 'Property',
    settings: 'Settings',
    widgetSettings: 'Chat Widget Settings',
    widgetPosition: 'Widget Position',
    primaryColor: 'Primary Color',
    primaryTextColor: 'Primary Text Color',
    invalidHexCode: 'Invalid Hex Code',
    copyCode: 'Copy Code',
    buttonSize: 'Button Size',
    promptButtonSize: 'Prompt Button Size',
    promptMessage: 'Prompt Message',
    left: 'Left',
    right: 'Right',
    enterHexColor: 'Enter Hex Color',
    useCodeToEmbed: 'Use this code to embed the RentGPT widget on your website',
    actions: 'Actions',
    chatBotUi: 'Chatbot UI',
    defaultActionsTitle: 'Swiftlane Default Actions',
    contactUs: 'Contact Us',
    enable: 'Enable',
    priority: 'Priority',
    scheduleVisit: 'Schedule Visit',
    gallery: 'Gallery',
    priorityIsRequired: 'Priority is required',
    customActionsTitle: 'Custom Actions',
    label: 'Button Label',
    purpose: 'Purpose',
    url: 'URL',
    addNewAction: 'Add New Action',
    documents: 'Documents',
    title: 'Title',
    tags: 'Tags',
    noTitle: 'No Title',
    noDescription: 'No Description',
    noTags: 'No Tags',
    addTag: 'Add Your Tag',
    addImages: 'Add Images',
    filterByTag: 'Filter by tag',
    deleteSelected: 'Delete Selected',
    updateSelected: 'Update Selected',
    updateImages: 'Update Images',
    updateModalDesc: 'Update Tag, Title and Description for selected images.',
    purposeDesc:
        'The action is identified by purpose. This needs to be descriptive and explain the purpose of action clearly. For ex. for action "Schedule Tour", the description can be "Allows prospects to book a visit to the property as per their availability',
    labelDesc: "Action Name. Will be used as Button text. Should be as compact as possible so that button size doesn't get bigger.",
    enableDesc: "If disabled, the button won't be shown on navigation bar and the button will also not be sent in chat response.",
    contentModerationDescription:
        'RentGPT can work with highly unstructured data, as long as it has all the information needed to answer certain questions about the property. coverage is important, exact structure is not. This data will be used by rentGPT to provide insights about the property - please edit, add or remove data that can help the model perform better',
    addInfo:
        'Provide more details to RentGPT. When saving, RentGPT may break down the documents in smaller documents in order to improve the way it consumes the information. ',
    widgetSettingsDescription:
        "Please select the settings below to match the chatbot design to your website's theme. The code will be updated accordingly.",
    priorityDesc:
        'Used to position button on navigation bar on chatbot. Priority 1 will be placed first, P2 next to it and so on. If actions are more than 3, there will be a "More" dropdown on chatbot and additional actions will be listed under it.',
    urlDesc: 'It can be the URL to a form which user needs to fill and submit to perform the action.',
    image: 'Image',
    enterTagName: 'Enter Tag Name',
    notificationSettings: 'Notification Settings',
    note: 'Note',
    notificationRecipients: 'Notification Recipients',
    notificationRecipientsPlaceHolder: 'Please add email ids for recipients',
    notificationSettingsNoteTxt:
        'Please add the email addresses of all recipients who need to receive notifications when a lead is submitted or a new tour request is received for this property via RentGPT. You can add as many email addresses as you need and all of them will receive the notifications.',
    botId: 'Bot ID',
};
