export default {
    elevators: 'Elevators',
    viewDetails: 'View Details',
    mappedReaders: 'Mapped Readers',
    addElevator: 'Add Elevator',
    carNumber: 'Car Number',
    elevatorName: 'Elevator Name',
    elevatorToReadersMapping: 'Elevator to Readers Mapping',
    elevatorToReadersMappingDesc: 'Reader mapped to the Elevator. It enables Floor call buttons based on access.',
    floorToReadersMapping: 'Floor to Readers Mapping',
    floorToReadersMappingDesc: 'Reader installed outside the elevator. It enables elevator Call button on the floor.',
    selectReader: 'Select Reader',
    floorDetails: 'Floor Details',
    elevatorDetails: 'Elevator Details',
    floors: 'Floors',
    floorName: 'Floor Name',
    floorToAccessGroups: 'Floor access to Access Groups & Access Schedule',
    floorToAccessGroupsDesc: 'Users from the mapped access groups will get access to this floor.',
    accessGroups: 'Access Groups',
    selectAccessGroups: 'Select Access Groups',
    accessSchedules: 'Access Schedules',
    accessSchedulesDesc: 'The elevator on this floor will be restricted as per selected schedule for the corresponding Access Groups.',
    selectSchedule: 'Select Schedule',
    floorToReaderMapping: 'Floor To Reader Mapping',
    floorToReaderMappingDesc: 'Map a reader placed outside the elevator on this floor',
    floorButtonToRelayMapping: 'Floor Button To Relay Mapping',
    floorButtonToRelayMappingDesc: 'Map relays with the particular floor button on reader inside the elevator',
    floorUpCallBtn: 'Up Call button on Floor to relay mapping',
    floorUpCallBtnDesc: 'Map relays with the Elevator Call button placed on this floor',
    addEdit: 'Add / Edit',
    selectElevator: 'Select Elevator',
    selectFloor: 'Select Floor',
    device: 'Device',
    relays: 'Relays',
    elevator: 'Elevator',
    addFloor: 'Add floor',
    elevatorCars: 'Elevator cars',
    accessSchedulesForGroups: 'Access Schedules for mapped Access Groups',
    noAccessGroupsAssigned: 'No Access Groups assigned to this floor',
    noRelayMappedToFloor: 'No relays mapped to this floor yet. Please click "Add" to map relays.',
    noCallBtnMappedToFloor: 'No Call button mapped to this floor yet. Please click "Add/Edit" to map Call button.',
    viewRelayMapping: 'View Relay Mapping',
    noElevatorMapped: 'No Elevator mapped',
    noAccessGroupMapped: 'No Access Group mapped',
    noRelaysMapped: 'No Relays mapped',
    noReaderMapped: 'No Reader mapped',
    relayMapping: 'Relay Mapping',
    deselectScheduleConfirm: 'Are you sure you want to unmap the schedule?',
    elevatorsHelpTxt:
        "Once user accesses this door, it'll also enable Floor buttons in mapped elevator to which the user has access. Ex. If user has access to Floor 1 to 5 and EL1 is assigned to the door, when user accesses the door, floor buttons 1-5 will be enabled for pre-configured time.",
};
