import React, { FC, useContext } from 'react';
import { TSideNavMenu } from 'types/TSideNavMenu';
import { AppDataContext } from 'context/App';
import Item from '../../Item';

interface IProps {
    data: TSideNavMenu;
}

const ActivityFeedItem: FC<IProps> = ({ data }) => {
    const { wsMessagesCount } = useContext(AppDataContext);
    return <Item data={data} count={wsMessagesCount} />;
};

export default ActivityFeedItem;
