import React, { useEffect, useRef, FC, useState, useCallback, useMemo } from 'react';
import OT, { Session, Publisher, Stream, Device, PublisherProperties, SubscriberProperties } from '@opentok/client';

import cn from 'classnames';
import FilledUser from 'components/common/Icons/FilledUser';
import doorIcon from 'assets/svg/door.svg';
import { useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';
import { textsCommon, textsPushNotifications } from 'texts/index';
import CountDown from 'components/common/CountDown';
import Button from 'components/antd/Button';
import MicIcon from 'components/common/Icons/Mic';
import VideoIcon from 'components/common/Icons/Video';
import PhoneMissedIcon from 'components/common/Icons/PhoneMissed';
import { TNoArgsFuncVoid } from 'types/other';
import { useMutation } from 'react-query';
import { requestAnswerCall, requestRemoteUnlock } from 'pages/IntercomSettings/FrontDescIntercom/services';
import { toast } from 'react-toastify';
import { DownOutlined } from '@ant-design/icons';
import { Modal, Select } from 'antd';
import stylesCommon from 'styles/common.module.scss';

import ButtonsRow from 'components/common/button/ButtonsRow';
import PhoneCallIcon from 'components/common/Icons/PhoneCall';
import { checkMediaPermissions } from 'utils/mediaPermissions';
import { analyticsIntercomEventTypes, dashboardAnalytics, dashboardAnalyticsEvents } from 'hooks/useAnalytics/config';
import { TError } from 'types/errors';
import styles from './styles.module.scss';

// provide app_id room_sid join_token to auto join the room, otherwise it will wait for user to click on answer call
interface Props {
    roomCreds: {
        app_id?: string;
        room_sid: string;
        join_token?: string;
        room_name: string;
    };
    photo?: string;
    accessPointId: string;
    accountName?: string;
    siteName?: string;
    accessPointName?: string;
    authToken?: string; // swiftlane auth token, we need it to unlock door when receiving call from another account
    onEndCall: TNoArgsFuncVoid;
    onCallAnswer?: TNoArgsFuncVoid;
}

interface StreamCreatedEvent {
    stream: Stream;
}

const VonageRoom: FC<Props> = ({
    roomCreds,
    photo,
    accountName,
    siteName,
    accessPointName,
    accessPointId,
    authToken,
    onEndCall,
    onCallAnswer,
}) => {
    const publisherVideoSource = useRef<boolean>(true);
    const canAutoJoinRef = useRef<boolean>(!!roomCreds.app_id && !!roomCreds.room_sid && !!roomCreds.join_token);
    const containerRef = useRef<HTMLDivElement>(null);
    const publisherRef = useRef<Publisher | null>(null);
    const sessionRef = useRef<Session | null>(null);
    const [isDoorUnlocked, setDoorUnlocked] = useState<boolean>(false);
    const [showCountDown, setShowCountDown] = useState<boolean>(false);
    const [canKeepCallOn, setCanKeepCallOn] = useState<boolean>(false);
    const [withVideo, setWithVideo] = useState<boolean>(true);
    const [withAudio, setWithAudio] = useState<boolean>(true);
    const [isJoinedToSession, setIsJoinedToSession] = useState<boolean>(false);
    const [isConnecting, setIsConnecting] = useState<boolean>(false);
    const [userDevices, setUserDevices] = useState<Device[] | undefined>([]);
    const [selectedCamera, setSelectedCamera] = useState<string | null>(null);
    const [selectedMicrophone, setSelectedMicrophone] = useState<string | null>(null);
    const [openDeviceSelection, setOpenDeviceSelection] = useState<boolean>(false);

    const { mutateAsync: requestUnlock, isLoading: isUnlocking } = useMutation(requestRemoteUnlock);

    const { name: defaultAccountName } = useSelector(({ workspace }: ICustomMap) => workspace);

    const roomName = useMemo(() => roomCreds?.room_name, [roomCreds]);

    const checkUserDevices = useCallback(() => {
        OT.getDevices((error: OT.OTError | undefined, devices: Device[] | undefined) => {
            if (error) {
                const errMessage =
                    'Error getting devices, please allow access to your camera and microphone or check your browser settings.';
                window?.console.error('Error getting devices:', error);
                toast.error(errMessage);
                dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                    event_type: analyticsIntercomEventTypes.userMediaPermissionsError,
                    message: errMessage,
                });
                onEndCall();
                throw new Error(errMessage);
            }

            const videoDevices = devices?.filter(device => device.kind === 'videoInput');
            const audioDevices = devices?.filter(device => device.kind === 'audioInput');

            if (videoDevices?.length && audioDevices?.length) {
                setUserDevices(devices);
                setSelectedCamera(videoDevices[0].deviceId);
                setSelectedMicrophone(audioDevices[0].deviceId);
            } else {
                const errMessage =
                    'No video or audio devices found, please connect a camera and microphone or check your browser settings.';
                window?.console.error(errMessage);
                dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                    event_type: analyticsIntercomEventTypes.userMediaPermissionsError,
                    message: errMessage,
                });
                toast.error(errMessage);
                throw new Error(errMessage);
            }
        });
    }, [onEndCall]);

    const handleEndSession = useCallback(() => {
        if (publisherRef.current && sessionRef.current) {
            sessionRef.current.unpublish(publisherRef?.current);
            // publisherRef?.current?.publishVideo(false);
            // publisherRef?.current?.publishAudio(false);
            publisherRef?.current.destroy();
            setTimeout(() => {
                sessionRef.current?.disconnect();
            }, 200);
        }
    }, []);

    const joinRoomSession = useCallback(
        (appID: string, roomSID: string, joinToken: string) => {
            if (!joinToken || !appID || !roomSID || !containerRef.current) return;
            try {
                checkUserDevices();
            } catch {
                return;
            }
            setIsConnecting(true);
            sessionRef.current = OT.initSession(appID, roomSID);

            sessionRef.current.on('streamCreated', (event: StreamCreatedEvent) => {
                const subscriberOptions: SubscriberProperties = {
                    insertMode: 'append',
                    width: '100%',
                    height: '100%',
                    showControls: false,
                };
                sessionRef.current?.subscribe(event.stream, 'subscriber', subscriberOptions, error => {
                    if (error) {
                        onEndCall();
                        window?.console.error('Error subscribing to stream:', error);
                        toast.error('Error Happened, try again later.');
                    } else {
                        setIsJoinedToSession(true);
                        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                            event_type: analyticsIntercomEventTypes.callAnswered,
                        });
                    }
                    setIsConnecting(false);
                });
            });

            sessionRef.current.on('streamDestroyed', () => {
                setIsJoinedToSession(false);
                setIsConnecting(false);
                handleEndSession();
                onEndCall();
            });

            const publisherOptions: PublisherProperties = {
                insertMode: 'append',
                width: '100%',
                height: '100%',
                publishVideo: true,
                publishAudio: true,
                showControls: false,
                videoSource: selectedCamera || undefined,
                audioSource: selectedMicrophone || undefined,
            };

            publisherRef.current = OT.initPublisher('publisher', publisherOptions, error => {
                if (error) {
                    window?.console.error('Error initializing publisher:', error);
                } else {
                    window?.console.log('Publisher initialized:', publisherRef.current);
                }
            });

            sessionRef.current.connect(joinToken, error => {
                if (error) {
                    toast.error('Error connecting to the session');
                    window?.console.error('Error connecting to the session:', error);
                    onEndCall();
                } else {
                    sessionRef.current?.publish(publisherRef.current as Publisher, error2 => {
                        if (error2) {
                            window?.console.error('Error publishing:', error2);
                        }
                    });
                    publisherRef.current?.publishVideo(publisherVideoSource.current);
                }
            });
        },
        [checkUserDevices, handleEndSession, onEndCall, selectedCamera, selectedMicrophone],
    );

    useEffect(() => {
        // Auto join if all creds passed to component already, used for live video calls from access point/feed
        if (roomCreds.app_id && roomCreds.room_sid && roomCreds.join_token && canAutoJoinRef.current) {
            joinRoomSession(roomCreds.app_id, roomCreds.room_sid, roomCreds.join_token);
            canAutoJoinRef.current = false;
        }
    }, [joinRoomSession, roomCreds]);

    useEffect(() => {
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.callModalOpened,
        });
        return () => {
            handleEndSession();
        };
    }, [handleEndSession]);

    const handleEndCall = useCallback(() => {
        handleEndSession();
        onEndCall();
    }, [handleEndSession, onEndCall]);

    const { mutateAsync: answerCall } = useMutation(requestAnswerCall, {
        onSuccess: ({ respData }) => {
            if (respData?.join_token && respData?.vonage_app_id && respData.room_id) {
                joinRoomSession(respData?.vonage_app_id, respData.room_id, respData.join_token);
                if (onCallAnswer) onCallAnswer();
            } else {
                const errMessage = 'Error answering the call, please try again later.';
                toast.error(errMessage);
                dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                    event_type: analyticsIntercomEventTypes.failedAnswerCall,
                    message: errMessage,
                });
                onEndCall();
            }
        },
        onError: (error: TError) => {
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.failedAnswerCall,
                message: error?.message,
            });
        },
    });

    const reqAnswerCall = useCallback(async () => {
        await answerCall({
            platform: 'web',
            access_point_id: accessPointId,
            room_name: roomName,
            room_sid: roomCreds.room_sid,
            authToken,
        });
    }, [accessPointId, answerCall, authToken, roomCreds.room_sid, roomName]);

    const handleUnlockDoor = useCallback(async () => {
        try {
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.requestDoorUnlock,
            });
            await requestUnlock({
                access_point_id: accessPointId,
                room_name: roomName,
                room_sid: roomCreds.room_sid,
                authToken,
            });
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.doorUnlocked,
            });
            if (canKeepCallOn) {
                setShowCountDown(true);
                setDoorUnlocked(true);
            } else {
                handleEndCall();
            }
        } catch {
            toast.error('Error unlocking the door, try again later.');
        }
    }, [accessPointId, authToken, canKeepCallOn, handleEndCall, requestUnlock, roomCreds.room_sid, roomName]);

    const togglePublisherVideo = useCallback(() => {
        if (withVideo) {
            publisherRef.current?.publishVideo(false);
            setWithVideo(false);
        } else {
            publisherRef.current?.publishVideo(true);
            setWithVideo(true);
        }
    }, [withVideo]);

    const togglePublisherAudio = useCallback(() => {
        if (withAudio) {
            publisherRef.current?.publishAudio(false);
            setWithAudio(false);
        } else {
            publisherRef.current?.publishAudio(true);
            setWithAudio(true);
        }
    }, [withAudio]);

    const handleAnswerCall = useCallback(async () => {
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.answerCallButtonPressed,
        });
        try {
            await checkMediaPermissions();
        } catch (err) {
            toast.error('Please allow access to your camera and microphone');
            return;
        }
        try {
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.requestAnswerCall,
            });
            setIsConnecting(true);
            publisherVideoSource.current = false;
            await reqAnswerCall();
            setWithVideo(false);
            setWithAudio(true);
            setCanKeepCallOn(true);
        } catch {
            setIsConnecting(false);
        }
    }, [reqAnswerCall]);

    const onAudioBtnPress = useCallback(() => {
        if (isJoinedToSession) {
            togglePublisherAudio();
        }
    }, [isJoinedToSession, togglePublisherAudio]);

    const onVideoBtnPress = useCallback(() => {
        if (isJoinedToSession) {
            togglePublisherVideo();
        }
    }, [isJoinedToSession, togglePublisherVideo]);

    const toggleDeviceSelection = useCallback(() => {
        setOpenDeviceSelection(prev => !prev);
    }, []);

    const handleCameraChange = useCallback((value: string) => {
        setSelectedCamera(value);
        publisherRef.current?.setVideoSource(value);
    }, []);

    const handleMicrophoneChange = useCallback((value: string) => {
        setSelectedMicrophone(value);
        publisherRef.current?.setAudioSource(value);
    }, []);

    return (
        <>
            <div className={styles.videoChatWrap}>
                <div className={cn(styles.media)} ref={containerRef}>
                    {photo && !isJoinedToSession && (
                        <div className={styles.photo} style={{ backgroundImage: `url(${photo && photo})` }}>
                            <img src={photo} alt='' />
                        </div>
                    )}
                    {!photo && !isJoinedToSession && (
                        <div className={cn(styles.noPhoto)}>
                            <FilledUser className={styles.userIcon} />
                        </div>
                    )}
                    <div id='publisher' className={cn(styles.publisher, { [styles.hidden]: !isJoinedToSession })} />
                    <div id='subscriber' className={cn(styles.subscriber, { [styles.hidden]: !isJoinedToSession })} />
                </div>
                <div className={cn(styles.info)}>
                    <div className={styles.box}>
                        <div>
                            <img src={doorIcon} alt='' />
                        </div>
                        {(accountName || defaultAccountName) && <h2 className={styles.title}>{accountName || defaultAccountName}</h2>}
                        {siteName && <h3 className={styles.siteName}>{siteName}</h3>}
                        {accessPointName && (
                            <p>{`${textsPushNotifications.message1} ${accessPointName} ${textsPushNotifications.message2}.`}</p>
                        )}
                        {isDoorUnlocked && <div className={styles.unlockedMsg}>Door is Unlocked</div>}

                        {isJoinedToSession ? (
                            <div className={styles.actions}>
                                <div>
                                    <Button
                                        className={styles.actionBtn}
                                        type='primary'
                                        onClick={isConnecting ? undefined : onAudioBtnPress}
                                    >
                                        <MicIcon />
                                        {!withAudio && <em className={styles.redLine} />}
                                    </Button>
                                    <span className={styles.btnTxt}>
                                        {textsPushNotifications.audio}
                                        {withAudio && ` ${textsPushNotifications.off}`}
                                        {!withAudio && ` ${textsPushNotifications.on}`}

                                        <button className={styles.deviceSelection} type='button' onClick={toggleDeviceSelection}>
                                            <DownOutlined />
                                        </button>
                                    </span>
                                </div>
                                <div>
                                    <Button
                                        className={styles.actionBtn}
                                        type='primary'
                                        onClick={isConnecting ? undefined : onVideoBtnPress}
                                    >
                                        <VideoIcon />
                                        {!withVideo && <em className={styles.redLine} />}
                                    </Button>
                                    <span className={styles.btnTxt}>
                                        {textsPushNotifications.video}
                                        {withVideo && ` ${textsPushNotifications.off}`}
                                        {!withVideo && ` ${textsPushNotifications.on}`}

                                        <button className={styles.deviceSelection} type='button' onClick={toggleDeviceSelection}>
                                            <DownOutlined />
                                        </button>
                                    </span>
                                </div>
                                <div>
                                    <Button danger onClick={handleEndCall} className={cn(styles.actionBtn, styles.missBtn)}>
                                        <PhoneMissedIcon />
                                    </Button>
                                    <span className={cn(styles.btnTxt, styles.red)}>{textsPushNotifications.endCall}</span>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.answerBtns}>
                                <Button
                                    className={styles.answerBtn}
                                    type='primary'
                                    size='large'
                                    green
                                    ghost
                                    loading={isConnecting}
                                    onClick={handleAnswerCall}
                                    icon={<PhoneCallIcon />}
                                >
                                    {textsPushNotifications.answerCall}
                                </Button>
                                <Button
                                    className={styles.ignoreBtn}
                                    danger
                                    onClick={isConnecting ? undefined : handleEndCall}
                                    size='large'
                                    icon={<PhoneMissedIcon />}
                                >
                                    {textsPushNotifications.ignoreCall}
                                </Button>
                            </div>
                        )}
                        {isDoorUnlocked && showCountDown && (
                            <div className={styles.autoEndCallBox}>
                                <strong>
                                    Your call will end in <CountDown seconds={30} onDone={handleEndCall} /> seconds
                                </strong>
                            </div>
                        )}
                    </div>
                    <div className={styles.unlockBtn}>
                        <Button type='primary' onClick={handleUnlockDoor} loading={isUnlocking}>
                            {textsPushNotifications.unlockDoor}
                        </Button>
                    </div>
                </div>
            </div>
            <Modal
                title='Change Audio/Video device'
                open={openDeviceSelection}
                footer={null}
                destroyOnClose
                onCancel={toggleDeviceSelection}
                zIndex={1101}
                width={400}
                style={{ minWidth: 300 }}
            >
                <div className={styles.deviceSelectBox}>
                    <label className={stylesCommon.formLabel}>Select Audio Device</label>
                    <Select value={selectedMicrophone} onChange={handleMicrophoneChange}>
                        {userDevices
                            ?.filter(device => device.kind === 'audioInput')
                            .map(device => (
                                <Select.Option key={device.deviceId} value={device.deviceId}>
                                    {device.label}
                                </Select.Option>
                            ))}
                    </Select>
                </div>
                <div className={styles.deviceSelectBox}>
                    <label className={stylesCommon.formLabel}>Select Video Device</label>
                    <Select value={selectedCamera} onChange={handleCameraChange}>
                        {userDevices
                            ?.filter(device => device.kind === 'videoInput')
                            .map(device => (
                                <Select.Option key={device.deviceId} value={device.deviceId}>
                                    {device.label}
                                </Select.Option>
                            ))}
                    </Select>
                </div>
                <ButtonsRow>
                    <Button size='large' type='primary' onClick={toggleDeviceSelection}>
                        {textsCommon.buttons.done}
                    </Button>
                </ButtonsRow>
            </Modal>
        </>
    );
};

export default VonageRoom;
