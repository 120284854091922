export interface ILoginOptions {
    ad_login_with_email_passcode: boolean;
    app_login_with_email_code: boolean;
    app_login_with_email_passcode: boolean;
    login_with_phone_code: boolean;
    login_with_azure_ad: boolean;
    login_with_gsuite: boolean;
    login_with_okta: boolean;
}

export enum EExternalLoginTypes {
    gsuite = 'gsuite',
    okta = 'okta',
    azure = 'azure_ad',
}

export enum EAuthTypes {
    openid = 'openid',
}

export type OnSuccessLogin = (accessToken: string, refreshToken: string) => void;
