import { LocalTrackPublication, Room } from 'twilio-video';
import { Track } from 'twilio-video/tsdef/Track';
import { LocalAudioTrackPublication } from 'twilio-video/tsdef/LocalAudioTrackPublication';
import { LocalVideoTrackPublication } from 'twilio-video/tsdef/LocalVideoTrackPublication';

export const disconnectRoom = (room: Room): void => {
    room.localParticipant.tracks.forEach((trackPublication: LocalTrackPublication) => {
        // @ts-ignore
        trackPublication.track.stop();
        trackPublication.unpublish();
    });
    room.disconnect();
};

export const hasVideoCamera = async (): Promise<boolean> => {
    let devices = null;
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        devices = await navigator.mediaDevices.enumerateDevices();
    }
    if (devices) {
        return !!devices.find(({ kind }) => kind === 'videoinput');
    }
    return false;
};

export const muteMedia = (participants: Map<Track.SID, LocalAudioTrackPublication | LocalVideoTrackPublication>): void => {
    participants.forEach(publication => {
        publication.track.disable();
    });
};

export const unMuteMedia = (participants: Map<Track.SID, LocalAudioTrackPublication | LocalVideoTrackPublication>): void => {
    participants.forEach(publication => {
        publication.track.enable();
    });
};
