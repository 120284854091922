import { workspaceActions } from 'store/workSpace/actionTypes';
import { IWorkspace, IWorkspaceSettings } from 'pages/CommandCenter/Accounts/types';

export const setWorkspace = (payload: IWorkspace | null) => ({
    type: workspaceActions.SET_WORKSPACE,
    payload,
});

export const setWorkspaceSettings = (payload: IWorkspaceSettings | null) => ({
    type: workspaceActions.SET_WORKSPACE_SETTINGS,
    payload,
});
