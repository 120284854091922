import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { textsCommon } from 'texts/index';
import ExternalLinkIcon from 'components/common/Icons/ExternalLinkIcon';
import { TSideNavMenu } from 'types/TSideNavMenu';
import styles from './styles.module.scss';

interface IProps {
    data: TSideNavMenu;
    count?: number | null; // number in brackets
}

const Item: FC<IProps> = ({ data, count }) => {
    const { items, title, route, showOpenInNewTab } = data;
    return (
        <div>
            {items ? (
                title
            ) : (
                <NavLink to={route || ''} activeClassName={styles.activeItem} className={styles.link}>
                    <span>
                        {title}
                        {count && <span className={styles.count}>{count}</span>}
                    </span>
                </NavLink>
            )}
            {showOpenInNewTab && (
                <a className={styles.external} href={route} target='_blank' title={textsCommon.openInNewTab} rel='noreferrer'>
                    <ExternalLinkIcon />
                </a>
            )}
        </div>
    );
};

export default Item;
