export default {
    title: 'Access Groups',
    addGroupMembers: 'Add members to a group',
    generalInfo: 'General Information',
    accessGroup: 'Access Group',
    selectGroup: 'Select Group',
    pleaseSelectGroup: 'Please Select a Group',
    pleaseSelectUsers: 'Please Select Users',
    usersAddedSuccessfully: 'Users added Successfully',
    members: 'Members',
    addGroup: 'Add Group',
    addAccessGroup: 'Add Access Group',
    enterGroupName: 'Enter Custom Group Name',
    editGroup: 'Edit Access Group',
    groupName: 'Group Name',
    unAssignUserConfirm: 'Are you sure you want to remove user from the Group?',
    addUsers: 'Add Users',
    addUsersToGroup: 'Add Users to Door Access Group',
    addAccessPoint: 'Add Access Point',
    addAccessPoints: 'Add Access Points to Group',
    removeAccessPointConfirm: 'Are you sure you want to remove access point from the Group?',
    addAccessPointToGroup: 'Add Access Point To Access Group',
    removeFloorConfirm: 'Are you sure you want to remove Floor from the Group?',
    addFloorToGroup: 'Add Floor To Access Group',
    group: 'Group',
    successFullyCreated: 'successfully created',
    extraGroupsTitle: 'You can also select new Group Name from below frequently used names',
    groupsErrMsg: 'Please select one group name or add group name using text field',
    groupExistErrMsg: 'Group already exist, please enter another group name',
    groupsToBeCreated: 'Custom groups to be created ',
    searchAccessGroup: 'Search Access Group',
    groupNames: {
        everyone: 'Everyone',
        admins: 'Admins',
        users: 'Users',
        employees: 'Employees',
        maintenance: 'Maintenance',
        deliveries: 'Deliveries',
        residents: 'Residents',
        visitors: 'Visitors',
    },
};
