import { textsWorkspaces } from 'texts/index';

export const workspacesKeys = {
    name: 'name',
    workspaceName: 'workspace_name',
    customerType: 'customer_type',
    id: 'id_str',
    primaryColor: 'workspace_primary_color',
    accountStatus: 'status',
    paymentStatus: 'payment_status',
};

export const adminsKeys = {
    firstName: 'first_name',
    email: 'email',
    id: 'id_str',
    role: 'role',
};

export const customerTypes = [
    {
        label: textsWorkspaces.directCustomer,
        value: 'direct-customer',
    },
    {
        label: textsWorkspaces.integrator,
        value: 'integrator',
    },
    {
        label: textsWorkspaces.demo,
        value: 'demo',
    },
];

export enum EYardiPlatforms {
    sqlServer = 'SQL Server',
}

export const yardiPropertyKeys = {
    marketingName: 'marketing_name',
    address: 'address',
    propertyId: 'property_id',
};

export const acuMappingKeys = {
    deviceId: 'device_id',
    deviceType: 'device_type',
};

export const adminsQueryKey = 'admins';
export const workspacesQueryKey = 'workspaces';
export const workspaceQueryKey = 'workspace';
export const authAccountQueryKey = 'auth-account';
