import React, { FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import routes from 'config/routes';
import { ICustomMap } from 'types/ICustomMap';
import queryString from 'query-string';

const RedirectTo: FC = () => {
    const location = useLocation();
    const query: ICustomMap = queryString.parse(location.search);
    return <Redirect to={`${routes.integrations.locks.august.root}?state=${query.state}&code=${query.code}`} />;
};

export default RedirectTo;
