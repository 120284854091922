import React, { FC } from 'react';

const Mic: FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M8 0.666626C7.46957 0.666626 6.96086 0.87734 6.58579 1.25241C6.21071 1.62749 6 2.13619 6 2.66663V7.99996C6 8.53039 6.21071 9.0391 6.58579 9.41417C6.96086 9.78925 7.46957 9.99996 8 9.99996C8.53043 9.99996 9.03914 9.78925 9.41421 9.41417C9.78929 9.0391 10 8.53039 10 7.99996V2.66663C10 2.13619 9.78929 1.62749 9.41421 1.25241C9.03914 0.87734 8.53043 0.666626 8 0.666626V0.666626Z'
            stroke='#081C45'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path
            d='M12.6663 6.66663V7.99996C12.6663 9.23764 12.1747 10.4246 11.2995 11.2998C10.4243 12.175 9.23735 12.6666 7.99967 12.6666C6.762 12.6666 5.57501 12.175 4.69984 11.2998C3.82467 10.4246 3.33301 9.23764 3.33301 7.99996V6.66663'
            stroke='#081C45'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path d='M8 12.6666V15.3333' stroke='#081C45' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M5.33301 15.3334H10.6663' stroke='#081C45' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);

export default Mic;
