import { ICustomMap } from 'types/ICustomMap';
import queryString from 'query-string';
import { APIMethods } from 'hooks/useRequestApi/types';
import api from 'utils/api';

export const prepareRoute = (route: string, variables?: ICustomMap, query?: ICustomMap): string => {
    const keys = variables ? Object.keys(variables) : null;
    let newRoute = route;
    if (variables && keys && keys.length > 0) {
        keys.forEach((key: string) => {
            newRoute = newRoute.replace(`:${key}`, variables[key]); // "/dcu/devices/:id/" by {id: "324419261573987456"}
        });
    }
    const queryStr = query ? queryString.stringify(query || {}, { skipEmptyString: true, skipNull: true }) : '';
    newRoute = queryStr ? `${newRoute}?${queryStr}` : newRoute;
    return newRoute;
};

// eslint-disable-next-line
export const getAPI = (method: APIMethods) => {
    switch (method) {
        case APIMethods.GET:
            return api.get;
        case APIMethods.POST:
            return api.post;
        case APIMethods.PUT:
            return api.put;
        case APIMethods.DELETE:
            return api.delete;
        case APIMethods.PATCH:
            return api.patch;
        default:
            return api.get;
    }
};
