export default {
    augustLocks: 'August & Yale Locks',
    august: 'August & Yale',
    addConnection: 'Add Connection',
    connectionName: 'Connection Name',
    connectionCreated: 'Connection successfully created',
    cardDesc: 'Manage Unit Level Access. You will need admin account in August Home app',
    connections: 'Connections',
    mapLocks: 'Add Locks',
    deleteConnectionMsg: 'If you delete the connection, all August locks associated with this connection will be deleted from Swiftlane.',
    deleteConnectionTitle: 'Are you sure you want to delete the August Connection?',
    added: 'Added',
    notConnected: 'Available to add',
    connectLocksDesc: 'Please select locks to add/remove to Swiftlane',
    connectLocksDesc2: 'Only locks which are connected to August WiFi bridge will be added to Swiftlane',
    authenticate: 'Authenticate',
    owner: 'Owner',
    lockIsAdded: 'Lock is already added to Swiftlane',
    reAuth: 'Re-Auth',
    ownerEmail: 'Lock Owner Email',
    addConnectionDesc: 'Add new August locks connection to add locks to Swiftlane.',
    addLocksDesc: 'Select and Add locks to Swiftlane from August connection.',
    removeConnectionDesc: 'Remove connection from Swiftlane.',
    authenticateDesc: 'Use your August account credentials to authenticate the August connection.',
    reAuthDesc: 'Use your August account credentials to re-authenticate the August connection. ',
    removeLocksDesc: 'Remove selected locks from Swiftlane.',
    authConfirm: 'You will be redirected to August Lock’s webpage. Do you want to continue?',
    editConnectionDetails: 'Edit Connection name and description.',
    editConnection: 'Edit Connection',
    reviewAddedLocks: 'Review Added Locks',
    connectionOwnerEmail: 'Connection Owner Email',
};
