import { toast } from 'react-toastify';
import { TError } from 'types/errors';
import { analyticsIntercomEventTypes, dashboardAnalytics, dashboardAnalyticsEvents } from 'hooks/useAnalytics/config';

export const requestMediaPermissions = async () => {
    try {
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.userMediaPermissionsRequested,
        });

        const devices = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
        devices.getTracks().forEach(track => track.stop()); // Stop the tracks as we only need to check the access

        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.userMediaPermissionsGranted,
        });
    } catch (err) {
        const error = err as TError;
        window?.console.log('Error:', error?.name, error?.message);
        if (error?.name === 'NotFoundError' || error?.name === 'DevicesNotFoundError') {
            const errMessage = 'Requested Video/Mic device not found';
            toast.error(errMessage);

            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.userMediaPermissionsError,
                message: errMessage,
            });
        } else if (error?.name === 'NotAllowedError' || error?.name === 'PermissionDeniedError') {
            const errMessage = 'Media Permissions denied by the user';
            toast.error(errMessage);
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.userMediaPermissionsError,
                message: errMessage,
            });
        } else {
            const errMessage = error?.message || 'Failed to get Video/Mic permissions';
            toast.error(errMessage);
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.userMediaPermissionsError,
                message: errMessage,
            });
        }
        throw new Error(error);
    }
};

export const checkMediaPermissions = async () => {
    const permissions = await Promise.all([
        navigator.permissions.query({ name: 'camera' as PermissionName }),
        navigator.permissions.query({ name: 'microphone' as PermissionName }),
    ]);

    const hasCameraPermission = permissions[0].state === 'granted';
    const hasMicrophonePermission = permissions[1].state === 'granted';
    if (!hasCameraPermission || !hasMicrophonePermission) {
        await requestMediaPermissions();
    } else {
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.userMediaPermissionsAlreadyGranted,
        });
    }
};
