import React, { FC, memo, useEffect } from 'react';
import { analyticsIntercomEventTypes, dashboardAnalytics, dashboardAnalyticsEvents } from 'hooks/useAnalytics/config';

interface IProps {
    audio: string;
}

const Sound: FC<IProps> = ({ audio }: IProps) => {
    useEffect(() => {
        if (audio) {
            const audioInstance = new Audio(audio);
            const playPromise = audioInstance.play();
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.playSoundRequested,
            });
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {})
                    .catch(error => {
                        window?.console.info(error);
                        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                            event_type: analyticsIntercomEventTypes.playSoundError,
                            message: error?.message,
                        });
                    });
            }
        }
    }, [audio]);

    return <></>;
};

export default memo(Sound);
