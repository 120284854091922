import React, { FC } from 'react';

const PhoneIcon: FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M11.334 1.33203H4.66732C3.93094 1.33203 3.33398 1.92898 3.33398 2.66536V13.332C3.33398 14.0684 3.93094 14.6654 4.66732 14.6654H11.334C12.0704 14.6654 12.6673 14.0684 12.6673 13.332V2.66536C12.6673 1.92898 12.0704 1.33203 11.334 1.33203Z'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path d='M8 12H8.00667' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);

export default PhoneIcon;
