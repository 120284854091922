export default {
    unlockDoor: 'Unlock Door',
    ignoreCall: 'Ignore',
    video: 'Video',
    audio: 'Audio',
    message1: 'Someone is at the',
    message2: 'is requesting access to enter',
    on: 'on',
    off: 'off',
    endCall: 'End Call',
    answerCall: 'Answer',
};
