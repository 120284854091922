export default {
    title: 'Inventory Management',
    acuDcu: 'ACU/DCU manifests',
    records: 'Records',
    uploadAcuDcu: 'Upload ACU/DCU manifests',
    csv: 'CSV',
    clickOrDragToUpload: 'Click or drag file to this area to upload',
    downloadCsvTemplate: 'Download our CSV template here',
    uploadTemplateMsg: 'Please refer to the template for some columns that you need in order to finish the upload correctly.',
    deviceId: 'Device ID',
    deleteSelected: 'Delete selected',
    new: 'New',
    errors: 'Errors',
};
