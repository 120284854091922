import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import styles from './style.module.scss';

interface IContainerProps {
    className?: string;
    fullWidth?: boolean;
    children: ReactNode;
}

const Container: FC<IContainerProps> = ({ className, fullWidth, children }: IContainerProps) => (
    <div className={cn(styles.container, { [className || '']: className, [styles.fullWidth]: fullWidth })}>{children}</div>
);

export default Container;
