import React, { FC } from 'react';

const VisitorAccessIcons: FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M2.66699 9.3335H6.66699V13.3335' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.333 6.6665H9.33301V2.6665' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M9.33301 6.66667L13.9997 2' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M2 14.0002L6.66667 9.3335' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);

export default VisitorAccessIcons;
