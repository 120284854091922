import axios, { InternalAxiosRequestConfig } from 'axios';
import {
    cacheRecentAuths,
    cacheUserRole,
    cacheUserTokens,
    getCachedRecentAuths,
    getCachedUserTokens,
    removeCachedUserRole,
    removeCachedUserTokens,
    removeFailedAuthFromCachedRecentAuths,
} from 'utils/auth';
import { ICustomMap } from 'types/ICustomMap';
import { history } from 'utils/history';
import routes from 'config/routes';
import { store } from 'store/index';
import { setIsLoggedInAction, setRecentAuthsAction, setUserTokensAction } from 'store/authentication/actions';
import { adminAccess } from 'utils/adminAccess';
import queryString from 'query-string';
import parseJwt from 'utils/parseJwt';

const logOut = (companyId: string) => {
    removeCachedUserTokens();
    removeCachedUserRole();
    removeFailedAuthFromCachedRecentAuths(companyId);
    store.dispatch(setIsLoggedInAction(false));
    store.dispatch(setUserTokensAction(null));
    store.dispatch(setRecentAuthsAction(getCachedRecentAuths()));
    adminAccess.deleteToken();
    history.push(routes.auth.enterWorkspace);
};

const api = axios.create({
    baseURL: '/api/v1/',
    headers: {
        'Content-Type': 'application/json',
    },
    paramsSerializer: {
        serialize: params => {
            return queryString.stringify(params, { skipEmptyString: true, skipNull: true });
        },
    },
});

// @ts-ignore
api.interceptors.request.use(async (configuration: InternalAxiosRequestConfig & { isExternalCall: boolean }) => {
    const { isExternalCall, ...config } = configuration;
    const cachedTokens = store?.getState()?.authentication?.userTokens || getCachedUserTokens();
    const adminAccessToken = adminAccess.getToken(); // admin access token for client accounts

    if (cachedTokens?.access_token && !isExternalCall) {
        // this check is needed if need to path token direct throught api call and ignore cached tokens
        const hasPassedAuthorization = !!config?.headers?.Authorization || !!config?.headers?.authorization;
        const token = hasPassedAuthorization ? {} : { Authorization: `Bearer ${adminAccessToken || cachedTokens?.access_token}` };
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        config.headers = {
            ...config.headers,
            ...token,
        };
    }

    return config;
});

let refreshTokenIsInProccess = false;

api.interceptors.response.use(
    response => response.data,
    async error => {
        if (error.response && error.response.status === 401 && !refreshTokenIsInProccess) {
            refreshTokenIsInProccess = true;

            if (adminAccess.getToken()) {
                adminAccess.deleteToken();
                window?.location.reload();
            }

            const cachedTokens = getCachedUserTokens();
            const formData = new FormData();
            formData.append('grant_type', 'refresh_token');
            formData.append('refresh_token', cachedTokens?.refresh_token || '');

            const msg = error.response?.data?.message || error.response?.data?.detail;

            if (msg === 'Token expired or invalid, please log in') {
                try {
                    const { data: user }: ICustomMap = await axios.post('/api/v1/get_access_token/', formData, {
                        headers: { 'Content-Type': 'x-www-form-urlencoded' },
                    });

                    const { company_id: companyId } = parseJwt(user.access_token);
                    const recentAuths = getCachedRecentAuths() || {};

                    // Update cached recent AUTH
                    if (companyId && recentAuths) {
                        recentAuths[companyId] = {
                            refresh_token: user.refresh_token,
                            access_token: user.access_token,
                            id_str: companyId,
                            userId: recentAuths[companyId].userId || '',
                            commonUserId: recentAuths[companyId].commonUserId || '',
                            shortName: recentAuths[companyId].shortName || '',
                            workspaceName: recentAuths[companyId].workspaceName || '',
                            identityProvider: recentAuths[companyId].identityProvider || '',
                        };
                        // store.dispatch(setRecentAuthsAction(recentAuths));
                        cacheRecentAuths(recentAuths);
                    }

                    // Update cached tokens
                    cacheUserTokens({
                        access_token: user.access_token,
                        refresh_token: user.refresh_token,
                    });
                    // store.dispatch(
                    //     setUserTokensAction({
                    //         access_token: user.access_token,
                    //         refresh_token: user.refresh_token,
                    //     }),
                    // );

                    if (user.role) {
                        cacheUserRole(user.role);
                    }

                    // request original request with new access token
                    // const { config } = error;
                    // config.headers.Authorization = `Bearer ${user.access_token}`;
                    // return await api.request(error.config);
                    window.location.reload();
                } catch {
                    refreshTokenIsInProccess = false;
                    const { company_id } = parseJwt(error?.config?.headers?.Authorization?.replace('Bearer ', '')) || {};
                    // remove user from local storage to log user out
                    if (company_id) {
                        logOut(company_id);
                    }
                }
            }
        }
        return Promise.reject(error);
    },
);

export default api;
