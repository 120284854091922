export default {
    editReader: 'Edit Key Card Reader',
    readerName: 'Reader name',
    description: 'Description',
    modelNumber: 'Model number',
    generalInfo: 'General info',
    addKeyCardReader: 'Add Key Card Reader',
    weigand: 'Weigand',
    osdp: 'OSDP',
    controllerConnectionTitle: 'CONFIGURE CONNECTION to the controller',
    doorController: 'Door controller',
    readerTerminal: 'Reader terminal',
    selectDoorController: 'Select door controller',
    selectTerminal: 'Select terminal',
    connectToAccessPoint: 'Connect to an access point',
    selectAccessPoint: 'Select access point',
    accessPoint: 'Access Point',
    terminalDesc: 'For this door controller, select the wiegand or OSDP terminal where this card reader is connected',
    relayController: 'Relay controller',
    selectRelayController: 'Select relay controller',
    deleteKeyCardReader: 'Delete Key Card Reader',
    connectedToController: 'Connected to controller',
    supportedType: 'Supported type',
    terminalName: 'Terminal name',
    type: 'Type',
    terminal: 'Terminal',
    selectReader: 'Select Reader',
    connectElevators: 'Connect Elevators',
    elevators: 'Elevators',
    selectElevators: 'Select Elevators',
    connectFloor: 'Connect to a floor call button (reader outside elevator)',
    floor: 'Floor',
    selectFloor: 'Select floor',
    selectSite: 'Select Site',
    selectSiteToConnect: 'Select a Site to connect Access Point, Elevators or Floor',
    manufacturer: 'Manufacturer',
    stidReaderConfigFile: 'Mobile Keycards Reader Configurations File',
    sendStidConffile: 'Send the Mobile Keycards Configurations File',
    sendStidConfDesc: "You'll send an email with  URLs to download reader config file",
    plsEnterCorrectEmail: 'please Enter Correct Email',
    enterEmailsToShare: 'Enter Emails to Share',
    emailWillHave: 'This email will have',
    point1: 'The Mobile Keycards Reader Configuration file as attachment. This file is required to configure the Mobile Keycards reader via STid Mobile App',
    point2: 'Link to download the STid Mobile App (iOS and Android) required to configure the Mobile Keycards reader',
    point3: 'Link to the Step by Step guide on how to configure the Mobile Keycards Reader',
    configured: 'Configured',
    confPending: 'Config  Pending',
    learnMore: 'Learn More',
    isReaderPreConfigured: 'Is your reader pre-configured',
    isReaderPreConfiguredDesc:
        'Is your reader pre-configured at Swiftlane OR Have you configured the reader manually as per the instructions given',
    here: 'here',
    no: 'No',
    yes: 'Yes',
    additionalConfForReader: 'Additional Configurations for Mobile Keycards Reader',
    additionalConfForReaderDesc: 'Please make sure that the reader is pre-configured or manually configured as per the instructions in',
    thisArticle: 'this article',
    readerConfStatus: 'Reader Config Status',
};
