import React, { FC } from 'react';
import styles from 'components/App/styles.module.scss';
import { Link } from 'react-router-dom';
import routes from 'config/routes';
import logo from 'assets/svg/logo.inline.svg';
import { textsCommon } from 'texts/index';

const EmptyHeader: FC = () => (
    <div className={styles.mainLogo}>
        <Link to={routes.home}>
            <img className={styles.logo} src={logo} width={130} alt={textsCommon.swiftlane} />
        </Link>
    </div>
);

export default EmptyHeader;
