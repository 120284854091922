export default {
    title: 'Reports',
    desc: 'Generate activity and account reports',
    inProgress: 'In Progress',
    completed: 'Completed',
    failed: 'Failed',
    activityFeed: 'Activity Feed',
    accessPointDailyActivity: 'Activity summary by access point',
    timesIn: 'Times In',
    jobId: 'Job id',
    reportName: 'Report Name',
    reportType: 'Report type',
    startedAt: 'Started at',
    startedBy: 'Started by',
    finished: 'Finished',
    time: 'Time',
    cloneToNewReport: 'Clone into a new report',
    tryAgain: 'Try again',
    reportDownloading: 'Report Downloading',
    newReport: 'New Report',
    reportsHistory: 'Reports history',
    userAccessLevel: 'Users Access Level',
    activityFeedDesc: 'See all activity events, such as access granted, denied etc.',
    timesInDesc:
        'Generate a CSV report of entry times for each user for each day selected. This would use the local timezone of each site, and show the first time when a person arrived on site.',
    userAccessLevelDesc:
        'Lists all the doors and sites that a group of users or a single user has access to, and through what groups. This would allow debugging whether a person has access to a door or not. The result will be a downloadable CSV file.',
    accessPointDailyActivityDesc:
        'This report shows number of unique users and total number of access events at each access point in the buildings.',
    createTitle: 'Create a new report',
    createDesc: 'Use this wizard to generate a new report',
    reportTypeFields: 'Report type fields',
    generateReport: 'Generate report',
    pleaseSelectType: 'Please select type',
    pleaseEnterReportName: 'Please enter report name',
    users: 'Users',
    addUser: 'Add user...',
    sites: 'Sites',
    addSite: 'Add site...',
    accessPoints: 'Access points',
    addAccessPoint: 'Add access point...',
    accessGroups: 'Access Groups',
    addAccessGroup: 'Add Access group...',
    credentialType: 'Credential type',
    addCredentialType: 'Add credential type...',
    startDateTime: 'Start timestamp',
    endDateTime: 'End timestamp',
    addDateTime: 'Add date/time...',
    emailMe: 'Email me the report once completed',
    outputFormat: 'Output format',
    csv: 'CSV',
    pdf: 'PDF',
    reportIsCreating: 'Report is creating',
    userWeeklyActivity: 'User Weekly Activity',
    userWeeklyActivityDesc: 'The number of weekly access events per access point and user.',
    groupWeeklyActivity: 'Group Weekly Activity',
    groupWeeklyActivityDesc: 'The number of weekly access events per access point and Access group.',
    accessStatus: 'Access Status',
    deleteReportsConfirm: 'Are you sure you want to delete the selected reports?',
    download: 'Download',
    clone: 'Clone',
};
