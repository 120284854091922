import React, { FC } from 'react';

const ChipIcon: FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M11.9993 2.66797H3.99935C3.26297 2.66797 2.66602 3.26492 2.66602 4.0013V12.0013C2.66602 12.7377 3.26297 13.3346 3.99935 13.3346H11.9993C12.7357 13.3346 13.3327 12.7377 13.3327 12.0013V4.0013C13.3327 3.26492 12.7357 2.66797 11.9993 2.66797Z'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path d='M10 6H6V10H10V6Z' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6 0.667969V2.66797' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10 0.667969V2.66797' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M6 13.332V15.332' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M10 13.332V15.332' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.334 6H15.334' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M13.334 9.33203H15.334' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M0.666016 6H2.66602' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M0.666016 9.33203H2.66602' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);

export default ChipIcon;
