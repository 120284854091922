import { textsCommon } from 'texts/index';

export const minPinLen = 4;
export const maxPinLen = 8;

export const pinLenSizes = [4, 5, 6, 7, 8];

export const sequentialNum = '123456789';
export const revSequentialNum = '987654321';
export const repeatingPattern = /\b(\d)\1+\b/;

export const validatePin = (value: string, minLength = minPinLen, maxLength = maxPinLen) => {
    if (!value) throw new Error(`${textsCommon.setPinWith} ${minLength} - ${maxLength} ${textsCommon.setPinWithP2}`);
    const isSequential = sequentialNum.includes(value) || revSequentialNum.includes(value);
    const isRepeating = repeatingPattern.test(value);

    if (value.length < minLength || value.length > maxLength || value[0] === '0') {
        throw new Error(`${textsCommon.setPinWith} ${minLength} - ${maxLength} ${textsCommon.setPinWithP2}`);
    }

    if (isSequential) {
        throw new Error(textsCommon.sequentialDigitsNotAllowed);
    }
    if (isRepeating) {
        throw new Error(textsCommon.repeatedDigitsNotAllowed);
    }

    return true;
};
