import React, { FC } from 'react';

const MenuIcon: FC = () => (
    <svg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 9C0 8.44771 0.447715 8 1 8H17C17.5523 8 18 8.44771 18 9C18 9.55229 17.5523 10 17 10H1C0.447715 10 0 9.55229 0 9Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 1C0 0.447715 0.447715 0 1 0H23C23.5523 0 24 0.447715 24 1C24 1.55228 23.5523 2 23 2H1C0.447715 2 0 1.55228 0 1Z'
        />
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M0 17C0 16.4477 0.447715 16 1 16H23C23.5523 16 24 16.4477 24 17C24 17.5523 23.5523 18 23 18H1C0.447715 18 0 17.5523 0 17Z'
        />
    </svg>
);

export default MenuIcon;
