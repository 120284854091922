import React, { FC } from 'react';
import { Intercom as IntercomSdk } from '@intercom/messenger-js-sdk';
import { useQuery } from 'react-query';
import { requestGetUserHash } from 'components/Intercom/services';
import { useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';

const Intercom: FC = () => {
    const { isLoggedIn, user } = useSelector(({ authentication }: ICustomMap) => authentication || {});

    useQuery({
        queryKey: 'intercom',
        queryFn: () => requestGetUserHash(),
        enabled: isLoggedIn && !!user?.id_str,
        onSuccess: ({ respData }) => {
            const hash = respData?.intercom_hash;

            if (hash && user?.id_str) {
                IntercomSdk({
                    app_id: 'x2vxpesw',
                    email: user.email,
                    user_id: user.id_str,
                    name: `${user.first_name} ${user.last_name}`,
                    user_hash: hash,
                    alignment: 'right',
                });
            }
        },
    });

    return <></>;
};

export default Intercom;
