import { fcmActionTypes } from 'store/fcm/actionTypes';

export const setFCMToken = (payload: string | null) => ({
    type: fcmActionTypes.SET_FCM_TOKEN,
    payload,
});

export const setFCMServiceWorkerAvailability = (payload: boolean) => ({
    type: fcmActionTypes.SET_FCM_SERVICE_WORKER_AVAILABILITY,
    payload,
});
