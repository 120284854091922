export default {
    profile: 'User Profile',
    credentials: 'Credentials',
    faceIndex: 'Face Index',
    accessPin: 'Access Pin',
    keyCards: 'Key Cards',
    generatePin: 'Generate PIN',
    reGeneratePin: 'Re-Generate PIN',
    generatePinDesc: 'Generate PIN so that users can access doors without phone',
    revokePin: 'Revoke PIN',
    revokePinDesc: 'User will be able to access door with PIN (system sends it automatically by email)',
    searchSelect: 'Search & Select',
    assignKeyCard: 'Assign key Card',
    assignedKeyCards: 'Assigned Key cards',
    removeFaceIndexes: 'Remove Face Indexes',
    plsSelectCard: 'Please select card',
    email: 'Email',
    id: 'ID',
    status: 'Status',
    assignedGroups: 'Assigned Access groups',
    selectAccessGroup: 'Select Access Group',
    assignToUserGroup: 'Assign to access group',
    updateUserInfo: 'Update User Information',
    selectRole: 'Select role',
    roteIntercomCalls: 'Route Intercom Calls',
    regularPhoneCall: 'Regular Phone Call',
    videoCallSwiftlaneApp: 'Video Call on Swiftlane App',
    phoneNumValidationMsg: 'Provide provide phone number, min 8 and max 10 digits',
    uploadPhotos: 'Upload Photos',
    uploadPhotosForFaceRecognition: 'Upload face photos for face recognition door unlock',
    sendInvite: 'Send Invite',
    doorAccess: 'Door Access',
    addGroup: 'Add Group',
    removeUserFromGroup: 'Remove User From Group',
    openSchedule: 'Open schedule',
    removeFromGroupConfirm: 'You want to remove a user from the group',
    assignGroupsToUser: 'Assign Groups to User',
    assignGroup: 'Assign Group',
    usAssignGroup: 'Un-assign Group',
    goToDoorDetails: 'Go to Door Details',
    backToAccess: 'Back to Door Access',
    viewAll: 'View All',
    viewLess: 'View Less',
    groupsAccess: 'Groups Access',
    noSchedule: 'No Schedule',
    accessDesc: 'Below is the list of Access Groups and corresponding doors to which the user has access',
    doors: 'Doors',
    unlockDoor: 'Unlock Door',
    changePassword: 'Change Password',
    currentPass: 'Current Password',
    newPass: 'New Password',
    enterNewPass: 'Enter new password',
    confirmPass: 'Confirm Password',
    passMissMatch: 'Passwords Mismatch!',
    activity: 'Activity',
    intercomCalls: 'Intercom Calls',
    pinCodes: 'Pin Codes',
    missedCall: 'Missed call',
    acceptedCall: 'Accepted Call',
    unlockFailed: 'Unlock Failed',
    unlockSuccess: 'Unlock Success',
    visitorPasses: 'Visitor Pins',
    addVisitorPass: 'Add Visitor Pin',
    unitAccess: 'Unit Access',
    unitAccessDesc: 'Below is the list of Units and corresponding locks to which the user has access',
    unitName: 'Unit Name',
    unassign: 'Unassign',
    unassignUnitConfirmation: "Are you sure you want to revoke the user's access to this unit?",
    assignUnit: 'Assign Unit',
    assignUnitsToUser: 'Assign Units to User',
    backToUnitAccess: 'Back to Unit Access',
    assign: 'Assign',
    assignedUnits: 'Assigned Units',
    assignUnits: 'Assign Units',
    assignUnitsDesc: 'Please select units to assign to User',
    unAssignUnitDesc: 'Un-assign unit from the user',
    autoGenerate: 'Auto Generate',
    createOwnPin: 'Create own Pin',
    showAccessActivity: 'Show Access Activity',
    showAssociatedTenantsEntries: 'Show Associated Tenant entries',
    phoneNumber: 'Phone Number',
    fieldRequireUsePhone: "The field is mandatory as you've opted to route intercom calls to regular phone",
    subscriptions: {
        subscriptions: 'Subscriptions',
        planValidTill: 'Your Swiftlane Premium is valid till',
        trialExpiredOn: 'Your trial for Swiftlane premium is valid till',
        mySubscriptions: 'My Subscriptions',
        basicPlan: 'Swiftlane Basic',
        premiumPlan: 'Swiftlane Premium',
        plansTitle: 'Swiftlane Subscriptions plan',
        trialPremium: 'Swiftlane Premium Trial',
        validTill: 'Valid till',
        getPremium: 'Get Premium',
        manage: 'Manage Subscriptions',
        getPremiumForPins: {
            p1: 'Get the ',
            p2: 'Swiftlane Premium',
            p3: 'plan to create unlimited visitor pins.',
        },
        getEditAccess: {
            p1: 'Get',
            p2: 'Edit access',
            p3: 'to visitor pins',
        },
        upgradeToPremium: 'Upgrade to Premium',
        free: 'Free',
        month: 'month',
        annual: 'annual',
        monthly: 'Monthly',
        maxDuration: 'Max Visitor Pin Duration',
        days: 'days',
        upgradeTo: 'Please upgrade to',
        toViewFeed: 'to view Activity Feed',
    },
    services: {
        videoIntercomAccess: 'Video intercom access',
        pinAccess: 'Pin Access',
        mobileUnlock: 'Remote Unlock (via Swiftlane App)',
        maxVisitorPins: 'Limited number of Visitor Pins',
        unlimitedVisitorPins: 'Unlimited visitor pins',
        doorAccessUsingFace: 'Face Unlock to Access Door',
        intercomCallLogs: 'Intercom call logs',
        pinAccessLogs: 'Pin access logs',
    },
    enterEmail: 'Enter Email',
    enterCode: 'Enter Code',
    updateEmail: 'Update Email',
    getCode: 'Send Verification Code via email',
    reSendCode: 'Resend Verification Code',
    resendOtp: 'Resend Verification Code in',
    seconds: 'seconds',
    faceScansDone: 'Face Scans Done',
    tenantEntryAdded: 'Tenant Entry Added',
    frontDeskRecipient: 'Front Desk Recipient',
    accessGroups: 'Access Groups',
    mobileKeyCards: 'Mobile Keycards',
    copyPin: 'Copy PIN',
};
