import React, { FC, memo } from 'react';
import { Card, Typography } from 'antd';
import { textsCommon } from 'texts/index';
import { Link } from 'react-router-dom';
import routes from 'config/routes';
import Button from 'components/antd/Button';
import styles from './styles.module.scss';

const PermissionDenied: FC = () => {
    const { Title } = Typography;
    return (
        <Card className={styles.container}>
            <Title level={4}>{textsCommon.noPermissionContactAdmin}</Title>
            <Link to={routes.auth.enterWorkspace}>
                <Button ghost type='primary'>
                    {textsCommon.navSettings.logout}
                </Button>
            </Link>
        </Card>
    );
};

export default memo(PermissionDenied);
