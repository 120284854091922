import { AnyAction } from 'redux';
import { Property } from 'pages/RentGpt/types';
import { RentGptPropertyLocalStorageKey } from 'pages/RentGpt/config';
import { workspaceActions } from '../workSpace/actionTypes';
import { TState } from './types';
import { rentGptActionTypes } from './actionTypes';

const cachedProperty: string | null = localStorage.getItem(RentGptPropertyLocalStorageKey);
const propertyData: Property | null = cachedProperty ? JSON.parse(cachedProperty) : null;

const initialState: TState = {
    property: {} as Property,
    properties: null, // use this property to store the list of properties to have possibility to preselect property is botID is present in URL
};

function reducer(state = initialState, action: AnyAction): TState {
    const { type, payload } = action;
    switch (type) {
        case rentGptActionTypes.SET_RG_PROPERTY:
            return {
                ...state,
                property: payload,
            };
        case rentGptActionTypes.SET_RG_PROPERTIES:
            return {
                ...state,
                properties: payload,
            };
        case workspaceActions.SET_WORKSPACE: {
            // when current account data is loaded, we need to check if rentGpt company is same as cached property company to prevent fails on other accounts with unknown property
            const { id_str: accountId } = payload || {};
            const prop = propertyData?.account_details?.company_id_str === accountId ? propertyData : {};
            return {
                ...state,
                ...prop,
            };
        }
        default:
            return state;
    }
}

export default reducer;
