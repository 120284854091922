import { TDispatch, TGetState } from 'store/index';
import { ITokens, IRecentAuths } from 'types/auth';
import { cacheRecentAuths, getLogOutRecentAuths, removeCachedUserCreds } from 'utils/auth';
import { IProfileUser } from 'types/IUser';

import { authActions } from './actionTypes';

export const setIsLoggedInAction = (payload: boolean) => ({
    type: authActions.SET_IS_LOGGED_IN,
    payload,
});

export const setUserTokensAction = (payload: ITokens | null) => ({
    type: authActions.SET_USER_TOKENS,
    payload,
});

export const setAuthErrorAction = (payload: string | null) => ({
    type: authActions.SET_AUTH_ERROR,
    payload,
});

export const setRecentAuthsAction = (payload: IRecentAuths | null) => ({
    type: authActions.SET_RECENT_AUTHS,
    payload,
});

export const setAuthedUser = (payload: IProfileUser | null) => ({
    type: authActions.SET_AUTH_USER,
    payload,
});

export const removeCurrentAccountFromRecentAuthAction =
    (id: string): any => // eslint-disable-line
    (dispatch: TDispatch, getState: TGetState): void => {
        const {
            authentication: { recentAuths },
        } = getState();

        if (id) {
            const data = { ...recentAuths };
            delete data[id];
            cacheRecentAuths(data);
            dispatch(setRecentAuthsAction(data));
        }
    };

export const logOutRecantAuthAction =
    (): any => // eslint-disable-line
    (dispatch: TDispatch, getState: TGetState): void => {
        const { workspace } = getState();
        const accountId = workspace?.id_str;

        if (accountId) {
            const authes = getLogOutRecentAuths(accountId);
            cacheRecentAuths(authes || {});
            dispatch(setRecentAuthsAction(authes));
        }
    };

export const logOutAction =
    (): any => // eslint-disable-line
    (dispatch: TDispatch): void => {
        removeCachedUserCreds();
        dispatch({ type: authActions.LOG_OUT });
    };
