export default {
    iosDevices: 'iOS Devices',
    deviceInfo: 'Device Info',
    hexNodeId: 'Hexnode ID',
    deviceName: 'Device Name',
    kioskMode: 'Kiosk Mode',
    serialNumber: 'Serial Number',
    accessPoint: 'Access Point',
    modelName: 'Model Name',
    internalDBId: 'Internal DB ID',
    lastReported: 'Last Reported',
    enrolledTime: 'Enrolled time',
    site: 'Site',
    company: 'Company',
    rebootDevice: 'Reboot Device',
    accessPointAssigned: 'Access Point is assigned',
    changeKioskModeConfirm: 'Are you sure you want to change kiosk mode?',
    rebootingConfirm: 'Are you sure you want to reboot device?',
    rebootSuccess: 'Device rebooted',
    provisionCode: 'Provision Code',
    provision: 'Provision',
    deProvision: 'De-Provision',
    deviceProvisionedSuccess: 'Device Provisioned Successfully',
    subTitle: 'Manage Swift Readers and their bindings with access points',
    list: 'List',
    refreshFromHexNode: 'Refresh from Hexnode',
    assignAccessPoint: 'Assign Access Point',
    exitAssignMode: 'Exit Assign Mode',
    workspaceIsAssigned: 'Account Is Assigned',
    thermalState: 'Thermal State',
    akuvox: 'Akuvox',
    swiftReaders: 'Swift Readers',
    swiftReaderX: 'Swift Reader X',
    iPhone11: 'iPhone 11',
    iPhoneXR: 'iPhone XR',
    appVers: 'App Version',
    hexnodeURL: 'Hexnode URL',
    open: 'Open',
    appVersion: 'App Version',
    accountId: 'Account ID',
    telemetry: 'Telemetry',
    accessPointId: 'Access Point ID',
    reboot: 'Reboot',
    links: 'Links',
    webSocketsInfo: 'WebSockets Info',
    connectedAt: 'Connected at',
    lastActiveAt: 'Last Active at',
    sourceIp: 'Source IP',
    webSockets: 'WebSockets',
    on: 'On',
    off: 'Off',
    privateIp: 'Private IP',
    unassignAccessPintConfirm: 'Are you sure you want to unassign access point?',
};
