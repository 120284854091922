export default {
    title: 'Intercom Settings',
    frontDesc: 'Front Desk Intercom',
    tenant: 'Tenant',
    tenantDirectory: 'Intercom Directory',
    qrCodes: 'QR Codes',
    intercomDesk: {
        siteName: 'Site Name',
        address: 'Address',
        routeFrontDeskCallsUsers: 'Route Front desk calls to these users',
        routeCallsPrimaryUsers: 'Route Front desk calls to these Primary users',
        setUpFrontDesk: 'Set up Front Desk Intercom Recipients',
        assignRemoveUsers: 'Assign/Remove Users',
        setUpScheduledCallRouting: 'Set up Scheduled Call Routing',
        addUser: 'Add User',
        // addSchedule: 'Add schedule',
        frontDescSettings: 'Front desk intercom settings',
        pickUpAnotherSchedule: 'Pick another schedule',
        fallBackUsers: 'Fallback Users to route Front Desk call when primary users fail to respond (in 30 Seconds)',
        primaryUsers: 'Primary Front desk Users',
        secondaryUsers: 'Fallback (Secondary) Front desk Users',
        createSchedule: 'Create Schedule',
        primaryUsersInfo:
            'This users will receive intercom call notification when a visitor selects "Call front desk" option on Access Point. Applicable 24/7.',
        secondaryUsersInfo:
            'This users will receive intercom call notification when Primary recipients do not respond to front desk call for 30 seconds and user selects "Call secondary desk" on Access Point. Applicable 24/7.',
    },
    tenants: {
        tenants: 'Tenants',
        csv: 'CSV',
        addTenant: 'Add Entry',
        editTenant: 'Edit Tenant',
        uploadTenantsCsv: 'Upload Tenants CSV',
        uploadCsv: 'Upload CSV',
        reviewEdit: 'Review & Edit',
        verifyImportedData: 'Verify Imported Data',
        csvUploadDesc:
            'Use this CSV upload tool to bulk import entries for tenant directory into the system. Please create a CSV style format file, that can be easily exported from Excel or Google Sheets, and upload that here. Please refer to the template for some columns that you need in order to finish the upload correctly.',
        downloadCsvTemplate: 'Download our CSV template here',
        nextReview: 'Next: Review',
        nextVerify: 'Next: Verify',
        done: 'Done',
        cancelUpload: 'Cancel Upload',
        onlyUploadOneFile: 'You can only upload one file',
        uploadedSuccessfully: 'uploaded successfully',
        successList: 'Success List',
        failedList: 'Failed List',
        intercomRecipients: 'Intercom Recipients',
        errors: 'Errors',
        deleteRow: 'Delete Row',
        reValidate: 'Re-validate',
        next: 'Next',
        reviewEditDesc:
            'You can either edit and revalidate details based on error descriptions in below table or edit the csv and upload again.',
        guidelinesToFillCsv: 'Guidelines to fill the CSV -',
        displayNameMandatory:
            'Display Name is a mandatory field - this will be displayed on Swiftlane readers under Intercom Directory. Make sure you assign a unique Display name for each tenant entry',
        recipientsMustBeValid: 'Any emails added as recipient should be a valid user in your Account',
        atleastOneRecipient: 'There should be at least one recipient against each Tenant entry',
        entriesCreatedSuccess: 'Below tenant entries are created successfully.',
        clickOrDragToUpload: 'Click or drag file to this area to upload',
        siteName: 'Site Name',
        shouldBePresentInWorkspace: 'should be present in your account',
        displayNameLessThan32: 'Display Name should not be more than 32 characters',
        removeSelected: 'Remove selected',
        assignSite: 'Assign Site to selected tenants',
        assignRecipients: 'Assign Recipients to selected tenants',
    },
    qr: {
        qrDetails: 'QR Code Details',
        codes: 'QR Codes',
        addQrCode: 'Add QR Code',
        deactivated: 'Deactivated',
        addQr: 'Add QR Code',
        downloadQr: 'Download QR Code',
    },
};
