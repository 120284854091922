export default {
    healthCheck: 'Health Check',
    healthCheckSettings: 'Health Check Settings',
    healthActivity: 'Health Activity',
    customizeSettings: 'Customize your health initiative',
    view: 'View',
    setUpEmployeeChecking: 'Set up your employee health checking',
    capacityPlaning: 'Capacity Planning',
    capacityPlaningTxt: 'Configure your office to meet current codes',
    emergencyContacts: 'Emergency Contacts',
    emergencyContactsTxt: 'Important contact info to assist your employee needs',
    settingsDesc: 'Require employees to perform self health checks using the Swiftlane app',
    turnOnHealthCheck: 'Turn on Health Check',
    turnOnHealthCheckDesc: 'This will require employees to register their health activity before coming to work',
    denyAccess: 'Deny Access',
    denyAccessDesc: 'Revoke access for failed and missing Health Checks',
    selectGroupsDesc: 'Select the groups you want to enroll in Health Check',
    healthCheckFrequency: 'Health Check Frequency',
    healthCheckFrequencyDesc: 'Select the frequency at which checks are required by employees.',
    everyday: 'Every day',
    twiceAWeek: 'Twice a week',
    weekly: 'Weekly',
    capacityPlaningDesc: 'Configure alerts and capacity limits for your building',
    alertLimit: 'Alert Limit',
    alertLimitDesc: 'Alert admins when capacity limit is reached',
    maxCapacity: 'Max Capacity',
    maxCapacityDesc: 'Set the maximum allowed capacity',
    denyCapacityAccess: 'Deny access when capacity is reached',
    emergencyContactsDesc: 'A way for people to contact in case of emergency',
    addContact: 'Add Contact',
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    editContact: 'Edit Contact',
    title: 'Title',
    selectEmployee: 'Select employee',
    approveAccess: 'Approve Access',
    revokeAccess: 'Revoke Access',
    lengthOfTime: 'Length of time',
    lengthOfTimeDesc: 'How long should access be revoked?',
    revokeDetails: 'Revoke Details',
    by: 'By',
    date: 'Date',
    noAccessTill: 'No access till',
    approveAccessDesc: 'Cancel access revocation',
};
