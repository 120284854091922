import { FC } from 'react';

export type TIcon = () => JSX.Element;

export enum EIconColorType {
    fill = 'fill',
    stroke = 'stroke',
}

export enum MenuSelectBoxType {
    renGpt = 'renGpt',
}

export type TSideNavMenu = {
    icon?: TIcon | FC;
    iconColorType?: EIconColorType;
    title?: string;
    route?: string;
    launchDarklyFlag?: string;
    isCommandCenter?: boolean;
    isBilling?: boolean;
    showOpenInNewTab?: boolean;
    workspaceSettingFlag?: string;
    selectBoxType?: MenuSelectBoxType;
    items?: TSideNavMenu[];
};
