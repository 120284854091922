export class AdminAccess {
    private readOnlyAccessToken = 'readOnlyAccessToken';

    public setToken = (token: string): void => {
        localStorage.setItem(this.readOnlyAccessToken, token);
    };

    public getToken = (): string | null => {
        return localStorage.getItem(this.readOnlyAccessToken);
    };

    public deleteToken = (): void => {
        localStorage.removeItem(this.readOnlyAccessToken);
    };
}

export const adminAccess = new AdminAccess();
