import { QueryFunctionContext } from 'react-query/types/core/types';
import { APIMethods, apiProxy, TResponse } from 'utils/api/apiRequestProxy';
import { IMessage } from 'components/PushNotifications/types';
import { isProd } from 'utils/isProd';
import { ICustomMap } from 'types/ICustomMap';
import {
    ChatDocument,
    ChatMessages,
    ChatSessions,
    Documents,
    FormSubmission,
    GalleryImage,
    Lead,
    Properties,
    RentGptAction,
    ReqActions,
    ReqChatMessages,
    ReqChatSessions,
    ReqCreateDocument,
    ReqDeleteDocument,
    ReqDeleteImage,
    ReqDocument,
    ReqDocuments,
    ReqFormSubmissions,
    ReqGalleryImages,
    ReqLeads,
    ReqMapProperties,
    ReqProperties,
    ReqSaveAction,
    ReqSaveDefaultAction,
    ReqUpdateDocument,
    ReqUpdateImage,
    ReqUploadImage,
    ReqGalleryBulkActions,
    ReqPropertyNotificationSettings,
    PropertyNotificationSettings,
    ReqSavePropertyNotificationSettings,
} from './types';

const host = isProd() ? 'https://rentgpt.swiftlane.com' : 'https://rentgpt-j4vz52w6ia-ue.a.run.app';
// const host = 'http://localhost:8080';
// const baseURL = `${process.env.REACT_APP_RENT_GPT_API || host}/api/v1`;
const baseURL = `${host}/api/v1`;

export async function requestProperties({
    queryKey,
}: QueryFunctionContext<[string] | [string, ReqProperties]>): Promise<TResponse<Properties>> {
    const [, query] = queryKey;
    return apiProxy<Properties>({ method: APIMethods.GET, url: '/chat-bot/properties', params: query });
}

export async function requestMapPropertiesToAccount(payload: ReqMapProperties): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/chat-bot/map-account', data: payload });
}

export async function requestChatSessions({
    queryKey,
}: QueryFunctionContext<[string] | [string, ReqChatSessions]>): Promise<TResponse<ChatSessions>> {
    const [, query] = queryKey;
    return apiProxy<ChatSessions>({ method: APIMethods.GET, url: '/chat-sessions', params: query, baseURL });
}

export async function requestChatMessages({ queryKey }: QueryFunctionContext<[string, ReqChatMessages]>): Promise<TResponse<ChatMessages>> {
    const [, query] = queryKey;
    return apiProxy<ChatMessages>({ method: APIMethods.GET, url: `/chat-messages/${query.chatSessionID}`, params: query, baseURL });
}

export async function requestDocuments({ queryKey }: QueryFunctionContext<[string, ReqDocuments]>): Promise<TResponse<Documents>> {
    const [, query] = queryKey;
    return apiProxy<Documents>({ method: APIMethods.GET, url: '/list-documents', params: query, baseURL });
}

export async function requestDeleteDocument(query: ReqDeleteDocument): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.DELETE, url: '/delete-document', params: query, baseURL });
}

export async function requestUpdateDocument(payload: ReqUpdateDocument): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/edit-document', data: payload, baseURL });
}

export async function requestCreateDocument(payload: ReqCreateDocument): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/add-document', data: payload, baseURL });
}

export async function requestDocument({ queryKey }: QueryFunctionContext<[string, ReqDocument]>): Promise<TResponse<ChatDocument>> {
    const [, query] = queryKey;
    return apiProxy<ChatDocument>({ method: APIMethods.GET, url: '/get-document', params: query, baseURL });
}

export async function requestLeads({ queryKey }: QueryFunctionContext<[string, ReqLeads]>): Promise<TResponse<Lead[]>> {
    const [, query] = queryKey;
    return apiProxy<Lead[]>({ method: APIMethods.GET, url: '/leads', params: query, baseURL });
}

export async function requestFormSubmissions({
    queryKey,
}: QueryFunctionContext<[string, ReqFormSubmissions]>): Promise<TResponse<FormSubmission[]>> {
    const [, query] = queryKey;
    return apiProxy<FormSubmission[]>({ method: APIMethods.GET, url: '/form-submission', params: query, baseURL });
}

export async function requestActions({ queryKey }: QueryFunctionContext<[string, ReqActions]>): Promise<TResponse<RentGptAction[]>> {
    const [, query] = queryKey;
    return apiProxy<RentGptAction[]>({ method: APIMethods.GET, url: '/actions', params: query, baseURL });
}

export async function requestSaveDefaultAction(payload: ReqSaveDefaultAction): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/default-actions', data: payload, baseURL });
}

export async function requestCreateAction(payload: ReqSaveAction): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/actions', data: payload, baseURL });
}
export async function requestUpdateAction(payload: ReqSaveAction & { id: string }): Promise<TResponse<IMessage>> {
    const { id, ...data } = payload;
    return apiProxy<IMessage>({ method: APIMethods.PUT, url: `/actions/${id}`, data, baseURL });
}

export async function requestDeleteAction({ botID, id }: ICustomMap): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.DELETE, url: `/actions/${id}`, params: { botID }, baseURL });
}

export async function requestGalleryImages({
    queryKey,
}: QueryFunctionContext<[string, ReqGalleryImages]>): Promise<TResponse<GalleryImage[]>> {
    const [, query] = queryKey;
    return apiProxy<GalleryImage[]>({ method: APIMethods.GET, url: '/gallery-images', params: query, baseURL });
}

export async function requestUpdateGalleryImage(payload: ReqUpdateImage): Promise<TResponse<IMessage>> {
    const { id, ...data } = payload;
    return apiProxy<IMessage>({ method: APIMethods.PUT, url: `/gallery-images/${id}`, data, baseURL });
}

export async function requestDeleteGalleryImage(payload: ReqDeleteImage): Promise<TResponse<IMessage>> {
    const { id, botID } = payload;
    return apiProxy<IMessage>({ method: APIMethods.DELETE, url: `/gallery-images/${id}`, params: { botID }, baseURL });
}

export async function requestUploadGalleryImage(payload: ReqUploadImage): Promise<TResponse<IMessage>> {
    const { botID, formData } = payload;
    return apiProxy<IMessage>({
        method: APIMethods.POST,
        url: `/gallery-images`,
        params: { botID },
        data: formData,
        baseURL,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        showToastSuccessMsg: false,
    });
}

export async function requestGalleryBulkActions(payload: ReqGalleryBulkActions): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({
        method: APIMethods.POST,
        url: `/gallery-images/bulk-action`,
        data: payload,
        baseURL,
    });
}

export async function requestPropertyNotificationSettings({
    queryKey,
}: QueryFunctionContext<[string, ReqPropertyNotificationSettings]>): Promise<TResponse<PropertyNotificationSettings>> {
    const [, query] = queryKey;
    return apiProxy<PropertyNotificationSettings>({ method: APIMethods.GET, url: '/notification-settings', params: query, baseURL });
}

export async function requestSavePropertyNotificationSettings(payload: ReqSavePropertyNotificationSettings): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({
        method: APIMethods.POST,
        url: `/notification-settings`,
        data: payload,
        params: { botID: payload.botID },
        baseURL,
    });
}
