export default {
    title: 'Door Control Units',
    devices: 'Devices',
    firmware: 'Firmware',
    hardware: 'Hardware',
    createFirmware: 'Create Firmware',
    addFirmware: 'Add Firmware',
    addDCUs: 'Add DCUs',
    addHardware: 'Add Hardware',
    deviceId: 'Device ID',
    workspace: 'Account',
    hardwareVersion: 'Hardware Version',
    firmwareVersion: 'Firmware Version',
    networkStatus: 'Network Status',
    initializing: 'Initializing',
    updating: 'Updating',
    hardwareVersions: 'Hardware Versions',
    assignWorkspace: 'Assign Account',
    assignWorkspaceToSelectedDevices: 'Assign Account To Selected Devices',
    clearWorkspaces: 'Clear account assignment',
    pinFirmware: 'Pin firmware',
    unPinFirmware: 'Unpin firmware',
    unPin: 'Unpin',
    pin: 'Pin',
    clearWorkspacesConfirm: 'Clear account for selected devices (this will remove all existing access point mappings)?',
    clearWorkspacesSuccess: 'Accounts cleared successfully',
    unpinSuccess: 'Devices unpinned successfully',
    pinFirmwareTitle: 'Pin firmware for selected devices',
    selectFirmware: 'Select Firmware',
    workspaceAssigned: 'Account is assigned',
    isUpdating: 'is updating',
    createDCUDevices: 'Create DCU devices',
    uploadDCUDevices: 'Upload DCU devices',
    plsUploadCsv: 'Please upload a CSV file with the following columns',
    devicesFailed: 'Devices failed',
    devicesCreated: 'Devices created successfully',
    assignAndSearchWorkspace: 'Search & Assign an Account',
    deviceDetails: 'Device Details',
    firmwareVersions: 'Firmware Versions',
    pinnedVersion: 'Pinned version',
    size: 'Size',
    cloudUrl: 'Cloud URL',
    pinned: 'pinned',
    unknown: 'unknown',
    engaged: 'Engaged',
    exitAssignMode: 'Exit Assign Mode',
    exitMode: 'Exit Mode',
    assignWorkspaceToSelected: 'Assign Account to selected devices',
    selectWorkspace: 'Select an Account',
    liveFirmwareVersion: 'Live Firmware Version',
    defaultFirmwareVersion: 'Default Firmware Version',
    pinnedFirmwareVersion: 'Pinned Firmware Version',
    disEngaged: 'Disengaged',
    engagedStatus: 'Engaged Status',
    devicesPinnedSuccessfully: 'Device(s) pinned successfully',
    pinFirmwareConfirm: 'Are you sure you want to pin firmware?',
    unPinFirmwareConfirm: 'Are you sure you want to unpin firmware?',
    exportDcus: 'Export DCUs',
    deviceUrl: 'Device URL',
    iotLogs: 'IoT Logs',
    iotDevice: 'IoT Device',
    general: 'General',
    iotId: 'IoTCore Num. ID',
    internalId: 'Internal DB ID',
    deviceDataUpdates: 'Device data to be updated every 10s',
    activity: 'Activity',
    lastConfigAckTime: 'Last config ack time',
    lastEventTime: 'Last event time',
    lastHearBeatTime: 'Last heartbeat time',
    lastConfigSendTime: 'Last config send time',
    lastStateTime: 'Last State Time',
    logLevel: 'Log level',
    state: 'State',
    privateIp: 'Private IP',
    macAddress: 'Mac address',
    forceFirmwareUpdate: 'Force Firmware update',
    defaultVersion: 'Default version',
    liveVersion: 'Live Version',
    errorInfo: 'Error Info',
    lastErrorTime: 'Last error time',
    lastErrorCode: 'Last error code',
    lastErrorMsg: 'Last error message',
    telemetry: 'Telemetry',
    statusCode: 'Status Code',
    eventDetail: 'Event Detail',
    data: 'Data',
    eventId: 'Event ID',
    timestamp: 'Timestamp',
    receivedTimestamp: 'Received Timestamp',
    fwRev: 'FW Rev',
    hwRev: 'HW Rev',
    mapAccessPoints: 'Map Access Points',
    engagedStatusIsUpdated: 'Engaged status is updated',
    changeEngageConfirm: 'Are you sure you want to change engage status?',
    assignWorkspaceConfirm: 'Are you sure you want to change/assign account?',
    clearWorkspaceConfirm: 'Are you sure you want to clear account?',
    forceUpdateConfirm: 'Are you sure you want to force update?',
    deleteConfirm: 'Are you sure you want to delete?',
    heap: 'Heap',
    minHeap: 'Min heap',
    mqttCommands: 'MQTT commands',
    runtime: 'Runtime',
    updatedAt: 'Updated at',
    configUpdates: 'Config updates',
    assignAccessPoint: 'Assign Access Point',
    selectAccessPoint: 'Select access point',
    selectDoorController: 'Select Door Controller',
    addDoorController: 'Add Door Controller',
    gettingMappings: 'Getting mapping',
    accessPoint: 'Access Point',
    associatedRelays: 'Associated relays',
    site: 'Site',
    firmwareTxts: {
        firmwareItems: 'Firmware Items',
        firmwareIsArchived: 'Firmware is Archived',
        uploadFirmware: 'Upload firmware',
        hardwareVersion: 'Hardware Version',
        firmwareVersion: 'Firmware Version',
        fileNamePattern: 'The file name should follow the pattern',
        where: 'where',
        example: 'Example',
        firmwareDetails: 'Firmware Details',
        localId: 'Local ID',
        firmwareIsAdded: 'Firmware is added',
        confirmArchive: 'Are you sure archive this firmware?',
        author: 'Author',
        description: 'Description',
        releaseNotes: 'Release notes URL',
        pinnedDevices: 'Pinned Devices',
        hardwareDefaultDevices: 'Default hardware Devices',
    },
    hardwareTxts: {
        displayName: 'Display name',
        relaysNumber: 'Relays number',
        weigandPorts: 'Weigand ports',
        defaultFirmwareVersion: 'Default Firmware Version',
        hardwareDetails: 'Hardware Details',
        numberRelatedDevices: 'Number of registered devices',
        numberOfFirmwareVersions: 'Number of firmware versions',
        confirmDelete: 'Are you sure to delete the hardware?',
        createHardware: 'Create Hardware',
        enterCorrectVersion: 'Please enter correct version - x.x.x',
        hardwareIsCreated: 'Hardware Is Created',
        setFirmware: 'Set firmware',
        selectFirmware: 'Select Firmware',
        changeDefaultFirmwareVer: 'Change default firmware version',
        firmwareChangeDesc: 'Setting this value will trigger firmware updates for all relevant devices',
        noFirmwareFound: "Can't find any firmware with hardware version",
        defaultFirmwareVersionUpdated: 'Default Firmware version is updated',
    },
    lastConfigs: 'Last Configs',
    showingLatestToOldest: 'Showing Latest to Oldest',
    elevator: 'Elevator',
    floor: 'Floor',
    assignFloorButton: 'Assign Floor Button',
    assignElevatorCallButton: 'Assign Elevator Call Button',
    mapFloorButtons: 'Map Floor Buttons',
    mapElevatorCallButtons: 'Map Elevator Call Buttons',
    floorButtonToDCUMapping: 'Floor Button to Door Controllers Mapping',
    elevatorCallButtonToDCUMapping: 'Elevator Call Button to Door Controllers Mapping',
    relayConfig: 'Relay Config',
    dcuDevices: 'DCU Devices',
    akuvoxDevices: 'Akuvox Devices',
    rexToRelayMapping: 'Rex input to Relay mapping',
    rexInput: 'Rex Input',
    relaysMapped: 'Relays Mapped',
    swiftReaderX: 'Swiftreader X',
    rexMappingHelpTxt:
        'Map any third party input to trigger any relay/s. For example, postal button to exit, motion sensor, Fire Alarms, etc.',
    engageSelected: 'Engage Selected devices',
    disengageSelected: 'Disengage Selected devices',
    links: 'Links',
    disableLocalMqtt: 'Disable ACU communication',
    enableLocalMqtt: 'Enable ACU communication',
    localMqttStatus: 'Local MQTT Status',
    time: 'Time',
    config: 'Config',
    activeConfig: 'Active Config',
    editConfig: 'Edit Config',
    rebootSelected: 'Reboot Selected',
    rebootSelectedConfirm: 'Are you sure you want to reboot selected devices?',
    rebootSuccess: 'Reboot Action Performed Successfully',
    reboot: 'Reboot',
    builtInRelays: '(Built in Relays)',
    mapDoorControllerRelays: 'Map Door Controller Relays',
    doorUnlockSchedule: 'Door Unlock Schedule',
};
