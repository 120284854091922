import React, { FC, useMemo, memo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Input } from 'antd';
import { Link } from 'react-router-dom';

import cn from 'classnames';

import routes from 'config/routes';
import useSettings from 'components/NavSideBar/Settings/useSettings';
import { textsCommon } from 'texts/index';
import { Roles, routeId } from 'config/general';
import oktaIcon from 'assets/login/oktaIcon.png';
import gsuite from 'assets/login/googleIcon.png';
import azure from 'assets/login/azureIcon.png';
import { EExternalLoginTypes } from 'pages/Auth/Login/types';
import { ItemType } from 'antd/es/menu/interface';
import styles from './styles.module.scss';

const { Search } = Input;

interface ISettingsProps {
    className?: string;
}

const workspaceListSize = 7;

const Settings: FC<ISettingsProps> = ({ className }: ISettingsProps) => {
    const {
        // isLoading,
        activeWorkspaceInfo,
        workspaceSearchQuery,
        resentAuthList,
        activeWorkspaceId,
        profileInfo,
        handleAvoidSettingsHideOnSearch,
        handleSearchWorkspace,
        handleLogOut,
        handleSelectWorkspace,
        handleLogin,
    } = useSettings();

    const menuItems = useMemo(() => {
        let recentAuths: ItemType[] = [];

        if (resentAuthList && resentAuthList.length > 0) {
            recentAuths = [
                {
                    key: 'recentAuths',
                    type: 'group',
                    label: (
                        <>
                            {textsCommon.navSettings.workspaces}
                            {resentAuthList && resentAuthList.length >= workspaceListSize && (
                                <div
                                    className={styles.search}
                                    role='button'
                                    aria-hidden='true'
                                    tabIndex={0}
                                    onClick={handleAvoidSettingsHideOnSearch}
                                >
                                    <Search placeholder={textsCommon.navSettings.searchWorkspace} onChange={handleSearchWorkspace} />
                                </div>
                            )}
                            <div className={cn(styles.lineLoader, { [styles.animate]: false })} />
                        </>
                    ),
                    className: styles.menuTitle,
                    children: resentAuthList
                        .filter(({ workspaceName, shortName }) =>
                            `${workspaceName} ${shortName}`.toLowerCase().includes((workspaceSearchQuery || '').toLowerCase()),
                        )
                        .map(({ id_str, workspaceName, shortName, access_token, identityProvider, commonUserId }) => ({
                            key: id_str,
                            className: cn(styles.subNavItem, { [styles.subNavItemActive]: id_str === activeWorkspaceId }),
                            label: (
                                <div
                                    className={styles.subNavChild}
                                    onClick={access_token ? handleSelectWorkspace(id_str) : handleLogin(workspaceName)}
                                    role='button'
                                    aria-hidden='true'
                                    tabIndex={0}
                                >
                                    <div>
                                        <p>
                                            {shortName}
                                            {commonUserId && (
                                                <span>
                                                    {commonUserId}
                                                    {!access_token && <> - ({textsCommon.loggedOut})</>}
                                                </span>
                                            )}
                                        </p>
                                        {EExternalLoginTypes.okta === identityProvider && <img height={16} src={oktaIcon} alt='Login' />}
                                        {EExternalLoginTypes.gsuite === identityProvider && <img height={16} src={gsuite} alt='Login' />}
                                        {EExternalLoginTypes.azure === identityProvider && <img height={16} src={azure} alt='Login' />}
                                    </div>
                                    {/* {id_str !== activeWorkspaceId && ( */}
                                    {/*    <button */}
                                    {/*        type='button' */}
                                    {/*        onClick={evt => { */}
                                    {/*            evt.stopPropagation(); */}
                                    {/*            handleRemoveAuth(id_str); */}
                                    {/*        }} */}
                                    {/*    > */}
                                    {/*        <DeleteOutlined /> */}
                                    {/*    </button> */}
                                    {/* )} */}
                                </div>
                            ),
                        })),
                },
            ];
        }

        const settingsItem =
            profileInfo?.role === Roles.admin
                ? [
                      {
                          key: 'settings',
                          label: (
                              <Link to={routes.users.profile.replace(routeId, profileInfo.id_str)}>
                                  {textsCommon.navSettings.profileSettings}
                              </Link>
                          ),
                      },
                  ]
                : [];
        return [
            ...recentAuths,
            {
                key: 'addWorkspace',
                label: <Link to={routes.auth.enterWorkspace}>{textsCommon.navSettings.addWorkspace}</Link>,
            },
            ...settingsItem,
            {
                key: 'logOut',
                label: (
                    <div onClick={handleLogOut} role='button' aria-hidden='true' tabIndex={0}>
                        <p>{textsCommon.navSettings.logout}</p>
                    </div>
                ),
                className: styles.logOut,
            },
            {
                key: 'email',
                label: (
                    <div>
                        <p>{textsCommon.navSettings.loggedAs}:</p>
                        <p>{profileInfo?.email}</p>
                    </div>
                ),
                className: styles.menuItemEmail,
            },
        ];
    }, [
        activeWorkspaceId,
        handleAvoidSettingsHideOnSearch,
        handleLogOut,
        handleLogin,
        handleSearchWorkspace,
        handleSelectWorkspace,
        profileInfo,
        resentAuthList,
        workspaceSearchQuery,
    ]);

    return (
        <Dropdown
            className={cn({ [className || '']: className })}
            overlayClassName={styles.overlay}
            menu={{ items: menuItems, className: styles.menu }}
            trigger={['click']}
            // onOpenChange={handleSettingsOpenChange}
        >
            <div className={styles.settingsBtn}>
                {activeWorkspaceInfo && (
                    <>
                        <div className={styles.settingsBtnTxt}>
                            {activeWorkspaceInfo.name}
                            {activeWorkspaceInfo.workspace_name && <p>{activeWorkspaceInfo.workspace_name}</p>}
                        </div>
                        <DownOutlined />
                    </>
                )}
            </div>
        </Dropdown>
    );
};

export default memo(Settings);
