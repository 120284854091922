import { QueryFunctionContext } from 'react-query/types/core/types';
import { APIMethods, apiProxy, TResponse } from 'utils/api/apiRequestProxy';
import { IMessage } from 'components/PushNotifications/types';
import {
    AccountInvoice,
    AccountInvoiceTemplate,
    BillingState,
    InvoiceContactInfo,
    QBCustomer,
    QBTemplate,
    ReqInvoices,
    ReqAccountInvoiceTemplates,
    ReqAddInvoiceContactInfo,
    ReqBillingState,
    ReqUpdateInvoice,
    ReqUpdateRecurringInvoice,
    ReqInvoicesContactInfo,
    ReqQBCustomers,
    ReqQBTemplate,
    ReqSyncInvoices,
    ReqTrialAccess,
    ReqMapQBTemplate,
    ReqCreateRecTemplate,
} from './types';

export async function requestBillingState({ queryKey }: QueryFunctionContext<[string, ReqBillingState]>): Promise<TResponse<BillingState>> {
    const [, query] = queryKey;
    return apiProxy<BillingState>({
        url: '/account-billing-info',
        method: APIMethods.GET,
        params: query,
    });
}

export async function requestActivateAccount(): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/activate-account' });
}

export async function requestTrialAccess(payload: ReqTrialAccess): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/request-test-access', data: payload });
}

export async function requestInvoices({ queryKey }: QueryFunctionContext<[string, ReqInvoices]>): Promise<TResponse<AccountInvoice[]>> {
    const [, query] = queryKey;
    return apiProxy<AccountInvoice[]>({
        url: '/invoices',
        method: APIMethods.GET,
        params: query,
        respKey: 'invoices',
    });
}

export async function requestAccountInvoiceTemplates({
    queryKey,
}: QueryFunctionContext<[string, ReqAccountInvoiceTemplates]>): Promise<TResponse<AccountInvoiceTemplate[]>> {
    const [, query] = queryKey;
    return apiProxy<AccountInvoiceTemplate[]>({
        url: '/invoice-template',
        method: APIMethods.GET,
        params: query,
        respKey: 'templates',
    });
}

export async function requestInvoicesContactInfo({
    queryKey,
}: QueryFunctionContext<[string, ReqInvoicesContactInfo]>): Promise<TResponse<InvoiceContactInfo>> {
    const [, query] = queryKey;
    return apiProxy<InvoiceContactInfo>({
        url: '/invoicing-contact',
        method: APIMethods.GET,
        params: query,
        respKey: 'invoicing_contact',
    });
}

export async function requestCreateInvoice(payload: ReqUpdateInvoice): Promise<TResponse<IMessage>> {
    const { company_id, ...rest } = payload;
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/invoices', params: { company_id }, data: rest });
}

export async function requestCreateRecurringInvoice(payload: ReqUpdateRecurringInvoice): Promise<TResponse<IMessage>> {
    const { company_id, ...rest } = payload;
    return apiProxy<IMessage>({
        method: APIMethods.POST,
        url: '/invoice-template',
        params: { company_id },
        data: rest,
    });
}

export async function requestAddInvoiceContactInfo(payload: ReqAddInvoiceContactInfo): Promise<TResponse<IMessage>> {
    const { company_id, ...rest } = payload;
    return apiProxy<IMessage>({
        method: APIMethods.POST,
        url: '/invoicing-contact',
        params: { company_id },
        data: rest,
    });
}

export async function requestQBCustomers({ queryKey }: QueryFunctionContext<[string, ReqQBCustomers]>): Promise<TResponse<QBCustomer[]>> {
    const [, query] = queryKey;
    return apiProxy<QBCustomer[]>({
        url: '/qb-customers',
        method: APIMethods.GET,
        params: query,
    });
}

export async function requestQBTemplate(query: ReqQBTemplate): Promise<TResponse<QBTemplate>> {
    return apiProxy<QBTemplate>({
        url: '/qb-recurring-transactions',
        method: APIMethods.GET,
        params: query,
    });
}

export async function requestMapQBTemplate(payload: ReqMapQBTemplate): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/map-qb-recurring-transaction', data: payload });
}

export async function requestSyncInvoices(payload: ReqSyncInvoices): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.POST, url: '/invoice-sync', data: payload });
}

export async function requestCreateRecTemplate(payload: ReqCreateRecTemplate): Promise<TResponse<IMessage>> {
    const { company_id, ...data } = payload;
    return apiProxy<IMessage>({
        method: APIMethods.POST,
        url: '/create-qb-recurring-template',
        data,
        params: { company_id },
    });
}

export async function requestDeleteTemplate(id: string): Promise<TResponse<IMessage>> {
    return apiProxy<IMessage>({ method: APIMethods.DELETE, url: `/invoice-template-single/${id}` });
}
