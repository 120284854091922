import { FC } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { ICustomMap } from 'types/ICustomMap';

interface IProps {
    toggleName?: string;
    flags?: ICustomMap;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
}

const FeatureFlag: FC<IProps> = ({ toggleName, flags, children }: IProps) => {
    if (!toggleName) return children;
    return flags?.[toggleName] ? children : null;
};

export default withLDConsumer()(FeatureFlag);
