import { APIMethods, apiProxy, Message, TResponse } from 'utils/api/apiRequestProxy';
import { QueryFunctionContext } from 'react-query/types/core/types';
import { IProfileUser, IUser } from 'types/IUser';
import { ICSVUploadedData } from './ImportUsers/types';
import { IGetUsersQuery, ReqAddUser, ReqUploadUsers } from './types';

export async function requestBulkUploadUsers(data: ReqUploadUsers): Promise<TResponse<ICSVUploadedData>> {
    return apiProxy<ICSVUploadedData>({
        url: '/users/bulk_upload/',
        method: APIMethods.POST,
        data,
    });
}

export async function fetchUser({
    queryKey,
}: QueryFunctionContext<[string, { userId: string; companyId?: string }]>): Promise<TResponse<IUser>> {
    const [, { userId, companyId }] = queryKey;
    return apiProxy<IUser>({
        url: `/user/${userId}`,
        method: APIMethods.GET,
        params: { company_id: companyId },
    });
}

export async function fetchUsers({ queryKey }: QueryFunctionContext<[string, IGetUsersQuery]>): Promise<TResponse<IUser[]>> {
    const [, query] = queryKey;
    return apiProxy<IUser[]>({
        url: `/users/`,
        method: APIMethods.GET,
        params: query,
        respKey: 'users',
    });
}

export async function requestAddUsers(data: ReqAddUser): Promise<TResponse<Message>> {
    return apiProxy<Message>({
        url: '/users/',
        method: APIMethods.POST,
        showToastSuccessMsg: false,
        data,
    });
}

export async function requestGerUserProfile(): Promise<TResponse<IProfileUser>> {
    return apiProxy<IProfileUser>({
        url: '/profile/',
        method: APIMethods.GET,
        respKey: 'user',
    });
}

export async function requestUploadProfilePhoto({ userId, file }: { userId?: string; file: File }): Promise<TResponse<Message>> {
    const data = new FormData();
    data.append('file', file);

    return apiProxy<Message>({
        url: `/profile_photo/`,
        method: APIMethods.POST,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        params: { user_id: userId },
        data,
    });
}

export async function requestDeleteProfilePhoto({ userId }: { userId?: string }): Promise<TResponse<Message>> {
    return apiProxy<Message>({
        url: `/profile_photo/`,
        method: APIMethods.DELETE,
        params: { user_id: userId },
    });
}
