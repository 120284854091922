import { RingBell } from 'types/RingBell';
import doorBellAudio from 'assets/audio/doorbell.mp3';
import snapshotAudio from 'assets/audio/snapshot.mp3';
import swiftlaneAudio from 'assets/audio/swiftlane.mp3';
import telephoneAudio from 'assets/audio/telephone.wav';
import { userSettingsStorageKey } from 'config/general';

const defaultRingtone = RingBell.swiftlane;

export const getRingtoneUrl = (name?: RingBell): string => {
    if (name === RingBell.doorBell) return doorBellAudio;
    if (name === RingBell.snapshot) return snapshotAudio;
    if (name === RingBell.swiftlane) return swiftlaneAudio;
    if (name === RingBell.telephone) return telephoneAudio;

    return swiftlaneAudio;
};

export const getUserRingtoneName = (userId: string): RingBell => {
    const userSettings = JSON.parse(localStorage.getItem(userSettingsStorageKey) || '{}');
    return (userSettings[userId]?.ringtone as RingBell) || defaultRingtone;
};

export const saveUserRingtone = (userId: string, ringtone: RingBell): void => {
    const userSettings = JSON.parse(localStorage.getItem(userSettingsStorageKey) || '{}');
    userSettings[userId] = { ringtone };
    localStorage.setItem(userSettingsStorageKey, JSON.stringify(userSettings));
};
