// TODO Should be implemented on env side;

export const isProd = (): boolean => {
    const host = window?.location?.hostname;
    return host?.includes('.swiftlane.com');
};

export const isStage = (): boolean => {
    const host = window?.location?.hostname;
    return host?.includes('.swiftpass.io');
};
