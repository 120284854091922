import React, { FC, useMemo, useState, useCallback, useEffect } from 'react';
import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Layout } from 'antd';

import CustomScroll from 'react-custom-scroll';

import { Link, useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { LG_WIDTH } from 'config/general';
import logo from 'assets/svg/logo.svg';
import routes from 'config/routes';

import cn from 'classnames';

import MenuIcon from 'components/common/Icons/MenuIcon';
import Container from 'components/common/Container';
import { hideMenuBar } from 'utils/hideMenuBar';
import { textsCommon } from 'texts/index';
import featureFlagsList from 'config/featureFlagsList';
import FeatureFlag from 'components/Launchdarkly/FeatureFlag';
import MainMenu from 'components/NavSideBar/MainMenu';
import HelpButton from 'components/common/Help/HelpButton';
import { useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';
import styles from './styles.module.scss';
import Settings from './Settings';

import ReferButton from '../common/Referral';

const { Sider } = Layout;

const NavSideBar: FC = () => {
    const { width } = useWindowSize();
    const [collapsed, setCollapsed] = useState((width || 0) <= LG_WIDTH);
    const location = useLocation();
    const { isAccountRestricted } = useSelector(({ billing }: ICustomMap) => billing);
    useEffect(() => {
        if (width && width <= LG_WIDTH) {
            setCollapsed(true);
        }
    }, [width]);

    const collapsedWidth = useMemo(() => {
        if (width && width <= LG_WIDTH) return 0;
        return undefined;
    }, [width]);

    const hideNavBar = useMemo(() => hideMenuBar(location.pathname), [location.pathname]);

    const toggleSideBar = useCallback(() => setCollapsed(!collapsed), [collapsed]);

    const closeSideBarOnMenuItemPress = useCallback(() => {
        if (width && width <= LG_WIDTH) {
            setCollapsed(true);
        }
    }, [width]);

    if (hideNavBar) return null;

    return (
        <>
            <header className={styles.mobileHeader}>
                <Container>
                    <div className={styles.mobileWrap}>
                        <Link to={routes.home}>
                            <img src={logo} alt={textsCommon.swiftlane} />
                        </Link>
                        <div className={styles.rightSide}>
                            <HelpButton className={styles.helpBtn} />
                            <button type='button' onClick={toggleSideBar}>
                                <MenuIcon />
                            </button>
                        </div>
                    </div>
                </Container>
            </header>
            <Sider
                width={240}
                className={styles.sider}
                breakpoint='lg'
                collapsedWidth={collapsedWidth}
                trigger={null}
                collapsible
                collapsed={collapsed}
            >
                <div className={styles.siderContent}>
                    <div className={cn(styles.head, { [styles.collapsed]: collapsed })}>
                        <div className={styles.top}>
                            <Link to={routes.home}>
                                <img src={logo} alt={textsCommon.swiftlane} />
                            </Link>
                            <button className={styles.collapseBtn} type='button' onClick={toggleSideBar}>
                                <DoubleLeftOutlined />
                            </button>
                        </div>
                        <div>
                            <Settings className={styles.settings} />
                            <button className={styles.unCollapseBtn} type='button' onClick={toggleSideBar}>
                                <DoubleRightOutlined />
                            </button>
                        </div>
                    </div>
                    <CustomScroll>
                        <div className={styles.menu}>
                            <MainMenu onItemPress={closeSideBarOnMenuItemPress} />
                            {!isAccountRestricted && (
                                <FeatureFlag toggleName={featureFlagsList.enableReferEarnButton}>
                                    <div className={collapsed ? styles.giftContainer : styles.referContainer}>
                                        <ReferButton iconOnly={collapsed} type={collapsed ? 'link' : 'primary'} />
                                    </div>
                                </FeatureFlag>
                            )}
                        </div>
                    </CustomScroll>
                </div>
            </Sider>
        </>
    );
};

export default NavSideBar;
