import { AxiosError } from 'axios';
import { textsCommon } from 'texts/index';
import { ICustomMap } from 'types/ICustomMap';

export const getErrorMsg = (error: AxiosError | ICustomMap): string => {
    if (error.response) {
        return (
            error.response?.data?.msg ||
            error?.response?.data?.message ||
            error?.response?.data?.detail?.msg ||
            error?.response?.data?.detail?.[0]?.msg ||
            error?.response?.data?.data?.msg ||
            error?.response?.data?.data?.message ||
            error?.response?.data?.error_description ||
            textsCommon.errorHappened
        );
    }
    return textsCommon.errorHappened;
};

export const getSuccessMsg = (respData: ICustomMap | string): string =>
    typeof respData === 'string' ? respData : respData?.msg || respData?.message || respData?.data?.msg || respData?.data?.message;
