import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { ICustomMap } from 'types/ICustomMap';
import { dashboardAnalytics, analyticsDashboardUserId } from 'hooks/useAnalytics/config';

const offAnalytics = process.env.REACT_APP_OFF_ANALYTICS === 'true';

const useAnalytics = () => {
    const { isLoggedIn, user } = useSelector(({ authentication }: ICustomMap) => authentication || {});
    const { id_str: accountId } = useSelector(({ workspace }: ICustomMap) => workspace);

    const { id_str: userId } = user || {};

    useEffect(() => {
        if (isLoggedIn && accountId && userId && !offAnalytics) {
            dashboardAnalytics?.ready(() => {
                const browserId = localStorage.getItem('dashboard_analytics_browser_id') || uuidv4();
                localStorage.setItem('dashboard_analytics_browser_id', browserId);
                const traits = {
                    accountId,
                    browserId,
                    dashboardUserId: userId,
                };
                dashboardAnalytics.setAnonymousId(analyticsDashboardUserId);
                dashboardAnalytics.identify(
                    analyticsDashboardUserId,
                    {
                        accountId,
                        browserId,
                        dashboardUserId: userId,
                    },
                    {
                        traits,
                    },
                );

                dashboardAnalytics.addSourceMiddleware(({ payload, next }) => {
                    const { type, context } = payload.obj || {};
                    if (type === 'track' || type === 'page' || type === 'identify') {
                        // @ts-ignore
                        context.traits = {
                            ...(context?.traits || {}),
                            ...traits,
                        };
                        // @ts-ignore
                        // eslint-disable-next-line no-param-reassign
                        payload.obj.dashboardBrowserId = browserId;
                    }
                    next(payload);
                });
            });
        }
    }, [accountId, isLoggedIn, userId]);
};

export default useAnalytics;
