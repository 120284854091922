import { IMetaData } from 'types/IRespMetaData';
import { ICustomMap } from 'types/ICustomMap';
import { AxiosRequestConfig } from 'axios';
import { getErrorMsg, getSuccessMsg } from 'utils/getErrorMsg';
import { toast } from 'react-toastify';
import { TError } from 'types/errors';
import { get, isArray } from 'lodash';
import api from './index';

export type Message = {
    message?: string;
    msg?: string;
};

export type TResponse<Data> = {
    respData?: Data;
    metaData?: IMetaData;
    errors?: ICustomMap | string[] | null;
    message?: string;
};

interface IRequestConfig extends AxiosRequestConfig {
    showToastErrMsg?: boolean;
    showToastSuccessMsg?: boolean;
    respKey?: string; // use this key to get the response data from the api response
    isExternalCall?: boolean; // use this flag to make external calls without the setting our auth headers
}

export enum APIMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export async function apiProxy<T>(config: IRequestConfig): Promise<TResponse<T>> {
    const { showToastErrMsg = true, showToastSuccessMsg = true, respKey, ...axiosConfig } = config;
    try {
        let respData;
        let response = await api(axiosConfig);

        if (isArray(response) || typeof response === 'undefined') {
            // @ts-ignore
            response = { data: response || [] };
        }
        const { data, metadata, errors, ...rest }: ICustomMap = response;
        respData = data || rest || response;

        if (respKey) {
            respData = get(respData, respKey);
        }

        const message = getSuccessMsg(respData);
        const showSuccessToast = showToastSuccessMsg && message && axiosConfig.method !== APIMethods.GET;

        if (showSuccessToast) toast.success(message);
        return { respData, metaData: metadata, errors, message };
    } catch (err) {
        const error = err as TError;
        const errMsg = getErrorMsg(error);
        const showErrorToast = showToastErrMsg && errMsg && axiosConfig.method !== APIMethods.GET;
        if (showErrorToast) toast.error(errMsg);
        throw new Error(errMsg);
    }
}
