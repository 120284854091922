import React, { FC } from 'react';

const IntegrationIcon: FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M9.7999 4.20015C9.67775 4.32477 9.60933 4.49231 9.60933 4.66682C9.60933 4.84132 9.67775 5.00886 9.7999 5.13348L10.8666 6.20015C10.9912 6.3223 11.1587 6.39072 11.3332 6.39072C11.5077 6.39072 11.6753 6.3223 11.7999 6.20015L14.3132 3.68682C14.6485 4.42761 14.75 5.25297 14.6042 6.05291C14.4585 6.85285 14.0724 7.58938 13.4974 8.16434C12.9225 8.73929 12.1859 9.12537 11.386 9.27112C10.5861 9.41687 9.7607 9.31537 9.0199 8.98015L4.41324 13.5868C4.14802 13.852 3.78831 14.001 3.41324 14.001C3.03816 14.001 2.67845 13.852 2.41324 13.5868C2.14802 13.3216 1.99902 12.9619 1.99902 12.5868C1.99902 12.2117 2.14802 11.852 2.41324 11.5868L7.0199 6.98015C6.68468 6.23936 6.58318 5.41399 6.72893 4.61405C6.87468 3.81411 7.26076 3.07759 7.83572 2.50263C8.41067 1.92767 9.1472 1.5416 9.94714 1.39584C10.7471 1.25009 11.5724 1.35159 12.3132 1.68682L9.80657 4.19348L9.7999 4.20015Z'
            strokeWidth='1.33333'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default IntegrationIcon;
