import { IOnnOff } from 'config/accessPoint';
import { IUserGroup } from 'types/IUserGroup';
import { SelectValue } from 'antd/es/select';
import { IPaging } from 'types/IPaging';
import { EElevatorMapAction, ELiveViewAction } from './config';

export interface IAccessPointSaveSettings {
    enable_face_recognition: IOnnOff;
    enable_tenant_intercom: IOnnOff;
    enable_visitor_enter_pin: IOnnOff;
    enable_visitor_front_desk_call: IOnnOff;
    face_only_unlock: IOnnOff;
    low_light: IOnnOff;
    remote_unlock: IOnnOff;
    settings_icon_on_face_scan_screen: IOnnOff;
    touchless_unlock: IOnnOff;
    device_volume: number;
    front_desk_text: string;
    tenant_intercom_text: string;
    visitor_button_label_text: string;
    enable_unlock_tone: IOnnOff;
    enable_third_party_weigand_output: IOnnOff;
    enable_key_card_reader: IOnnOff;
    enable_screen_saver: IOnnOff;
    enable_acu: IOnnOff;
    enable_package_scan: IOnnOff;
    enable_third_party_weigand_input: IOnnOff;
}

export interface IAccessPointSettings extends IAccessPointSaveSettings {
    access_point_id_str: string;
    company_id_str: string;
    id_str: string;
    enable_user_schedules: IOnnOff | null;
    enable_visitor_user_from_directory_call: IOnnOff | null;
}

export interface IAccessPointUserGroup extends IUserGroup {
    schedule: {
        id_str: string;
        schedule_name: string;
    };
}

export enum ECallProvider {
    TWILIO = 'TWILIO',
    VONAGE = 'VONAGE',
}

export interface IRoomData {
    url: string;
    room_sid: string;
    room_name: string;
    access_token: string;
    join_token: string;
    vonage_app_id: string;
    call_provider: ECallProvider;
}

export type ReqCreateAccessPoint = {
    color: string;
    description: string;
    group_name?: string;
    short_name: string;
    site_id: string;
    nearby_access_groups?: string[];
    remote_unlock_access_groups?: string[];
    live_view_access_groups?: string[];
};

export interface IGetAccessPointsQuery extends IPaging {
    access_point_name?: string;
    access_point_ids_query?: string[];
    access_point_id?: string[];
    site_id?: string | string[];
    company_id?: string;
}

export type TUpdateLiveViewGroups = (action: ELiveViewAction) => (groupId: SelectValue) => void;

export type ReqMapElevatorsToAccessPoint = {
    elevator_ids: string[];
    action: EElevatorMapAction;
    accessPointId: string;
};
