import React, { FC } from 'react';

const PinCodeIcon: FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='1.84615' cy='1.84615' r='1.84615' fill='#061C3F' />
        <circle cx='7.9985' cy='1.84615' r='1.84615' fill='#061C3F' />
        <circle cx='14.1547' cy='1.84615' r='1.84615' fill='#061C3F' />
        <circle cx='1.84615' cy='8.00045' r='1.84615' fill='#061C3F' />
        <circle cx='7.9985' cy='8.00045' r='1.84615' fill='#061C3F' />
        <circle cx='14.1547' cy='8.00045' r='1.84615' fill='#061C3F' />
        <circle cx='7.9985' cy='14.1538' r='1.84615' fill='#061C3F' />
    </svg>
);

export default PinCodeIcon;
