export default {
    signInToWorkspace: 'Sign in to your account',
    findEmailTxt: 'You can find account name in your Swiftlane invitation email',
    workspaceName: 'Account Name',
    recentWorkspaces: 'Recently Used Accounts',
    changeWorkspace: 'Change Account',
    signInTo: 'Sign in to',
    workspace: 'account',
    enterWorkspaceName: 'Enter account name',
    enterEmail: 'Enter Email',
    enterPassword: 'Enter Password',
    forgotPassword: 'Forgot password',
    logIn: 'Log In',
    enterYourWorkspace: 'Enter your Account Name',
    enterYourEmail: 'Enter your email address',
    confirm: 'Confirm',
    go: 'Go',
    goBackToLogin: 'Go Back to Login',
    enterNewPassword: 'Enter new Password',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    passwordsNotMatch: 'Entered Passwords do not match!',
    oneUpperCaseLetter: 'At least one upper case letter',
    oneLowerCaseLetter: 'At least one lower case letter',
    oneDigitCharacters: 'At least one digit characters',
    oneSpecialCharacter: 'At least one special character',
    minimumEightLength: 'Minimum eight in length (8)',
    setPasswordForYourAccount: 'Set password for your account',
    android: 'Android',
    ios: 'IOS',
    emailVerified: 'Email is Verified',
    downloadApp: 'Please download our app',
    or: 'or',
    getAnOtpEmail: 'Get an Verification Code via your email',
    enterCode: 'Enter Code',
    getCode: 'Send Verification Code via email',
    reSendCode: 'Resend Verification Code',
    resendOtp: 'Resend Verification Code in',
    seconds: 'seconds',
    reSendInvite: 'Resend Invite',
    emailVerification: 'Email Verification',
    sendTxtCode: 'Send Login Code via Text',
    getCodeByPhone: 'Get a Login code via Text on your Phone Number',
};
