import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import jwtDecode from 'jwt-decode';
import { ICustomMap } from 'types/ICustomMap';
import { useHistory } from 'react-router-dom';
import { IRecentAuth, ITokens } from 'types/auth';
import { cacheUserRole, cacheUserTokens, removeCachedUserTokens } from 'utils/auth';
import { logOutAction, logOutRecantAuthAction } from 'store/authentication/actions';
import routes from 'config/routes';
import { IWorkspace } from 'pages/CommandCenter/Accounts/types';
import { IAuthenticationState } from 'store/authentication/types';
import { IProfileUser } from 'types/IUser';
import { adminAccess } from 'utils/adminAccess';
import { sortRecentAuth } from 'store/authentication/utils';
import { TNoArgsFuncVoid } from 'types/other';
import { unRegisterFcm } from '../../../firebase';

interface IUseSettings {
    activeWorkspaceInfo?: IWorkspace;
    workspaceSearchQuery?: string;
    resentAuthList: IRecentAuth[];
    activeWorkspaceId?: string;
    profileInfo: IProfileUser | null;
    handleAvoidSettingsHideOnSearch(evt: ICustomMap): void;
    handleSearchWorkspace(evt: ChangeEvent<HTMLInputElement>): void;
    handleLogOut(): void;
    handleSelectWorkspace: (id: string) => () => void;
    handleLogin: (accountName: string) => TNoArgsFuncVoid;
}

const useSettings = (): IUseSettings => {
    const [workspaceSearchQuery, setWorkspaceSearchQuery] = useState<string>();
    const { user: profileInfo } = useSelector((state: ICustomMap): IAuthenticationState => state.authentication);
    const activeWorkspaceInfo = useSelector(({ workspace }: ICustomMap) => workspace);
    const { recentAuths } = useSelector(({ authentication }: ICustomMap) => authentication);
    const history = useHistory();
    const dispatch = useDispatch();

    const resentAuthList: IRecentAuth[] = useMemo(() => (recentAuths ? sortRecentAuth(Object.values(recentAuths)) : []), [recentAuths]);

    const activeWorkspaceId: string = useMemo(() => activeWorkspaceInfo && activeWorkspaceInfo.id_str, [activeWorkspaceInfo]);

    const handleSelectWorkspace = useCallback(
        (workspaceId: string) => () => {
            const auth = recentAuths[workspaceId];
            const tokens: ITokens = {
                access_token: auth.access_token,
                refresh_token: auth.refresh_token,
            };
            // @ts-ignore
            const userRole: string = jwtDecode(auth.access_token).role;
            cacheUserRole(userRole);
            cacheUserTokens(tokens);
            adminAccess.deleteToken();
            window?.location?.assign(routes.home);
        },
        [recentAuths],
    );

    const handleLogOut = useCallback(() => {
        if (activeWorkspaceId) {
            dispatch(logOutRecantAuthAction());
            dispatch(logOutAction());
            // endZendeskUserSession();
            unRegisterFcm();
            adminAccess.deleteToken();
            history.push(routes.auth.enterWorkspace);
        }
    }, [activeWorkspaceId, dispatch, history]);

    const handleSearchWorkspace = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
        setWorkspaceSearchQuery(target.value);
    }, []);

    const handleAvoidSettingsHideOnSearch = useCallback((evt: ICustomMap) => {
        evt.stopPropagation();
    }, []);

    const handleLogin = useCallback(
        (accountName: string) => () => {
            removeCachedUserTokens();
            history.push(`${routes.auth.login}?workspace=${accountName}`);
        },
        [history],
    );

    return {
        activeWorkspaceInfo,
        workspaceSearchQuery,
        resentAuthList,
        activeWorkspaceId,
        profileInfo,
        handleAvoidSettingsHideOnSearch,
        handleSearchWorkspace,
        handleLogOut,
        handleSelectWorkspace,
        handleLogin,
    };
};

export default useSettings;
