import React, { FC, useEffect, useState } from 'react';
import { TNoArgsFuncVoid } from 'types/other';

interface IProps {
    seconds: number;
    onDone: TNoArgsFuncVoid;
}

const CountDown: FC<IProps> = ({ seconds = 0, onDone }: IProps) => {
    const [counter, setCounter] = useState(seconds);

    useEffect(() => {
        if (counter === 0) {
            onDone();
            return;
        }
        setTimeout(() => {
            setCounter(counter - 1);
        }, 1000);
    }, [counter, onDone]);

    return <span>{counter >= 10 ? counter : `0${counter}`}</span>;
};

export default CountDown;
