import { IWorkspaceState, TWorkspaceActions } from 'store/workSpace/types';
import { workspaceActions } from 'store/workSpace/actionTypes';
import { IWorkspace } from 'pages/CommandCenter/Accounts/types';

const initialState: IWorkspaceState = {};

function reducer(state = initialState, action: TWorkspaceActions): IWorkspaceState {
    const { type, payload } = action;
    switch (type) {
        case workspaceActions.SET_WORKSPACE:
            return (payload || {}) as IWorkspace;
        case workspaceActions.SET_WORKSPACE_SETTINGS:
            return {
                ...state,
                cap_settings: payload,
            } as IWorkspace;
        default:
            return state;
    }
}

export default reducer;
