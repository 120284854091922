export default {
    realPage: 'Real Page',
    cardDesc: 'Smarter Property Management',
    cardSubDesc: 'Import residence data from RealPage. You will need property ID, credentials and license key to enroll',
    connections: 'Connections',
    siteId: 'Site ID',
    pmcId: 'PMC ID',
    syncNow: 'Sync Now',
    connectionDetails: 'Connection Details',
    accessGroups: 'Access Groups',
    accessGroupsInfo:
        'Newly created users during sync will automatically be added to the selected access groups, and will be able to unlock doors according to the access configured for these groups.',
    createTenantEntry: 'Create Intercom Directory Entry?',
    createTenantEntryInfo:
        'If you select "Yes", an entry will be created in the Intercom directory for the selected sites during sync for all users.',
    tenantEntryCreationRule: 'Tenant Entry Creation Rule',
    unitNumber: 'Unit Number',
    recommended: 'Recommended',
    unit: 'Unit',
    firstName: 'First Name',
    lastName: 'Last Name',
    firstNameInitial: 'First Name Initial',
    lastNameInitial: 'Last Name Initial',
    unitLastName: 'Unit + Last Name',
    autoSync: 'Auto-Sync',
    autoInvites: 'Send Auto-Invites',
    autoInvitesInfo:
        'When a new user is created during sync, Swiftlane will automatically send an email invitation to the user with their login credentials.',
    tenantDirectorySettings: 'Tenant Directory Settings',
    buildingToSite: 'Building to Swiftlane Site mapping',
    siteToSite: 'Site to Swiftlane Site mapping',
    buildingName: 'Building Name',
    swiftlaneSite: 'Swiftlane Site',
    addBuilding: 'Add Building',
    selectBuilding: 'Select Building',
    mapBuilding: 'Map Building',
    building: 'Building',
    mappedBuildings: 'Current Building to Swiftlane Sites Mapping',
    siteName: 'Site Name',
    hideResidentsNamesInfo: 'If you want to hide names of residents in your building from visitors',
    entryRuleInfo:
        "This rule will create entries in Tenant directory corresponding to each building/site at the PMS in Swiftlane Access pointInte. Please choose display format from this options. Admin won't be able to edit the tenant entry created using auto-sync, though admin can create more entries manually.",
    tenantDirectorySettingsInfo:
        'This mapping is used to list entries in Tenant directory at corresponding access point to each building/site at the PMS',
    deleteSiteToBuildingMappingConfirm:
        'All tenants from this building will be deleted from the corresponding Swiftlane Site Tenant directory. Please confirm if you want to go ahead',
    siteToSiteInfo: 'All tenants will be created in below selected Swiftlane sites',
    tenantRuleChangeConfirm:
        'If you select a different Tenant creation rule, it’ll delete all existing tenants created by earlier rule and create new tenants as per new rule. Do you want to go ahead?',
    autoSyncInfoPart1: 'Swiftlane will only sync the residents with status as',
    current: 'Current',
    in: 'in',
    accessGroupsWarning:
        'Hey, no default user groups have been selected. The users added to Swiftlane via sync won’t have access to any doors. Are you sure? Assign access group now or you can choose to assign groups to users manually later.',
};
