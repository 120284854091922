import { APIMethods, apiProxy, TResponse } from 'utils/api/apiRequestProxy';

export type TUserHash = {
    intercom_hash: string;
    token: string;
};

export async function requestGetUserHash(): Promise<TResponse<TUserHash>> {
    return apiProxy<TUserHash>({
        url: '/zendesk/jwt/',
        method: APIMethods.GET,
        // respKey: 'call_history',
    });
}
