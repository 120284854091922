import React, { FC } from 'react';

const LinkedInIcon: FC = () => (
    <svg height='100%' version='1.1' viewBox='0 0 512 512' width='100%' xmlSpace='preserve' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M512,64c0,-35.323 -28.677,-64 -64,-64l-384,0c-35.323,0 -64,28.677 -64,64l0,384c0,35.323 28.677,64 64,64l384,0c35.323,0 64,-28.677 64,-64l0,-384Z'
            fill='#2867b2'
        />
        <g id='shapes'>
            <rect height='257.962' id='rect11' fill='#fff' width='85.76' x='61.053' y='178.667' />
            <path
                d='M104.512,54.28c-29.341,0 -48.512,19.29 -48.512,44.573c0,24.752 18.588,44.574 47.377,44.574l0.554,0c29.903,0 48.516,-19.822 48.516,-44.574c-0.555,-25.283 -18.611,-44.573 -47.935,-44.573Z'
                id='path13-0'
                fill='#fff'
                fillRule='nonzero'
            />
            <path
                d='M357.278,172.601c-45.49,0 -65.866,25.017 -77.276,42.589l0,-36.523l-85.738,0c1.137,24.197 0,257.961 0,257.961l85.737,0l0,-144.064c0,-7.711 0.554,-15.42 2.827,-20.931c6.188,-15.4 20.305,-31.352 43.993,-31.352c31.012,0 43.436,23.664 43.436,58.327l0,138.02l85.741,0l0,-147.93c0,-79.237 -42.305,-116.097 -98.72,-116.097Z'
                id='path15'
                fill='#fff'
                fillRule='nonzero'
            />
        </g>
    </svg>
);

export default LinkedInIcon;
