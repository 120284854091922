import React, { FC } from 'react';

const ContentEdit: FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M14.125 8H13.25C13.1812 8 13.125 8.05625 13.125 8.125V13.125H2.875V2.875H7.875C7.94375 2.875 8 2.81875 8 2.75V1.875C8 1.80625 7.94375 1.75 7.875 1.75H2.25C1.97344 1.75 1.75 1.97344 1.75 2.25V13.75C1.75 14.0266 1.97344 14.25 2.25 14.25H13.75C14.0266 14.25 14.25 14.0266 14.25 13.75V8.125C14.25 8.05625 14.1938 8 14.125 8Z' />
        <path d='M5.56095 8.35781L5.53127 10.2156C5.5297 10.3547 5.6422 10.4688 5.78127 10.4688H5.78752L7.63127 10.4234C7.66252 10.4219 7.69377 10.4094 7.71564 10.3875L14.2141 3.90313C14.2625 3.85469 14.2625 3.775 14.2141 3.72656L12.2719 1.78594C12.2469 1.76094 12.2156 1.75 12.1828 1.75C12.15 1.75 12.1188 1.7625 12.0938 1.78594L5.59689 8.27031C5.57432 8.29391 5.56149 8.32516 5.56095 8.35781ZM6.55314 8.72656L12.1828 3.10938L12.8891 3.81406L7.25627 9.43437L6.5422 9.45156L6.55314 8.72656Z' />
    </svg>
);

export default ContentEdit;
