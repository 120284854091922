import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, deleteToken, isSupported } from 'firebase/messaging';
import { store } from 'store/index';
import { setFCMToken } from 'store/fcm/actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Messaging } from '@firebase/messaging';
import { TError } from 'types/errors';

const app = initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: 'swiftpass.firebaseapp.com',
    databaseURL: 'https://swiftpass.firebaseio.com',
    projectId: 'swiftpass',
    storageBucket: 'swiftpass.appspot.com',
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_ID,
});

// let messaging: any;
//
// isSupported().then(() => {
//     messaging = getMessaging(app);
// });

export const getMessagingInstance = async (): Promise<Messaging | null> => {
    const supports = await isSupported();
    return supports ? getMessaging(app) : null;
};

export const getFcmToken = async (registration: ServiceWorkerRegistration): Promise<string | null> => {
    try {
        const messaging = await getMessagingInstance();
        if (!messaging) return null;

        const token = await getToken(messaging, {
            serviceWorkerRegistration: registration,
            vapidKey: process.env.REACT_APP_FIREBASE_CETRIFICATES,
        });

        store.dispatch(setFCMToken(token));
        return token;
    } catch (err) {
        const error = err as TError;
        window?.console.log(error);
    }
    return null;
};

export const unRegisterFcm = async (): Promise<boolean> => {
    const messaging = await getMessagingInstance();
    if (!messaging) return false;
    store.dispatch(setFCMToken(null));
    return deleteToken(messaging);
};
