export const chatSessionsQueryKey = 'chatSessions';
export const chatMessagesQueryKey = 'chatMessages';
export const documentsQueryKey = 'documents';
export const documentQueryKey = 'document';
export const rentGptLeadsQueryKey = 'rentGptLeads';
export const rentGptPropertiesQueryKey = 'rentGptProperties';
export const ccRentGptPropertiesQueryKey = 'ccRentGptProperties';

export const rentGptFormSubmissionsQueryKey = 'rentGptFormSubmissions';
export const rentGptFormActionsQueryKey = 'rentGptFormActions';
export const rentGptGalleryImagesQueryKey = 'rentGptGalleryImages';
export const rentGptPropertyNotificationSettings = 'rentGptPropertyNotificationSettings';

export enum EFilters {
    emailAddress = 'emailSearch',
    phoneNumber = 'phoneNumberSearch',
    firstName = 'firstNameSearch',
    lastName = 'lastNameSearch',
    type = 'typeSearch',
    accountID = 'company_id',
    propertyName = 'property_name',
    propertyAddress = 'property_address',
}

export const RentGptPropertyLocalStorageKey = 'RentGptProperty';

export const defaultRentGPTConfig = {
    position: 'right',
    widget: {
        offsetRight: 20,
        offsetLeft: 20,
        offsetBottom: 20,
        buttonSize: 60,
    },
    welcomeMessage: {
        text: ' Hi. I’m RentGPT - Ask me anything...',
        buttonSize: 24,
    },
    introMessage: 'Hi 👋 I’m RentGPT. \n You can ask me anything and in any language...',
    theme: {
        primaryColor: '#330DF2',
        primaryTxtColor: '#fff',
    },
    footerLabel: 'RentGPT Powered by',
    extraInfo: undefined,
    rreFetchActions: true,
};
