import { IMetaData } from 'types/IRespMetaData';
import { ICustomMap } from 'types/ICustomMap';
import { AxiosRequestConfig } from 'axios';

export enum APIMethods {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export interface IUseRequestApiRes<T> {
    data: T | null;
    isLoading: boolean;
    errorMsg: string | null;
    metaData: IMetaData;
    errors: ICustomMap | null;
}

export interface IRequestApiConfig {
    uri: string;
    method?: APIMethods;
    config?: AxiosRequestConfig;
    respKey?: string; // path to data in response to get proper level of data
}

export interface IAPIRequest {
    uriVariables?: ICustomMap;
    query?: ICustomMap;
    body?: ICustomMap;
    headers?: ICustomMap;
}

export interface IOptions {
    popupSuccessMsg?: boolean;
    popupErrMsg?: boolean;
}
