import React, { FC, useContext, useEffect, useMemo } from 'react';
import textsCommon from 'texts/common';
import { AppActionsContext, AppDataContext } from 'context/App';
import { useLocation } from 'react-router-dom';
import LearnIcon from 'components/common/Icons/Learn';
import useWindowSize from 'hooks/useWindowSize';
import { XXL_WIDTH } from 'config/general';
import cn from 'classnames';
import { CloseOutlined } from '@ant-design/icons';
import Button from 'components/antd/Button';
import routes from 'config/routes';
import styles from './styles.module.scss';
import { HelpData } from '../types';
import { getHelpData } from '../utils';

const HelpSidebar: FC = () => {
    const { openHelpSidebar } = useContext(AppDataContext);
    const { setOpenHelpSidebar, toggleHelpSidebar } = useContext(AppActionsContext);
    const { pathname } = useLocation();
    const { width } = useWindowSize();

    const helpData: HelpData | null = useMemo(() => getHelpData(pathname), [pathname]);

    const isFeed = useMemo(() => pathname === routes.activityFeed.root || pathname === routes.home, [pathname]);

    useEffect(() => {
        if (!!width && width > XXL_WIDTH && !isFeed) {
            setOpenHelpSidebar(true);
        } else {
            setOpenHelpSidebar(false);
        }
    }, [isFeed, setOpenHelpSidebar, width]);

    if (!helpData) return null;

    const hasFaq = helpData?.faq && helpData?.faq?.length > 0;
    const hasVideo = !!helpData?.video;

    return (
        <aside className={cn(styles.helpSider, { [styles.open]: openHelpSidebar, [styles.onlyFixed]: isFeed })}>
            <div className={styles.wrap}>
                <div className={styles.titleBox}>
                    <div>
                        <div className={styles.icon}>
                            <LearnIcon />
                        </div>
                        <div className={styles.title}>
                            <span>{textsCommon.learn}</span>
                            <p>{textsCommon.tutorial}</p>
                        </div>
                    </div>
                    <Button type='link' size='large' className={styles.closeBtn} onClick={toggleHelpSidebar}>
                        <CloseOutlined />
                    </Button>
                </div>
                <div className={styles.content}>
                    {hasVideo && (
                        <div className={styles.mb16}>
                            <h4 className={styles.titleLine}>{helpData.video?.title}</h4>
                            <iframe
                                className={styles.videoIframe}
                                src={helpData.video?.link}
                                title='YouTube video player'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                            />
                        </div>
                    )}
                    {hasFaq && (
                        <>
                            <h4 className={styles.titleLine}>{textsCommon.frequentlyQuestions}</h4>
                            <ul className={styles.list}>
                                {helpData?.faq?.map((item, index) => (
                                    <li key={`faq-${index}`}>
                                        <a target='_blank' href={item.link} rel='noreferrer'>
                                            {item.title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </>
                    )}
                </div>
            </div>
        </aside>
    );
};

export default HelpSidebar;
