export default {
    announcements: 'Building Announcements',
    title: 'Title',
    addAnnouncement: 'Add Announcement',
    announcementDesc: 'Send important health updates to your users',
    announcementGroupsDesc: 'Only selected groups will see this announcement',
    sendAnnouncementInApp: 'Send announcement in app',
    sendAnnouncementViaEmail: 'Send announcement via email',
    announcementChannels: 'Announcement Channels',
    announcementChannelsDesc: 'The announcement can be viewed in User App in User Profile —> Announcements page',
    buildingAnnouncements: 'Building Announcement',
    addSubTitle: 'Send building related communication to your residents/users',
    sendAnnouncement: 'Send Announcement',
    sendAnnouncementInAppDesc: 'The notification will be available in Swiftlane User App in Profile -> Announcements page.',
    type: 'Announcement Type',
    general: 'General',
    urgent: 'Urgent',
    announcement: 'Announcement',
    recipients: 'Recipient User Groups',
};
