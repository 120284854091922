import React, { Component, FC } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

export default (WrappedComponent: FC) => {
    const user = { anonymous: true };
    const WrappedComponentWithFeatureFlags = withLDProvider({
        clientSideID: process.env.REACT_APP_SIDE_ID || '',
        user,
        options: { streaming: true },
    })(WrappedComponent);

    // eslint-disable-next-line react/prefer-stateless-function
    return class extends Component {
        render() {
            // @ts-ignore
            return <WrappedComponentWithFeatureFlags {...this.props} />;
        }
    };
};
