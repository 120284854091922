// eslint-disable-next-line import/no-extraneous-dependencies
import 'react-app-polyfill/stable';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { isProd } from 'utils/isProd';
import App from 'components/App';
import featureFlagProvider from 'hoc/featureFlagProvider';
import { store } from './store';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import 'react-phone-input-2/lib/style.css';
import 'react-geosuggest/module/geosuggest.css';
import 'react-custom-scroll/dist/customScroll.css';
import 'styles/antdOverrides/index.scss';
import 'styles/global.scss';

if (isProd()) {
    Sentry.init({
        dsn: 'https://b26ce58681c3416280bd9d9432ddb689@o423966.ingest.sentry.io/5883935',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.1,
        denyUrls: ['/api/v1/'],
    });
}

// Create a client
const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

const container = document.getElementById('root');
const root = createRoot(container as any); // eslint-disable-line
const LDProvider = featureFlagProvider(App);
root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <LDProvider />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: async registration => {
        window?.console.log('On update event');
        window?.console.log('Registration', registration && registration.waiting);

        if (registration && registration.waiting) {
            // await registration.unregister();
            // Makes Workbox call skipWaiting()
            window?.console.log('New content is available - SKIP_WAITING');
            await registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            // await unRegisterFcm();
            window.location.reload();
        }
    },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
