import React, { FC, memo } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { Roles } from 'config/general';
import routes from 'config/routes';
import { useSelector } from 'react-redux';
import { IAuthenticationState } from 'store/authentication/types';
import { ICustomMap } from 'types/ICustomMap';
import { getCachedUserRole } from 'utils/auth';
import PermissionDenied from 'components/common/PermissionDenied';
import queryString from 'query-string';

const isMobAppWebView = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(window?.navigator?.userAgent);

export type ProtectedRouteProps = {
    allowedRoles?: Roles[];
} & RouteProps;

const PrivateRote: FC<ProtectedRouteProps> = ({ allowedRoles = [Roles.admin], ...routeProps }: ProtectedRouteProps) => {
    const { isLoggedIn } = useSelector(({ authentication }: ICustomMap): IAuthenticationState => authentication);
    const role = getCachedUserRole();
    const location = useLocation();
    const { workspace, email, navigationUrl }: ICustomMap = queryString.parse(location.search);

    if (isLoggedIn) {
        if (allowedRoles?.includes(role as Roles)) {
            return <Route {...routeProps} />;
        }
        return <PermissionDenied />;
    }

    // if need ro redirect user after log-in to proper page
    if (navigationUrl) localStorage.setItem('navigationUrl', navigationUrl);

    // redirect to log-in with pre-filed workspace
    if (workspace) {
        const query = queryString.stringify({
            email,
            workspace,
        });
        return isMobAppWebView ? null : <Redirect to={{ pathname: routes.auth.login, search: query }} />;
    }

    // for mobile app we use tokens from app itself, to avoid extra login step and possible logout effect - hiding login page
    return isMobAppWebView ? null : <Redirect to={{ pathname: routes.auth.enterWorkspace }} />;
};

export default memo(PrivateRote);
