import { APIMethods, apiProxy, TResponse } from 'utils/api/apiRequestProxy';
import { IReferralData } from './types';

export const referralCodesQueryKey = 'referralCodes';

export async function requestGetReferralCodes(): Promise<TResponse<IReferralData>> {
    return apiProxy<IReferralData>({
        url: '/referral-codes',
        method: APIMethods.GET,
    });
}
