import React, { FC } from 'react';

const FileIcon: FC = () => (
    <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#fileIcon)'>
            <path
                d='M9.39008 2.08691H4.72341C4.36979 2.08691 4.03065 2.22739 3.7806 2.47744C3.53055 2.72749 3.39008 3.06663 3.39008 3.42025V14.0869C3.39008 14.4405 3.53055 14.7797 3.7806 15.0297C4.03065 15.2798 4.36979 15.4202 4.72341 15.4202H12.7234C13.077 15.4202 13.4162 15.2798 13.6662 15.0297C13.9163 14.7797 14.0567 14.4405 14.0567 14.0869V6.75358L9.39008 2.08691Z'
                strokeWidth='1.33'
                strokeLinecap='round'
                strokeLinejoin='round'
            />
            <path d='M9.39008 2.08691V6.75358H14.0567' strokeWidth='1.33' strokeLinecap='round' strokeLinejoin='round' />
        </g>
        <defs>
            <clipPath id='fileIcon'>
                <rect width='16' height='16' transform='translate(0.723404 0.753418)' />
            </clipPath>
        </defs>
    </svg>
);

export default FileIcon;
