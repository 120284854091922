import React, { FC } from 'react';

const AuditLogsIcon: FC = () => (
    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M7.99967 1.3335L1.33301 4.66683L7.99967 8.00016L14.6663 4.66683L7.99967 1.3335Z'
            strokeWidth='1.33333'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
        <path d='M1.33301 11.3335L7.99967 14.6668L14.6663 11.3335' strokeWidth='1.33333' strokeLinecap='round' strokeLinejoin='round' />
        <path d='M1.33301 8L7.99967 11.3333L14.6663 8' strokeWidth='1.33333' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
);

export default AuditLogsIcon;
