import { IFCMState } from 'store/fcm/types';
import { fcmActionTypes } from 'store/fcm/actionTypes';
import { AnyAction } from 'redux';

const initialState: IFCMState = {
    token: null,
    isServiceWorkerAvailable: false,
};

function reducer(state = initialState, action: AnyAction): IFCMState {
    const { type, payload } = action;
    switch (type) {
        case fcmActionTypes.SET_FCM_TOKEN:
            return {
                ...state,
                token: payload,
            };
        case fcmActionTypes.SET_FCM_SERVICE_WORKER_AVAILABILITY:
            return {
                ...state,
                isServiceWorkerAvailable: payload,
            };
        default:
            return state;
    }
}

export default reducer;
