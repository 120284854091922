import React, { FC } from 'react';
import { Result, Spin } from 'antd';
import { textsCommon } from 'texts/index';
import styles from './styles.module.scss';

interface IProps {
    loading?: boolean;
}

const FetchingData: FC<IProps> = ({ loading }: IProps) => (
    <div className={styles.box}>{loading && <Result icon={<Spin size='large' />} title={textsCommon.fetchingData} />}</div>
);
export default FetchingData;
