import React, { FC, ReactElement, useContext, useMemo } from 'react';
import Button from 'components/antd/Button';
import { AppActionsContext } from 'context/App';
import { useLocation } from 'react-router-dom';
import { getHelpData } from 'components/common/Help/utils';
import LearnIcon from 'components/common/Icons/Learn';
import cn from 'classnames';
import { textsCommon } from 'texts/index';
import styles from './styles.module.scss';

type Props = {
    onLargeOnly?: boolean; // visible only on large screens
    className?: string;
};

const HelpButton: FC<Props> = ({ onLargeOnly, className }): ReactElement | null => {
    const { toggleHelpSidebar } = useContext(AppActionsContext);
    const { pathname } = useLocation();

    const hasHelpData = useMemo(() => !!getHelpData(pathname), [pathname]);

    if (!hasHelpData) return null;

    return (
        <Button
            type='link'
            onClick={toggleHelpSidebar}
            className={cn(styles.helpBtn, { [className || '']: className, [styles.onLargeOnly]: onLargeOnly })}
            size='middle'
        >
            <LearnIcon /> {textsCommon.learn}
        </Button>
    );
};

export default HelpButton;
