import React, { FC, memo, useEffect, useState } from 'react';
import moment from 'moment';
import { DASH, DATE_TIME_VISUAL, DATE_TIME_VISUAL_FULL_WITH_SECONDS, DATE_VISUAL } from 'config/general';
import { Tooltip } from 'antd';

interface IProps {
    date?: string | number | null;
    full?: boolean;
    fullWithSeconds?: boolean;
    noTime?: boolean;
    showFullOnHover?: boolean;
    updateInterval?: number;
}

const Time: FC<IProps> = ({ date, full, showFullOnHover, noTime, fullWithSeconds, updateInterval }: IProps) => {
    const [fromNow, setFromNow] = useState<string>();
    const [fullDate, setFullDate] = useState<string>();
    const [fullDateWithSeconds, setFullDateWithSeconds] = useState<string>();
    const [noTimeDate, setNoTimeDate] = useState<string>();

    useEffect(() => {
        if (date) {
            setFromNow(moment.utc(date).local().fromNow());
            setFullDate(moment.utc(date).local().format(DATE_TIME_VISUAL));
            setNoTimeDate(noTime ? moment.utc(date).local().format(DATE_VISUAL) : undefined);
            setFullDateWithSeconds(moment.utc(date).local().format(DATE_TIME_VISUAL_FULL_WITH_SECONDS));
        }
    }, [date, noTime]);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let interval: any = null;
        if (updateInterval && date) {
            interval = setInterval(() => {
                setFromNow(moment.utc(date).local().fromNow());
                setFullDate(moment.utc(date).local().format(DATE_TIME_VISUAL));
                setNoTimeDate(noTime ? moment.utc(date).local().format(DATE_VISUAL) : undefined);
            }, updateInterval);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [date, noTime, updateInterval]);

    if (!date) return <>{DASH}</>;

    return (
        <>
            {!showFullOnHover && !full && !noTime && !fullWithSeconds && fromNow}
            {full && !noTime && !fullWithSeconds && fullDate}
            {fullWithSeconds && fullDateWithSeconds}
            {noTime && noTimeDate}
            {showFullOnHover && !full && <Tooltip title={fullDate}>{fromNow}</Tooltip>}
        </>
    );
};

export default memo(Time);
