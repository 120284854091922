export default {
    title: 'Command center Users',
    usersExtendedList: 'Users extended list',
    workspace: 'Account',
    createdAt: 'Created At',
    companyId: 'Company Id',
    enterSearchPhrase: 'Enter search phrase',
    userDetails: 'User Details',
    intercomSettings: 'Intercom Settings',
    device: 'Device',
    lastLogin: 'Last Login (Intercom token updated)',
    notificationType: 'Notification Type',
    iphone: 'iPhone',
    ipad: 'iPad',
    android: 'Android',
    androidTablet: 'Android Tablet',
    webBrowser: 'Web Browser',
    unknown: 'Unknown',
    pushNotification: 'Push Notification',
    voip: 'VoIP',
    intercomHistory: 'Intercom History',
    accessPointSite: 'Access point / Site',
    duration: 'Duration',
    status: 'Status',
    photo: 'Photo',
    answerBy: 'Answered by',
};
