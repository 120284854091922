export default {
    brivo: 'Brivo',
    subDesc: "Integration with Swiftlane will enable Brivo users to use Swiftlane's  2 way video intercom for their visitors.",
    cardDesc: 'Cloud-based access control system',
    connectionName: 'Connection Name',
    clientId: 'Client ID',
    clientSecretKey: 'Client Secret Key',
    userName: 'User Name',
    password: 'Password',
    connectionAdded: 'Connection is successfully added',
    owner: 'Owner',
    connectionDetails: 'Connection Details',
    turnOnAutoSync: 'Turn on Auto Sync',
    turnOnAutoSyncDesc: 'Swiftlane with automatically sync selected user groups from Brivo periodically',
    autoSendEmails: 'Auto Send Email Invites to New Users',
    deleteConnection: 'Delete Connection',
    reAuth: 'Re-Auth',
    deleteTitle: 'Are you sure you want to delete the Brivo Connection?',
    deleteDesc:
        'If you delete the connection, all Sites / Users and User Groups associated with this connection will stop syncing with Swiftlane.',
    groupSyncTitle: 'Brivo User Groups for Sync',
    groupSyncDesc: 'Select the User Groups you want to sync with Swiftlane',
    groupSyncDetails:
        'Swiftlane will create the corresponding User Groups, sync all the users from selected Brivo User groups and map users to these groups during Sync',
    selectBrivoUserGroups: "Select Brivo User Groups to be Sync'ed",
    syncUsersFrom: 'Sync Users from',
    doorMappingTitle: 'Swiftlane - Brivo Door Mapping',
    doorMappingDesc:
        'Admins can map one or more Brivo doors to a Swiftlane Door. Swiftlane doors should be created manually in Swiftlane Dashboard.',
    sfDoor: 'Swiftlane Door',
    addMapping: 'Add New Mapping',
    addAccessPoint: 'Add New Swiftlane Access Point',
    brivoDoors: 'Mapped Brivo Doors',
    deleteMappingConfirm: 'Are you sure you want to delete this mapping?',
    intercomSettings: 'Intercom Settings',
    intercomSettingsDesc: 'Automatically create Swiftlane intercom entries for users inside specific Brivo Groups',
    selectSiteLabel: 'Select a Swiftlane Site to Sync into',
    selectBrivoGroupTenantsLabel: 'Select User Groups to create Tenant Directory Entries',
    selectBrivoGroupTenantsDesc:
        'All users under these User Groups will be added to the intercom directory entry within Swiftlane intercom',
    selectBrivoGroupIntercomLabel: 'Select User Groups to create Front Desk Intercom Recipients',
    selectBrivoGroupIntercomDesc:
        'All users from these Groups would be assigned as Front Desk intercom recipients with Swiftlane Front Desk Calling Option',
    selectBrivoGroups: 'Select Brivo User Groups',
    addSite: 'Add New Swiftlane Site',
    syncNow: 'Sync Now',
    connectionError: 'Create Connection Error',
    apiKey: 'API Key',
};
