interface IParseErrorsArgs {
    [key: string]: string[] | { [el: string]: string[] };
}

export const parseErrors = (errors: IParseErrorsArgs): string[] => {
    return Object.keys(errors)?.map((key: string) => {
        if (Array.isArray(errors[key])) {
            return `${key} - ${(errors[key] as Array<string>).join(', ')}`;
        }
        return `${key} - ${Object.values(errors[key]).join(', ')}`;
    });
};
