import { IRecentAuths, ITokens } from 'types/auth';
import { cacheAuthProps } from 'config/auth';
import { Roles } from 'config/general';

export const cacheUserRole = (role: string): void => localStorage.setItem(cacheAuthProps.userRole, role);
export const removeCachedUserRole = (): void => localStorage.removeItem(cacheAuthProps.userRole);
export const getCachedUserRole = (): Roles | null => {
    const role = localStorage.getItem(cacheAuthProps.userRole);
    return role ? (role as Roles) : null;
};

export const cacheRecentAuths = (list: IRecentAuths): void => localStorage.setItem(cacheAuthProps.recentAuths, JSON.stringify(list));
export const getCachedRecentAuths = (): IRecentAuths | null => {
    const res = localStorage.getItem(cacheAuthProps.recentAuths);
    return res ? JSON.parse(res) : {};
};

export const cacheUserTokens = (tokens: ITokens): void => localStorage.setItem(cacheAuthProps.userTokens, JSON.stringify(tokens));
export const removeCachedUserTokens = (): void => localStorage.removeItem(cacheAuthProps.userTokens);
export const getCachedUserTokens = (): ITokens | null => {
    const res = localStorage.getItem(cacheAuthProps.userTokens);
    return res ? JSON.parse(res) : null;
};

export const removeCachedUserCreds = (): void => {
    // remove user creds from local storage to log user out
    removeCachedUserTokens();
    localStorage.removeItem(cacheAuthProps.userRole);
    localStorage.removeItem(cacheAuthProps.verifyUser);
    localStorage.removeItem(cacheAuthProps.authedWorkspace);
};

export const getLogOutRecentAuths = (accountId: string): IRecentAuths | null => {
    const recentAuths = getCachedRecentAuths();
    const commonUserId = recentAuths && recentAuths[accountId]?.commonUserId;

    // remove all accounts from recent auths for current user
    if (commonUserId && recentAuths) {
        Object.keys(recentAuths).forEach(key => {
            if (recentAuths[key].commonUserId === commonUserId) {
                // @ts-ignore
                delete recentAuths[key];
            }
        });
    }

    // remove only current account from recent auths
    if (accountId && recentAuths && !commonUserId) {
        // @ts-ignore
        delete recentAuths[accountId];
    }

    return recentAuths;
};

export const removeFailedAuthFromCachedRecentAuths = (companyId: string): void => {
    const recentAuths = getCachedRecentAuths();
    if (companyId && recentAuths) {
        // @ts-ignore
        delete recentAuths[companyId];
        cacheRecentAuths(recentAuths);
    }
};
