import { IPaging } from 'types/IPaging';
import { ICustomMap } from 'types/ICustomMap';
import { SortOrders } from 'config/general';
import { AccountStatus } from 'pages/CommandCenter/Accounts/types';

export enum PaymentStatus {
    paid = 'Paid',
    overDue = 'Overdue',
    suspended = 'Suspended',
    due = 'Due',
    unpaidCurrent = 'Unpaid Current',
}

export enum ShippingStatus {
    readyToShip = 'Ready to ship',
    shippingBlocked = 'Shipping Blocked',
}

export enum EInvoiceStatus {
    paid = 'Paid',
    due = 'Due',
    overDue = 'Overdue',
    ignored = 'Ignored',
}

export enum InvoiceType {
    OneTime = 'OneTime',
    Recurring = 'Recurring',
}

export enum InvoiceTemplateStatus {
    active = 'active',
    pause = 'pause',
    inactive = 'inactive',
}

export type BillingState = {
    banner_message?: string;
    company_id: string;
    company_name: string;
    company_status: AccountStatus;
    payment_status: PaymentStatus;
    shipping_status: ShippingStatus;
    show_banner: boolean;
    is_trial_active: boolean;
    trial_end_time: string;
    invoicing_contact?: {
        address: string;
        created_at: string;
        emails: string[];
        id: number;
        is_integrator: boolean;
        name: string;
        phone: string;
        updated_at: string;
    };
};

export type NotificationLog = {
    is_sent: boolean;
    sent_at: string;
};

export type AccountInvoice = {
    invoice_type: InvoiceType;
    amount: number;
    block_shipment: boolean;
    company_id_str: string;
    created_at: string;
    due_date: string;
    grace_period: number;
    id_str: string;
    invoice_purpose: string;
    invoice_template_id: number;
    qb_invoice_id: string;
    qb_invoice_link: string;
    status: EInvoiceStatus;
    suspend_on_non_payment: boolean;
    updated_at: string;
    reminder_start_date: string;
    qb_invoice_doc_num: string;
    send_reminder: boolean;
    due_on_activation: boolean;
    notification_log: ICustomMap<NotificationLog>;
    reminder_1_sent: boolean;
    reminder_2_sent: boolean;
    reminder_3_sent: boolean;
    suspension_alert_sent: boolean;
    qb_invoice_json: string | null;
    account_name: string;
};

export type AccountInvoiceTemplate = {
    id_str: string;
    allow_customer_activate: boolean;
    amount_per_invoice: number;
    billing_frequency: number;
    company_id_str: string;
    description: string;
    end_date: string;
    grace_period: number;
    initial_activation_date: string;
    name: string;
    signed_term_duration: number;
    status: InvoiceTemplateStatus;
    suspend_on_non_payment: boolean;
    qb_template_id: string;
    send_reminder: boolean;
};

export type InvoiceContactInfo = {
    address: string;
    emails: string[];
    is_integrator: boolean;
    name: string;
    phone: string;
    cc_admins: boolean;
};

export type ReqBillingState = {
    company_id: string;
};

export interface ReqInvoices extends IPaging {
    account_id?: string;
    invoice_id?: string;
    invoice_status?: EInvoiceStatus;
    invoice_purpose?: string;
    gt_invoice_date?: string;
    lt_invoice_date?: string;
    invoice_type?: InvoiceType;
    blocked_shipment?: string;
    sort_by?: string;
    sort_order?: SortOrders;
}

export type ReqTrialAccess = {
    purpose: string;
};

export type ReqUpdateInvoice = {
    invoice_purpose: string;
    invoice_type: InvoiceType;
    qb_invoice_id?: string | number;
    qb_invoice_link: string;
    suspend_on_non_payment: boolean;
    block_shipment: boolean;
    amount: number;
    due_date: string;
    grace_period: number;
    company_id: string;
    reminder_start_date?: string;
    qb_invoice_doc_num?: string;
    invoice_db_id: string;
    status: EInvoiceStatus;
    send_reminder: boolean;
    due_on_activation: boolean;
};

export type ReqUpdateRecurringInvoice = {
    name: string;
    description?: string;
    allow_customer_activate: boolean;
    initial_activation_date?: string;
    grace_period: number;
    end_date?: string;
    signed_term_duration: number;
    billing_frequency: number;
    suspend_on_non_payment: boolean;
    amount_per_invoice: number;
    company_id: string;
    template_id: string;
    status: InvoiceTemplateStatus;
};

export interface ReqAccountInvoiceTemplates extends IPaging {
    company_id: string;
    template_id?: string;
}

export type ReqAddInvoiceContactInfo = {
    company_id: string;
    name: string;
    address: string;
    phone: string;
    emails: string[];
    is_integrator: boolean;
    cc_admins: boolean;
};

export interface ReqInvoicesContactInfo {
    company_id: string;
}

export type QBCustomer = {
    name: string;
    id: string;
    balance: number;
};

export type ReqQBCustomers = {
    search_term?: string;
    customer_id?: string;
};

export type QBTemplate = {
    day_of_month: number | null;
    day_of_week: number | null;
    month_of_year: number | null;
    week_of_month: number | null;
    end_date: string | null;
    id: string;
    interval_type: string;
    name: string;
    next_date: string;
    num_interval: number | null;
    previous_date: string | null;
    start_date: string | null;
    status: string;
};

export type ReqQBTemplate = {
    company_id: string;
    transaction_id: string;
};

export type ReqMapQBTemplate = {
    company_id: string;
    transaction_id: string;
    suspend_on_non_payment: boolean;
    send_reminder: boolean;
};

export type ReqSyncInvoices = {
    company_id: string;
    transaction_id?: string;
};

export type ReqCreateRecTemplate = {
    company_id: string;
    start_date: string;
    invoice_id: string;
    interval_type: string;
    num_intervals: number;
    grace_period: number;
    name: string;
    suspend_on_non_payment: boolean;
    send_reminder: boolean;
    line_items: ICustomMap[];
};
