import React, { FC } from 'react';

const ActivityIcon: FC = () => (
    <svg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M7.66667 1.3335L1 9.3335H7L6.33333 14.6668L13 6.66683H7L7.66667 1.3335Z'
            strokeWidth='1.33'
            strokeLinecap='round'
            strokeLinejoin='round'
        />
    </svg>
);

export default ActivityIcon;
