export default {
    assignedDCU: 'Assigned Door controllers',
    device: 'Device',
    relays: 'Relays',
    assignDevices: 'Assign Device(s)',
    enableTone: 'Enable Door Unlock Tone',
    address: 'Address',
    doorColorInUi: 'Door color in UI',
    frontDoor: 'Front Door',
    colorDesc: 'This color allows visually identifying the door in the mobile app.',
    mailEntrance: 'Main Entrance',
    accessSettings: 'Access Settings',
    allowAccess: 'Allow Nearby Access',
    allowRemoteUnlock: 'Allow remote unlock',
    doorUnlockSchedules: 'Door Unlock Schedules',
    doorUnlockLabel: 'Keep Front door unlocked during time intervals defined in schedules below',
    selectSchedule: 'Select Schedule',
    accessSchedules: 'Access Schedules',
    swiftreaderSettings: 'Swiftreader Settings',
    swiftReaderXSettings: 'Swiftreader X Settings',
    frontDeskTxt: 'Call Front Desk',
    frontDeskX: 'Front Desk',
    tenantIntercomTxt: 'Call Tenant',
    tenantIntercomXTxt: 'Tenants',
    visitorButtonTxt: "I'm a Visitor",
    touchlessUnlock: 'Touchless Unlock',
    touchlessUnlockInfo: 'Enables face recognition access as soon as a face is detected in front of it, without requiring a button tap.',
    intercomDirectory: 'Intercom Directory',
    intercomDirectoryInfo:
        'Would enable intercom directory where a visitor can pick a certain tenant from the list and call them directly (the call will be routed either to the tenant mobile app or cell/landline phone number, depending on what is configured for certain tenant).',
    faceOnlyUnlock: 'Face-only Unlock',
    faceOnlyUnlockInfo:
        'Turning this ON will enable two factor authentication requiring both mobile app and face recognition authentication to gain access. When this is turned OFF, access will be granted based only on recognized face.',
    accessSettingsUi: 'Access To Settings UI',
    accessSettingsUiInfo: 'Keep off, only useful for live debugging. It shows access point settings in the door app.',
    enableFaceRecognition: 'Enable Face Recognition',
    enableFaceRecognitionInfo: 'Enable or Disable using face recognition on swift reader to unlock the door.',
    frontDestIntercom: 'Front Desk Intercom',
    frontDestIntercomInfo:
        'Display front desk call option on SwiftReader, so that visitors can call front desk and get access. You can configure people who receive front desk calls under Visitor Access → Front Desk Intercom.',
    pinAccess: 'PIN Access',
    pinAccessInfo: 'Would enable virtual keypad on SwiftReader so users and visitors can enter PINs for access.',
    lowLight: 'Low Light',
    lowLightInfo:
        'The app will increase screen brightness and simulate flash using a white screen to recognize faces better under low light.',
    screensaver: 'Enable Screensaver',
    screensaverInfo:
        'When this is ON, your Swiftreader X will start showing screensaver in case of no activities performed on it. The home screen can be enabled by touching the screen when Screen saver is on.',
    auto: 'Auto',
    on: 'On',
    off: 'Off',
    swiftreaderHardware: 'Swiftreader Hardware',
    deviceName: 'Device name',
    kioskMode: 'Kiosk Mode (Single App Locked Mode)',
    noDevicesAssigned: 'No device assigned to access point.',
    reboot: 'Reboot',
    deviceRebooted: 'Device Rebooted',
    deleteDoor: 'Delete Door',
    accessPointRemoved: 'Access Point Removed',
    liveViewAndIntercomCallback: 'Live View & Intercom Callback',
    time: 'Time',
    refresh: 'Refresh',
    callDoor: 'Call Door',
    unlockDoor: 'Unlock Door',
    requestLiveView: 'Request Live View',
    couldNotRefresh: 'Could not refresh live view. Please try again later.',
    refreshing: 'Refreshing...',
    liveView: 'Live View',
    liveViewAccess: 'Live View Access',
    liveViewNotAvailable: 'Live View Not Available. Please click on the refresh button to request live view from the access point.',
    allowAccessHelpTxt:
        'People in this group can perform access via key card & fobs, PIN access, face unlock and mobile bluetooth/NFC unlock',
    allowRemoteUnlockHelpTxt:
        'People in this group can unlock the door using their mobile app, from anywhere, through cellular or wifi based mobile unlock',
    liveViewHelpTxt:
        'People in this group would be able to see a latest camera snapshot from the intercom, and also call back the reader from their mobile phone”',
    userList: 'User List',
    accessPoint: 'Access Point',
    viewUserList: 'View User List',
    nearByAccess: 'Nearby Access',
    remoteAccess: 'Remote Access',
    intercomCallback: 'Intercom Callback',
    usersAssignedTo: 'Users Assigned to',
    accessType: 'Access Type',
    assignUsers: 'Add Users',
    selectUsers: 'Select Users',
    selectGroups: 'Select groups',
    selectGroupsHelpTxt: 'Select groups help text',
    groupName: 'Group Name',
    addUsersHelpTxt: 'Select users to add to the Access Point',
    usersSuccessfullyAdded: 'Users Successfully Added to the access point',
    enterPin: 'Enter Pin',
    turnOnFlash: 'Turn on Flash',
    // cardReaderSettings: 'Card reader settings',
    keyCardReader: 'Key Card Reader',
    keyCardReaderInfo: 'This will enable Key Card reader in Swiftreader X',
    accessViaSwiftlane: 'Verify Access via Swiftlane',
    thirdPartyWeigandInfo: 'thirdPartyWeigand',
    accessViaWeigand: 'Wiegand Output Only',
    accessViaWeigandInfo: 'Swiftreader X will read the card and post Wiegand output to a defined third party system.',
    keyCardAccess: 'Key Card Access',
    faceRecHelpTxt: {
        p1: "The Face Recognition access can't be enabled for your account. Please contact",
        p2: 'for further info',
    },
    packageScanHelpTxt: 'To enable Package Scan, please contact',
    softReset: 'Soft Reset',
    liveViewCallback: 'Live view & Callback',
    enableAcu: 'Enable Offline Access',
    enableAcuInfo:
        "This option will allow residents to use their Pins or Bluetooth to unlock the doors. It'll also allow visitors to access using their valid visitor pins.",
    enable2FactorAuth: 'Enable 2 Factor Authentication',
    access: 'Access',
    kioskModeDesc: 'Single app locked mode: The reader will be locked and will show on Swiftlane App',
    pin: 'Pin',
    faceUnlock: 'Face Unlock',
    deviceSerialNumber: 'Device Serial Number',
    privateIP: 'Private IP',
    enablePackageScan: 'Enable Package Scan',
    enablePackageScanInfo: 'When turned On, the delivery person will get an option',
    intercomSettings: 'Intercom Settings',
    failedToGetLiveVideoRoom: 'Failed to get live video room',
};
