import { configureStore } from '@reduxjs/toolkit';

import { ICustomMap } from 'types/ICustomMap';
import rootReducer from './rootReducer';

const configureAppStore = (preloadedState: ICustomMap) => {
    const store = configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware(),
        preloadedState,
    });

    // @ts-ignore
    if (process.env.NODE_ENV !== 'production' && module.hot) {
        // @ts-ignore
        module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
    }

    return store;
};

export const store = configureAppStore({});

export type TDispatch = typeof store.dispatch;
export type TGetState = () => ICustomMap;
