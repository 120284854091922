import { useCallback, useEffect, useState } from 'react';
import { connect, Room, createLocalVideoTrack } from 'twilio-video';
import { RemoteParticipant } from 'twilio-video/tsdef/RemoteParticipant';
import { ITokens } from 'types/auth';
import { useMutation } from 'react-query';
import { requestAnswerCall, requestRemoteUnlock } from 'pages/IntercomSettings/FrontDescIntercom/services';
import { checkMediaPermissions } from 'utils/mediaPermissions';
import { toast } from 'react-toastify';
import { analyticsIntercomEventTypes, dashboardAnalytics, dashboardAnalyticsEvents } from 'hooks/useAnalytics/config';
import { TError } from 'types/errors';
import { disconnectRoom, hasVideoCamera, muteMedia, unMuteMedia } from './utils';

interface IProps {
    authTokens?: ITokens | null;
    roomName: string;
    roomId: string;
    accessPointId: string;
    accessToken?: string;
    onEndCallPress(): void;
    onCallAnswer?(): void;
}

interface IRes {
    room: Room | null;
    token: string | undefined;
    isAudioOnMute: boolean;
    isVideoOnMute: boolean;
    isUnlocking: boolean;
    isAudioAnswering: boolean;
    isDoorUnlocked: boolean;
    showCountDown: boolean;
    participants: RemoteParticipant[];
    handleAnswerCall: () => void;
    toggleMuteAudio: () => void;
    toggleMuteVideo: () => void;
    handleUnlockDoor: () => void;
    handleEndCall: () => void;
}

const useRoom = ({ roomName, roomId, accessPointId, accessToken, authTokens, onEndCallPress, onCallAnswer }: IProps): IRes => {
    const [room, setRoom] = useState<Room | null>(null);
    const [token, setToken] = useState<string>();
    const [isAudioOnMute, setIsAudioOnMute] = useState<boolean>(false);
    const [isVideoOnMute, setIsVideoOnMute] = useState<boolean>(true);
    const [isAudioAnswering, setIsAudioAnswering] = useState<boolean>(false);
    const [participants, setParticipants] = useState<RemoteParticipant[]>([]);
    const [canKeepCallOn, setCanKeepCallOn] = useState<boolean>(false);
    const [isDoorUnlocked, setDoorUnlocked] = useState<boolean>(false);
    const [showCountDown, setShowCountDown] = useState<boolean>(false);
    const [videoTrackId, setVideoTrackId] = useState<string | null>(null);

    const { mutateAsync: requestUnlock, isLoading: isUnlocking } = useMutation(requestRemoteUnlock);

    const connectRoom = useCallback(
        async (connectToken: string) => {
            if (!connectToken) return;

            const participantConnected = (participant: RemoteParticipant) => {
                setParticipants(prevParticipants => [...prevParticipants, participant]);
            };
            const participantDisconnected = (participant: RemoteParticipant) => {
                setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
            };

            connect(connectToken, {
                name: roomName,
                audio: true,
                video: false, // (await hasVideoCamera()) && { width: 200 },
            }).then(curRoom => {
                dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                    event_type: analyticsIntercomEventTypes.callAnswered,
                });
                setRoom(curRoom);
                curRoom.on('participantConnected', participantConnected);
                curRoom.on('participantDisconnected', participantDisconnected);

                // we have one to one connection, so when any participants disconnects we close the room
                curRoom.on('participantDisconnected', () => {
                    if (curRoom && curRoom.localParticipant.state === 'connected') {
                        disconnectRoom(curRoom);
                        onEndCallPress();
                    }
                });

                curRoom.participants.forEach(participantConnected);
            });
        },
        [onEndCallPress, roomName],
    );

    const { mutateAsync: answerCall } = useMutation(requestAnswerCall, {
        onSuccess: ({ respData }) => {
            setToken(respData?.access_token);
            if (respData?.access_token) {
                connectRoom(respData.access_token);
                if (onCallAnswer) onCallAnswer();
            }
        },
        onError: (error: TError) => {
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.failedAnswerCall,
                message: error?.message,
            });
        },
    });

    useEffect(() => {
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.callModalOpened,
        });
        if (accessToken) {
            setToken(accessToken);
            connectRoom(accessToken);
        }
    }, [accessToken, connectRoom]);

    useEffect(() => {
        return () => {
            if (room && room.localParticipant.state === 'connected') {
                disconnectRoom(room);
            }
        };
    }, [room]);

    const reqAnswerCall = useCallback(async () => {
        await answerCall({
            platform: 'web',
            access_point_id: accessPointId,
            room_name: roomName,
            room_sid: roomId,
            authToken: authTokens?.access_token,
        });
    }, [accessPointId, answerCall, authTokens, roomId, roomName]);

    const handleAnswerCall = useCallback(async () => {
        dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
            event_type: analyticsIntercomEventTypes.answerCallButtonPressed,
        });
        try {
            await checkMediaPermissions();
        } catch (err) {
            toast.error('Please allow access to your camera and microphone');
            return;
        }
        try {
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.requestAnswerCall,
            });
            setIsAudioAnswering(true);
            await reqAnswerCall();
            setIsVideoOnMute(true);
            setIsAudioOnMute(false);
            setCanKeepCallOn(true);
        } finally {
            setIsAudioAnswering(false);
        }
    }, [reqAnswerCall]);

    const toggleMuteAudio = useCallback(() => {
        if (room) {
            setIsAudioOnMute(current => {
                const result = !current;
                if (result) {
                    muteMedia(room.localParticipant.audioTracks);
                } else {
                    unMuteMedia(room.localParticipant.audioTracks);
                }
                return result;
            });
        }
    }, [room]);

    const enableVideo = useCallback(async () => {
        if (room) {
            const { localParticipant } = room;
            if (await hasVideoCamera()) {
                const newVideoTrack = await createLocalVideoTrack({ width: 200 });
                const track = await localParticipant.publishTrack(newVideoTrack);
                setVideoTrackId(track?.trackSid);
                setIsVideoOnMute(false);
            }
        }
    }, [room]);

    const disableVideo = useCallback(() => {
        if (room && videoTrackId) {
            const { localParticipant } = room;
            localParticipant.tracks.forEach(publication => {
                if (publication.trackSid === videoTrackId) {
                    // @ts-ignore
                    publication.track?.stop();
                    publication.unpublish();
                }
            });
            setIsVideoOnMute(true);
        }
    }, [room, videoTrackId]);

    const toggleMuteVideo = useCallback(async () => {
        if (room) {
            setIsVideoOnMute(current => {
                const result = !current;
                if (result) {
                    disableVideo();
                } else {
                    enableVideo();
                }
                return result;
            });
        }
    }, [disableVideo, enableVideo, room]);

    const handleEndCall = useCallback(() => {
        if (room) {
            disconnectRoom(room);
        }
        onEndCallPress();
    }, [onEndCallPress, room]);

    const handleUnlockDoor = useCallback(async () => {
        try {
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.requestDoorUnlock,
            });
            await requestUnlock({
                access_point_id: accessPointId,
                room_name: roomName,
                room_sid: roomId,
                authToken: authTokens?.access_token,
            });
            dashboardAnalytics?.track(dashboardAnalyticsEvents.intercom, {
                event_type: analyticsIntercomEventTypes.doorUnlocked,
            });
            if (canKeepCallOn) {
                setShowCountDown(true);
                setDoorUnlocked(true);
            } else {
                handleEndCall();
            }
        } catch {
            handleEndCall();
        }
    }, [accessPointId, authTokens, canKeepCallOn, handleEndCall, requestUnlock, roomId, roomName]);

    return {
        room,
        token,
        isAudioOnMute,
        isVideoOnMute,
        isUnlocking,
        isAudioAnswering,
        participants,
        showCountDown,
        isDoorUnlocked,
        toggleMuteAudio,
        toggleMuteVideo,
        handleAnswerCall,
        handleEndCall,
        handleUnlockDoor,
    };
};

export default useRoom;
