export default {
    title: 'Key Cards and Fobs',
    desc: 'Create Key Cards and Fobs',
    addNewKeyCard: 'New Key Card',
    keyCardsList: 'Key Cards and Fobs list',
    cardNumber: 'Card Number',
    predicted26CardNum: 'Predicted 26bit Card number',
    assignedUser: 'Assigned User',
    assignUser: 'Assign User',
    edit: 'Edit',
    deactivate: 'Deactivate',
    activate: 'Activate',
    active: 'Active',
    deactivated: 'Deactivated',
    activateCard: 'Activate Card',
    deactivateCard: 'Deactivate Card',
    addTitle: 'Add a New Key Card',
    addDesc: 'Use this Wizard to Create a New Key Card',
    cardFormat: 'Card Format',
    selectCardFormat: 'Select card format',
    addKeyCard: 'Add Key Card',
    standard26Bit: 'Standard 26 Bits',
    raw64Bit: 'Raw 64 Bits',
    cardInformation: 'Card Information',
    singleCard: 'Single Key Card',
    multipleCards: 'Multiple Key Cards',
    firstNumberInRange: 'First Number in Range',
    lastNumberInRange: 'Last Number in Range',
    facilityCode: 'Facility code',
    selectAssignedUser: 'Select assigned user',
    editKeyCard: 'Edit Key Card',
    saveKeyCard: 'Save Key Card',
    cardValueHex: 'Card Value (Hex)',
    cardHexDesc:
        'Raw 64 Bit formats simply take the complete scanned value of a card. They do not provide a breakdown between card number and facility code. Use this format if you are unsure about the facility code or card format',
    hexValue: 'Hex value',
    binaryFormat: 'Binary Format',
    binaryFormatValue: 'Binary Format Value (read only)',
    enterCorrectHexNumber: 'Please enter correct Hex number: numbers 0-9, letters A-F, could start with 0x',
    hid37BitH10304: 'HID 37 bit H10304',
    hid37BitH10302: 'HID 37 bit H10302',
    noFacilityCode: 'No Facility Code',
    userIsAssigned: 'User Successfully Assigned',
    userIsUnassigned: 'User Successfully Unassigned',
    multipleCardsHint: 'Users to be assigned via',
    screen: 'screen',
    preferablySerialNum: 'Description or Serial Number Printed on the Card',
    stidVirtualCards: 'Mobile Keycards',
    stidVCardsSettings: 'Mobile Keycards Settings',
    maxNumVCards: 'Number of Purchased Mobile Keycards',
    numActiveVCards: 'Number of Active Mobile Keycards',
    numDeletedVCards: 'Number of Deleted Mobile Keycards',
    remainingCards: 'Number of Remaining Mobile Keycards',
    numAvailableVCards: 'Number of Mobile Keycards',
    stidVirtualCardsList: 'Mobile Keycards List',
    assignVCardsToUsers: 'Assign Mobile Keycards to Users',
    selectUsers: 'Select Users',
    stidIsNotActivated: 'Mobile Keycard is not activated, please contact Swiftlane admin',
    stidSupportSubject: 'Purchase Mobile Key Card Credits',
    stidSupportBody:
        'Hi Swiftlane Support Team, We need to add X number of new mobile key cards to our account. Kindly help us to add required credits to the account.',
    stidSupportTxt1: 'The credits required for adding new mobile key cards are exhausted for your account. Please contact',
    stidSupportTxt2: 'to purchase more credits.',
    cardsTransactionHistory: 'Cards Transactions History',
    transactionHistory: 'Transactions History',
    action: 'Action',
    stid40BitVehicleTag: 'STID Vehicle Tags (40 bits)',
    stid64Bit: 'STID 64 Bits Raw',
    raw40Bit: 'Raw 40 Bits',
    raw96Bit: 'Raw 96 Bits',
    decimal: 'Decimal',
    hex: 'Hex',
    selectCardNumberFormat: 'Select Card Number Format',
    maxCardsInRange: 'Max 100 cards can be added at a time. Please provide range accordingly.',
    lastBiggerThenFirst: 'Last number in Range Should be bigger then First number in Range',
    settings: {
        title: 'Settings',
        facilityCodeSettings: 'Facility Code Settings for Key Cards and Fobs',
        facilityCodeSettingsSubTitle: 'Manage Facility Codes for various Key Card and Fobs Formats',
        defaultConfig: 'Default Configuration',
        createConfig: 'Create Custom Configuration',
        viewCards: 'View Key Card List',
        createConfigDesc:
            ' To relax the requirements for mandatory Facility Code, please create custom configurations for the Card Format you want to use.',
        createConfigNote: 'Note: Fot the bit length, only one card format and settings is allowed.',
        bit: 'Bit',
        selectBitLen: 'Select Bits length',
        selectCardFormat: 'Select Card Format',
        facilityCodeSet: 'Facility Code Settings',
        allowAny: 'Allow Any / No Facility Code',
        allowAnyHint:
            'When selected, key cards added will be matched only for Card Numbers, irrespective of facility code is provided or not.',
        allowApprovedCodes: 'Allow Only Approved Facility Codes',
        allowApprovedCodesHint:
            'Approved Facility Codes list MUST have at least 1 facility code added to it. Access will be provided to only those cards which has facility code from the approved codes list. Admins will still be able to add key cards with other facility codes but those cards won’t get access until their corresponding facility codes are added to the approved codes list.',
        approvedCodes: 'Approved Facility Codes',
        addCode: 'Add New Facility Code to List Above',
    },
    settingsForSelected: 'Facility Code Settings for Selected Card Format',
    csv: 'CSV',
    csvUpload: 'CSV Upload',
    uploadCsv: 'Upload CSV',
    reviewEdit: 'Review & Edit',
    verifyImportedData: 'Verify Imported Data',
    uploadSupportInfo: 'Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files',
    importedFileNotValid:
        "Imported CSV File isn't Valid, please check if headers is correct, no empty rows. Also Please check the template for correct format.",
    importFileNotValid: "Imported File isn't Valid",
    checkCsvLines: 'Check next CSV lines for mistakes',
    csvUploadDesc:
        'Use this CSV upload tool to bulk import Key Cards and Fobs into the system. Please create a CSV style format file, that can be easily exported from Excel or Google Sheets, and upload that here. Please refer to the template for some columns that you need in order to finish the upload correctly.',
    csvFileTemplate: 'CSV File Template',
    downloadCsvTemplate: 'download CSV template here',
    completeUpload: 'Complete Upload',
    saveErrorMsg: 'There are errors, please check entered data',
    bulkSuccessMsg: 'Key Cards and Fobs Successfully Uploaded',
    emailPhoneRequired: 'Please enter either Email or Phone number',
    cardNumberShouldBeDecimal: 'Card number should be a number',
    cardNumberRequired: 'Card number is required',
    facilityCodeRequired: 'Facility code is required',
    allowedFacilityCodes: 'Facility code is not allowed, allowed facility codes are',
    facilityCodeRange: 'Facility code should be in range of',
    and: 'and',
    facilityCodeShouldBeDecimal: 'Facility code should be a number',
    cardDuplicated: 'Card Number is duplicated',
    facilityCodeSettings: 'Facility Code Settings',
    cardNumHelpText: 'Card Number: Mandatory. Provide KeyCard / Fob serial number.',
    facilityCodeHelpTxt: 'Facility Code: Mandatory.',
    emailHelpTxt: ' Email ID: Optional. If a valid email id of existing user is provided, the key card will be assigned to that user.',
    countryCodePhoneHelpTxt:
        'Country Code + Phone Number: Optional. If a valid phone number of existing user is provided, the key card will be assigned to that user.',
    uniqueIdentifierHelpTxt:
        'Unique Identifier: Optional. It can be any text description to identify the physical key card. It can be serial number printed on card, etc.',
};
