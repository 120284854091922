import { textsAuth, textsCommon } from 'texts/index';
import {
    emailPattern,
    hexPattern,
    hexSimplePattern,
    mobilePattern,
    passwordPattern,
    phoneNumberPattern,
    positiveDigits,
    urlPattern,
} from 'utils/validationPatterns';
import { Rule } from 'antd/lib/form';

// eslint-disable-next-line import/no-extraneous-dependencies
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { ICustomMap } from 'types/ICustomMap';
import { maxPinLen, minPinLen, repeatingPattern, revSequentialNum, sequentialNum } from 'config/pins';

export const requiredMsg = {
    required: textsCommon.fieldIsRequired,
};

export const whitespaceMsg = {
    whitespace: textsCommon.fieldCannotBeEmpty,
};

export const positiveDigitMsg = {
    types: {
        number: textsCommon.enterPositiveDigits,
    },
};

export const hexMismatchMsg = {
    pattern: {
        mismatch: textsCommon.enterCorrectHexNumber,
    },
};

export const requiredRule: Rule = {
    required: true,
    message: textsCommon.fieldIsRequired,
};

export const requiredWhitespaceRule: Rule = {
    required: true,
    whitespace: true,
};

export const emailRule: Rule = {
    type: 'email',
    pattern: emailPattern,
    message: textsCommon.isNotValidEmail,
};

export const positiveDigitRule: Rule = {
    type: 'number',
    pattern: positiveDigits,
};

export const digitRule: Rule = {
    pattern: positiveDigits,
    message: textsCommon.enterPositiveDigits,
};

export const hexRule: Rule = {
    pattern: hexPattern,
};

export const hexSimpleRule: Rule = {
    pattern: hexSimplePattern,
    message: textsCommon.enterCorrectSimpleHexNumber,
};

export const whitespaceRule: Rule = {
    whitespace: true,
};

export const phoneNumberRuleWithCode: Rule = {
    pattern: phoneNumberPattern,
    message: textsCommon.phoneNumWithCodeValidationMsg,
};

export const phoneNumberFullRule: Rule = {
    pattern: mobilePattern,
    message: textsCommon.phoneNumFullValidationMsg,
};

export const passwordRule: Rule = {
    pattern: passwordPattern,
    message: textsCommon.passwordValidationMsg,
};

export const urlRule: Rule = {
    pattern: urlPattern,
    message: textsCommon.urlValidationMsg,
};

export const passwordMatchRule = ({ getFieldValue }: ICustomMap): ICustomMap => ({
    validator(_: RuleObject, value: StoreValue) {
        if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(textsAuth.passwordsNotMatch));
    },
});

export const pinRuleValidator = ({ min = minPinLen, max = maxPinLen }: RuleObject, value: StoreValue) => {
    if (!value) {
        return Promise.resolve();
    }

    const valString = value.toString();
    const isSequential = sequentialNum.includes(valString) || revSequentialNum.includes(valString);
    const isRepeating = repeatingPattern.test(valString);

    if (valString.length < min || valString.length > max || valString[0] === '0') {
        return Promise.reject(new Error(`${textsCommon.setPinWith} ${min} - ${max} ${textsCommon.setPinWithP2}`));
    }

    if (isSequential) {
        return Promise.reject(new Error(textsCommon.sequentialDigitsNotAllowed));
    }
    if (isRepeating) {
        return Promise.reject(new Error(textsCommon.repeatedDigitsNotAllowed));
    }

    return Promise.resolve();
};

export const emailsRule = (): ICustomMap => ({
    validator(_: RuleObject, emails: StoreValue) {
        if (!emails || emails.every((val: string) => val.match(emailPattern))) {
            return Promise.resolve();
        }
        return Promise.reject(new Error(textsCommon.someEnteredEmailIsInvalid));
    },
});
