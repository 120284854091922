import React, { FC, useEffect, useRef, useState } from 'react';
import { LocalParticipant } from 'twilio-video/tsdef/LocalParticipant';
import { RemoteParticipant } from 'twilio-video/tsdef/RemoteParticipant';
import { AudioTrack, VideoTrack } from 'twilio-video';

interface IProps {
    participant: LocalParticipant | RemoteParticipant | undefined;
}

const Participant: FC<IProps> = ({ participant }: IProps) => {
    const [videoTrack, setVideoTrack] = useState<VideoTrack | null>(null);
    const [audioTrack, setAudioTrack] = useState<AudioTrack | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        const trackSubscribed = (track: VideoTrack | AudioTrack) => {
            if (track.kind === 'video') {
                setVideoTrack(track);
            } else {
                setAudioTrack(track);
            }
        };

        const trackUnsubscribed = (track: VideoTrack | AudioTrack) => {
            if (track.kind === 'video') {
                setVideoTrack(track);
            } else {
                setAudioTrack(track);
            }
        };

        if (participant) {
            participant.on('trackSubscribed', trackSubscribed);
            participant.on('trackUnsubscribed', trackUnsubscribed);
            participant.on('trackPublished', ({ track }) => {
                if (track) {
                    trackSubscribed(track);
                }
            });
            participant.on('trackUnpublished', ({ track }) => {
                if (track) {
                    trackUnsubscribed(track);
                }
            });

            participant.tracks.forEach(publication => {
                if (publication.kind === 'video' && publication.track) {
                    trackSubscribed(publication.track as VideoTrack);
                }
            });
        }

        return () => {
            setVideoTrack(null);
            setAudioTrack(null);
            participant?.removeAllListeners();
        };
    }, [participant]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (videoTrack) {
            videoTrack.attach(videoRef.current as HTMLMediaElement);
            return () => {
                videoTrack.detach();
            };
        }
    }, [videoTrack]);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (audioTrack) {
            audioTrack.attach(audioRef.current as HTMLMediaElement);
            return () => {
                audioTrack.detach();
            };
        }
    }, [audioTrack]);

    return (
        <>
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video ref={videoRef} autoPlay />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio ref={audioRef} autoPlay />
        </>
    );
};

export default Participant;
