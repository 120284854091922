export default {
    activity: 'Activity',
    user: 'User',
    access: 'Access',
    accessPointSite: 'Access point / Site',
    accessType: 'Access Type',
    types: 'Types',
    time: 'Time',
    accessStatus: 'Access Status',
    statuses: 'Statuses',
    unlock: 'Unlock',
    addNote: 'Add Note',
    note: 'note',
    recentActivity: 'Recent Activity',
    updateNote: 'Update note',
    timestamp: 'Timestamp',
    compare: 'Compare',
    registerCardForUser: 'Register Card for a user',
    showPassUsage: 'Show Pass Usage',
    resetFilters: 'Reset Filters',
    callDoor: 'Call Door',
    liveView: 'Live View',
    zoom: 'Zoom',
    cardNumber: 'Card Number',
    facilityCode: 'Facility Code',
    addFaceRecognitionImage: 'Assign image to a User for Face Recognition',
    assign: 'Assign',
    pinDetails: 'PIN Details',
    passName: 'Pin Name',
};
